// Library
import React from "react";
import styled from "styled-components";

// UI
import Modal from "@ui/Modal";
import ImgWrap from "@ui/ImgWrap";

// Image
import successIcon from "@images/landing/success.svg";
import errorIcon from "@images/landing/error.svg";

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
`;

const ModalState = ({
  successModalShow,
  errorModalShow,
  setSuccessModalShow,
  setErrorModalShow
}) => {
  return (
    <>
      <Modal
        show={successModalShow.show}
        onHide={() => setSuccessModalShow({ ...successModalShow, show: false })}
        centered
      >
        <Wrapper>
          <div
            className="px-4 d-flex flex-column justify-content-center align-items-center text-center"
            style={{ height: "256px" }}
          >
            <ImgWrap src={successIcon} />
            <h5 className="mt-2">
              <b>{successModalShow.title}</b>
            </h5>
            <p>{successModalShow.text}</p>
          </div>
        </Wrapper>
      </Modal>
      <Modal
        show={errorModalShow.show}
        onHide={() => setErrorModalShow({ ...errorModalShow, show: false })}
        centered
      >
        <Wrapper>
          <div
            className="px-4 d-flex flex-column justify-content-center align-items-center text-center"
            style={{ height: "256px" }}
          >
            <ImgWrap src={errorIcon} />
            <h5 className="mt-2">
              <b>{errorModalShow.title}</b>
            </h5>
            <p>{errorModalShow.text}</p>
          </div>
        </Wrapper>
      </Modal>
    </>
  );
};

export default ModalState;
