// Label
import React, { useState, useEffect } from "react";
import classes from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

// state
import initialState from "./initialState";

// Styled-component
import Wrapper from "./Wrapper";

const Decor = ({ theme = "dark" }) => (
  <>
    <img
      className="decor decor__left decor__left_1"
      src={initialState[theme].left.decor1}
      alt=""
    />
    <img
      className="decor decor__left decor__left_2"
      src={initialState[theme].left.decor2}
      alt=""
    />
    <img
      className="decor decor__left decor__left_3"
      src={initialState[theme].left.decor3}
      alt=""
    />
    <img
      className="decor decor__left decor__left_4"
      src={initialState[theme].left.decor4}
      alt=""
    />
    <img
      className="decor decor__right decor__right_1"
      src={initialState[theme].right.decor1}
      alt=""
    />
    <img
      className="decor decor__right decor__right_2"
      src={initialState[theme].right.decor2}
      alt=""
    />
    <img
      className="decor decor__right decor__right_3"
      src={initialState[theme].right.decor3}
      alt=""
    />
  </>
);

const LandingSliderLaptop = ({
  className,
  theme = "dark",
  laptoWidth = 540
}) => {
  const classnames = classes([className, theme]);
  const [data, setData] = useState(initialState[theme].slides);
  const [activeSlide, setActiveSlide] = useState(2);
  const [swiper, setSwiper] = useState(null);
  let timer = null;

  useEffect(() => {
    if (window.innerWidth > laptoWidth) timer = setInterval(() => move(), 5000);
  }, [data]);

  // Slide move
  const move = (position = false) => {
    let tempData = data.slice();
    let tempInitialState = initialState[theme].slides.slice();

    if (position !== false && activeSlide !== position + 1) {
      tempData = [...tempInitialState.splice(position, 1), ...tempInitialState];
      tempData.unshift(tempData.pop());
      if (position === 1) {
        tempData.reverse();
      }
    }

    if (position === false) tempData.push(tempData.shift());

    clearTimeout(timer);
    setActiveSlide(tempData[1].position);
    setData(tempData);
  };

  return (
    <Wrapper className={classnames}>
      {/* Custom Slider */}
      <div className="slider-container px-2 px-sm-0">
        {/* Top */}
        <div className="slider-container__top">
          <img
            className="slider-container__laptop"
            onClick={() => move()}
            src={initialState[theme].top}
            alt=""
          />
          <div>
            {/* Sliders */}
            {data.map((i, k) => {
              return (
                <div
                  key={`slider-${k}`}
                  className={`slide slide-${k + 1}`}
                  style={{ background: `url(${i.img}) no-repeat` }}
                />
              );
            })}
          </div>
          {/* Decor */}
          <Decor theme={theme} />
        </div>
        {/* Bottom */}
        <img
          className="slider-container__bottom"
          src={initialState[theme].bottom}
          alt=""
        />
      </div>

      {/* Dots */}
      <div className="dots d-flex justify-content-center mt-2">
        <div
          className={`dot ${
            activeSlide === 1 ? "dot_active" : ""
          } cursor-pointer mr-2`}
          onClick={() => {
            if (swiper.width < 540) {
              swiper.slideTo(0);
            } else {
              move(0);
            }
          }}
        />
        <div
          className={`dot ${
            activeSlide === 2 ? "dot_active" : ""
          } cursor-pointer mr-2`}
          onClick={() => {
            if (swiper.width < 540) {
              swiper.slideTo(1);
            } else {
              move(1);
            }
          }}
        />
        <div
          className={`dot ${
            activeSlide === 3 ? "dot_active" : ""
          } cursor-pointer `}
          onClick={() => {
            if (swiper.width < 540) {
              swiper.slideTo(2);
            } else {
              move(2);
            }
          }}
        />
      </div>

      {/* Slider */}
      <div className="mx-auto" style={{ maxWidth: "1100px" }}>
        <Swiper
          autoplay={{
            delay: 5000
          }}
          onSwiper={setSwiper}
          controller={{ control: swiper }}
          className="pt-4 pb-3"
          updateOnWindowResize={true}
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          onSlideChange={slideData => {
            if (slideData.width < laptoWidth) move(slideData.realIndex);
          }}
          onInit={slideData => {
            if (slideData.width < laptoWidth) slideData.slideTo(1);
          }}
          breakpoints={{
            540: {
              slidesPerView: 3
            }
          }}
        >
          {initialState[theme].slides.map((i, k) => (
            <SwiperSlide
              key={`slide-${k}`}
              className="cursor-pointer p-2"
              onClick={() => {
                move(+k);
              }}
            >
              <div
                className={`swiper-slide__block ${
                  activeSlide === k + 1 ? "active shadow bg-white rounded" : ""
                } p-2`}
              >
                <h5>
                  <b>{i.title}</b>
                </h5>
                <p>{i.desc}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Wrapper>
  );
};

export default LandingSliderLaptop;
