// Library
import React from "react";
import { Formik, Form, Field } from "formik";

// UI
import Input from "@ui/Input";
import Button from "@ui/Button";

// Validation
import ValidationSchema from "./validation-schema";

const initialValues = {
  name: "",
  email: "",
  phone: ""
};

const FormTestAccess = ({
  getAccessDemo,
  setSuccessModalShow,
  setErrorModalShow,
  anchor
}) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) =>
        getAccessDemo(values, actions)
          .then(res => {
            if (res && res.success) {
              actions.resetForm();
              setSuccessModalShow({
                show: true,
                title: "Заявка принята",
                text:
                  "После проверки данные для входа в систему будут отправлены на указанную почту."
              });
            } else {
              setErrorModalShow({
                show: true,
                title: "Произошла ошибка",
                text:
                  "К сожалению, не удалось оставить заявку. Попробуйте заполинить форму еще раз."
              });
            }
          })
          .catch(() =>
            setErrorModalShow({
              show: true,
              title: "Произошла ошибка",
              text:
                "К сожалению, не удалось оставить заявку. Попробуйте заполинить форму еще раз."
            })
          )
      }
      render={({ values, errors, resetForm }) => (
        <Form>
          {errors.api && <small className="text-danger">{errors.api}</small>}
          <div className="row justify-content-md-center mt-3 mb-4">
            <div className="col-sm-3 pr-sm-0">
              {/* Имя */}
              <Field
                name="name"
                render={({
                  field,
                  form: { isSubmitting, errors, touched }
                }) => (
                  <Input
                    {...field}
                    className="mb-2"
                    value={values.name}
                    size="lg"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    disabled={isSubmitting}
                    placeholder="Имя"
                  />
                )}
              />
            </div>
            <div className="col-sm-3 pr-sm-0">
              {/* E-mail */}
              <Field
                name="email"
                render={({
                  field,
                  form: { isSubmitting, errors, touched }
                }) => (
                  <Input
                    {...field}
                    className="mb-2"
                    value={values.email}
                    size="lg"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    disabled={isSubmitting}
                    placeholder="E-mail"
                  />
                )}
              />
            </div>
            <div className="col-sm-3 pr-sm-0">
              {/* Телефон */}
              <Field
                name="phone"
                render={({
                  field,
                  form: { isSubmitting, errors, touched }
                }) => (
                  <Input
                    {...field}
                    className="mb-2"
                    value={values.phone}
                    size="lg"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    disabled={isSubmitting}
                    placeholder="Телефон"
                  />
                )}
              />
            </div>
            <div className="col-sm-2 pr-sm-0">
              {/* Отправить */}
              <Button
                className="w-100 btn btn-lg btn-warning text-white px-0"
                type="submit"
              >
                Отправить заявку
              </Button>
            </div>
          </div>
        </Form>
      )}
    />
  );
};

export default FormTestAccess;
