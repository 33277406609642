// Library
import React from "react";
import classnames from "classnames";
import styled from "styled-components";

// Image
import arrowGray from "@images/arrow.svg";

const WrapperButton = styled.button`
  &.btn-clear {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: none;
    font-size: var(--main-body-font-size);
    :focus 
    {
      box-shadow: none!important;
    }
  }
  &.btn-link {
    display: inline-flex;
    align-items: center;
    padding: 0;
    font-size: var(--main-body-font-size);
    &:focus {
      box-shadow: none;
    }
  }
  &.btn-arrow {
    width:10px;
    padding: 0;
    &:before
    {
      content:"";
      display: block;
      width: 10px;
      height 7px;
      background: url( ${arrowGray} ) no-repeat
    }
  }
  &.btn-warning-landing {
    background: #FFFBEF
  }
`;

const Button = ({ children, className, size, clear, keyMarker, ...props }) => {
  let sizeGroup = "";
  switch (size) {
    case "sm":
      sizeGroup = "btn-sm";
      break;

    case "md":
      sizeGroup = "";
      break;

    case "lg":
      sizeGroup = "btn-lg";
      break;

    default:
      break;
  }
  const classes = classnames([className, clear ? "btn-clear pl-0" : ""]);

  return (
    <WrapperButton className={classes} {...props}>
      {children}
    </WrapperButton>
  );
};

Button.defaultProps = {
  type: "button"
};

export default Button;
