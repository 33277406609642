// Library
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Action
import * as registerAction from "../../../processes-domain/register/register-actions";

// Component
import PaymentCaptcha from "../views/PaymentCaptcha";

const mapDispatchToProps = dispatch => {
  return {
    addRecaptcha: bindActionCreators(registerAction, dispatch).addRecaptcha
  };
};

export default connect(null, mapDispatchToProps)(PaymentCaptcha);
