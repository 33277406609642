const dictionary = {
  " ActiveLicenses": "Активны подписки",
  " Apply": "Применить",
  " GetAccess": "Получить доступ",
  " IncorrectPassword": "Неверный код подтверждения",
  " LoginCredentials": "Учетные данные для входа в систему:",
  " MinLengthFew": "Поле {0}  должно содержать минимум {1} символа",
  AccessDateActivation: "Доступ активирован с {0} по {1}",
  AccessDay: "Доступ на день ко всем возможностям {0}",
  AccessHalfYear: "Доступ на полгода ко всем возможностям {0}",
  AccessMonth: "Доступ на месяц ко всем возможностям {0}",
  AccessThreeMonth: "Доступ на три месяца ко всем возможностям {0}",
  AccessWeek: "Доступ на неделю ко всем возможностям {0}",
  AccessYear: "Доступ на год ко всем возможностям {0}",
  AccountAlreadyContainsThisVIPCode:
    "учетная запись уже содержит данный VIP-промокод",
  AccountWithTheSameDSAlreadyExist: "учетная запись с таким ЭЦП уже существует",
  AccountWithTheSameEmailAlreadyExist:
    "учетная запись с таким email уже существует",
  Activate: "Активировать",
  ActivationConfirmation: "Подтверждения активации",
  ActivationResult: "Результат активации",
  ActivationTextBasis1:
    "Изучайте связи между компаниями, проверяйте их аффилированность на интерактивной схеме — «Дереве связей».",
  ActivationTextBasis2:
    "Смотрите регистрационные данные компаний и руководящих лиц, проверяйте массовость адреса и телефона.",
  ActivationTextBasis3:
    "Рассказываем о заключенных компаниями и предпринимателями госконтрактах. Владеем информацией, насколько добросовестно они исполняют свои контрактные обязательства.",
  ActivationTextBasis4:
    "Публикуем бухгалтерский баланс и рассчитываем показатели компаний: ликвидность, платежеспособность, финансовую устойчивость, рентабельность, чистые активы.",
  ActivationTextControl1:
    "Будьте в курсе прозрачности и добросовестности закупочной деятельности по статьям 44-ФЗ и 223-ФЗ.",
  ActivationTextControl2:
    "Комплексная оценка закупочной деятельности организации. Темпы освоения бюджета и эффективности закупок, индикаторы рисков.",
  ActivationTextControl3:
    "Своевременное оповещение обо всех аспектах тендерной деятельности:                 проведение запланированных закупок, просрочка позиции, невозможность заключения контракта и т.д.",
  ActivationTextNews1:
    "Актуальная информация о событиях в отрасли, в стране и в мире. Узнавайте о ключевых событиях первыми.",
  ActivationTextNews2:
    "Настройте собственную ленту и получайте ключевые новости по своему профилю, актуальную информацию о партнерах, конкурентах.",
  ActivationTextNews3:
    "Смотрите то, что скрыто. Тональность новостей, досье на компании и персоны, влияние СМИ на их имидж. Самая подробная аналитика 24 часа в сутки 7 дней в неделю.",
  ActivationTextPrice1:
    "Учитывайте разброс цен и указывайте конкурентную стоимость, чтобы в ваших закупках всегда были заявки.",
  ActivationTextPrice2:
    "Используйте коэффициенты, чтобы сделать любую цену контракта обоснованной, учитывая разные условия закупки.",
  ActivationTextPrice3:
    "Ваша цена, подтвержденная обоснованием, будет соответствовать требованиям законодательства и быстро найдет своего поставщика.",
  ActivationTextPro1:
    "Отслеживайте цену и спрос на товары и услуги, будьте в курсе новых закупок c помощью виджетов.",
  ActivationTextPro2:
    "Участвуйте только в тех торгах, где шансы на победу максимально высоки. На основе тендерной истории, оценки потенциальных участников и других факторов создаются персональные прогнозы на победу.",
  ActivationTextPro3:
    "Искусственный интеллект оценит финансовое состояние заказчика, истории закупок, арбитражных дел, чтобы вы знали, с кем предстоит  работать.",
  ActivationTextPro4:
    "Система персонально подбирает профильные тендеры на основе данных о компании, чтобы вы не тратили время на самостоятельный поиск.",
  ActivationTitleBasis1: "Связи и аффилированность",
  ActivationTitleBasis2: "Выписка из ЕГРЮЛ",
  ActivationTitleBasis3: "Контракты и недобросовестные поставщики",
  ActivationTitleBasis4: "Балансы и экспресс-анализ",
  ActivationTitleControl1: "Следите за нарушениями",
  ActivationTitleControl2: "Аналитика закупочной деятельности",
  ActivationTitleControl3: "Мониторинг событий",
  ActivationTitleNews1: "Новости со всего мира",
  ActivationTitleNews2: "Персональная лента",
  ActivationTitleNews3: "Аналитика и искусственный интеллект",
  ActivationTitlePrice1: "Диапазон цен",
  ActivationTitlePrice2: "Корректируйте цену",
  ActivationTitlePrice3: "Подтверждайте цену",
  ActivationTitlePro1: "Анализ торгов",
  ActivationTitlePro2: "Прогноз побед",
  ActivationTitlePro3: "Оценка надежности",
  ActivationTitlePro4: "Лучшие закупки",
  ActiveFrom: "активен с",
  ActiveLicense: "Активная подписка",
  ActiveLicenseCountFew: "{0} активные подписки",
  ActiveLicenseCountMany: "{0} активных подписок",
  ActiveLicenseCountOne: "{0} активная подписка",
  ActualTill: "Действует до",
  AddRemoveRegion: "Добавить/удалить регион",
  AdminPasswordReset:
    "Уважаемый пользователь!{0} Ваш пароль был сброшен администратором. Чтобы установить новый пароль пройдите по {1} ссылке {2} !{3} С уважением,{4}",
  AfterPayment: "После оплаты вы сможете:",
  AlreadyRegistered: "Уже зарегистрированы?",
  AtLeastThanEightSymbols: "Не меньше 8 символов.",
  AuthorizeWithDS: "Авторизация через ЭЦП",
  Balance: "Баланс на счету",
  BasisMailDescription:
    "Сервис анализа и оценки российских и зарубежных компаний.",
  BasisRegistrationTitle:
    "Вы сможете проверить своих партнеров, найти клиентов, узнаете их сильные и слабые стороны.",
  BasisTariffDescription1:
    "24 миллиона российских компаний и предпринимателей будут у вас под рукой. Их возможности и потребности, сильные и слабые стороны. Удобный поиск по названию, реквизитам, контактам.",
  BasisTariffDescription2:
    "Изучайте досье на организации. Смотрите, насколько добросовестно ваш потенциальный партнер выполняет взятые на себя обязательства: финансовые, контрактные, правовые.",
  BasisTariffDescription3:
    "Получайте уведомления о событиях в жизни компаний. Seldon.Basis предупредит и покажет, что не так с вашим партнером, конкурентом. Используйте статистику и аналитику, чтобы получить об организации полное представление.",
  BasisTariffDescription4:
    "Изучайте деятельность компаний из стран СНГ. Проверьте их на предмет честного участия в торгах, проанализируйте финансовое положение. Расширяйте свой бизнес — работайте с зарубежными партнерами.",
  BasisTariffTitle1: "Найти новых клиентов",
  BasisTariffTitle2: "Минимизировать риски",
  BasisTariffTitle3: "Быть в курсе событий",
  BasisTariffTitle4: "Найти партнеров из СНГ",
  Bonuses: "Бонусы",
  BuySubscription: "Купить подписку",
  CanSendIn: "Можно отправить через {0}",
  ChangeData: "Изменить данные",
  ChangePassword: "Изменить пароль",
  ChangesSaved: "Изменения сохранены",
  ChangingEmail_SendToNewEmail:
    "Уважаемый пользователь!{0} Для подтверждения смены почты пройдите по {1} ссылке {2} !{3} С уважением,{4}",
  ChangingEmail_SendToNewEmailTitle: "Подтверждение смены почты",
  ChangingEmail_SendToOldEmail:
    "Уважаемый пользователь!{0}Вами была произведена смена электронной почты, новое значение: {1} Если это делали не Вы, то смените пароль и обратитесь в службу технической поддержки {2}С уважением,{3}",
  ChangingEmail_SendToOldEmailTitle: "Уведомление о смене почты",
  ChangingPassword:
    "Уважаемый пользователь!{0}Ваш пароль был изменен. Если это делали не Вы, то смените пароль и обратитесь в службу технической поддержки{1}С уважением, {2}",
  ChangingPasswordForAdmin:
    "Уважаемый пользователь!{0}Ваш пароль был изменен администратором. Ваш новый пароль {1} С уважением,{2}",
  ChangingPasswordTitle: "Уведомление о смене пароля",
  CheckEmailRecoverPassword:
    "Проверьте электронную почту, чтобы восстановить пароль.",
  ChooseCertificate: "Выберите сертификат",
  ChooseFile: "Выбрать файл",
  ChooseStrongPassword:
    "Придумайте надежный пароль и не используйте его в других местах.",
  CommonSettings: "Общие настройки",
  CommonSignIn: "Обычная авторизация",
  CommonSignUp: "Обычная регистрация",
  Company: "Компания",
  Confirm: "Подтвердить",
  ConfirmActivationTitle:
    "Вы уже зарегистрированы. Получите доступ в один клик",
  ConfirmLicenseCreated_Mail:
    "Уважаемый пользователь!{0}Вы успешно приобрели подписку на продукт {1}С уважением,{2}",
  ConfirmLicenseCreated_MailTitle: "Приобретение подписки",
  ConfirmLicenseUpdated_Mail:
    "Уважаемый пользователь!{0}Вы успешно продлили подписку на продукт {1}С уважением,{2}",
  ConfirmLicenseUpdated_MailTitle: "Продление подписки",
  ConfirmRegister: "Подтверждение регистрации",
  ConfirmRegister_Mail:
    "Уважаемый пользователь!{0}Вы успешно зарегистрированы в сервисе MySeldon. Для смены пароля пройдите по {1}ссылке{2}!{3}С уважением,{4}",
  Consult: "Консультация",
  ConsultText:
    "Если у вас появились вопросы или вы не успели {0} воспользоваться тестовым доступом, {0} нажмите кнопку «консультация»",
  ControlMailDescription:
    "Система профилактики нарушений, анализа и мониторинга закупочной деятельности.",
  ControlRegistrationTitle:
    "Контролируйте свою закупочную деятельность. Анализируйте расходы и оптимизируйте участие в тендерах по 44-ФЗ и 223-ФЗ.",
  ControlTariffDescription1:
    "Быстрый и детальный анализ закупочной деятельности организаций: сэкономит время, ресурсы, и позволит принять взвешенные решения.",
  ControlTariffDescription2:
    "Система позволит минимизировать ваши риски, проводить профилактику: предотвратить нарушения и штрафы по процедурам в рамках 44-ФЗ и  223-ФЗ.",
  ControlTariffTitle1: "Анализировать и мониторить тендерную деятельность",
  CreatePassword: "Создание пароля",
  CurrentPassword: "Старый пароль",
  CurrentSubsriptions: "Текущие подписки",
  DataProtected: "Данные защищены по международному стандарту PCI DSS",
  Day: "{0} день",
  DearUser: "Уважаемый пользователь!",
  Demo: "Демо",
  DemoAccess: "Демо-доступ",
  DemoAccessDateActivation: "Демо-доступ активирован с {0} по {1}",
  DemoAccessDay: "Демо-доступ на {0} день",
  DemoAccessFewDays: "Демо-доступ на {0} дня",
  DemoAccessManyDays: "Демо-доступ на {0} дней",
  Department: "Подразделение",
  DifferenceInExchangeRates:
    "При платеже в иностранной валюте сумма, снятая с вашего счета, может отличаться от указанной стоимости тарифа. Это связано с разницей валютных курсов при обмене валюты банком в момент совершения платежа.",
  DigitalSignatureActivated: "ЭЦП активирована",
  DigitalSignatureAlreadyBinded: "Данная ЭЦП уже привязана к Вашему аккаунту",
  DigitalSignatureAndCertificates: "ЭЦП и сертификаты",
  DigitalSignatureBinded: "ЭЦП успешно привязана",
  DigitalSignatureBindedToAnotherAccount:
    "Данная ЭЦП не может быть привязана к Вашему аккаунту, так как она уже используется другим пользователем",
  DigitalSignatureExpired:
    "Срок действия сертификата истек, используйте другой ключ ЭЦП",
  DigitalSignatureIsNotConfidential:
    "ЭЦП выдана УЦ, не входящим в зону доверия",
  DigitalSignatureSettings: "Настройка ЭЦП",
  DigitalSignatureWithdrawn:
    "ЭЦП находится в реестре отозванных сертификатов, используйте другой ключ ЭЦП",
  Edit: "Изменить",
  Email: "Электронная почта",
  EmailAlreadyExist:
    "Вы не можете сменить значение почты на указанное, так как такое значение уже есть в системе",
  EmailOrPhone: "Электронная почта или телефон",
  EmailSignature: "Команда {0}",
  EndLicense: "до",
  EnterCode: "Введите код из смс",
  EnterCodeConfirmPhone:
    "Введите код из сообщения, для подтверждения нового номера телефона",
  EnterEmailOrPhoneNumber: "Введите адрес электронной почты или номер телефона",
  EnterMailOrPhone: "Введите адрес электронной почты или номер телефона",
  EnterYourEmail:
    "Укажите электронную почту – на нее мы пришлем ссылку для входа и другие инструкции.",
  ExceededRegisterPromo:
    "Превышено количество регистраций по данному промокоду",
  ExtendedTestAccess: "Продлен тестовый доступ",
  ExtendedTestAccessText: "Вам был продлен тестовый доступ в системе mySeldon.",
  FewDays: "{0} дня",
  Finances: "Финансы",
  FirstName: "Имя",
  ForgotPassword: "Забыли пароль?",
  ForgotPasswordError:
    "Учетной записи с указанным вами адресом электронной почты или номером телефона не существует. Пройдите {0}регистрацию{1}",
  FreeAccessDayProduct: "Доступ бесплатно на {0} день {1}к {2}",
  FreeAccessFewDays: "Бесплатный доступ на {0} дня",
  FreeAccessFewDaysProduct: "Доступ бесплатно на {0} дня {1}к {2}",
  FreeAccessManyDays: "Бесплатный доступ на {0} дней",
  FreeAccessManyDaysProduct: "Доступ бесплатно на {0} дней {1}к {2}",
  FreeAccessOneDay: "Бесплатный доступ на {0} день",
  FreeAccessProduct: "Доступ к системе {0}",
  FreeAccessProductActivate: "Бесплатный доступ к {0} активирован",
  FutureLicense: "Будущая подписка",
  FutureSubscribeOne: "У Вас есть еще {0} будущая подписка",
  FutureSubscribesFew: "У Вас есть еще {0} будущие подписки",
  FutureSubscribesMany: "У Вас есть еще {0} будущих подписок",
  Gender: "Пол",
  GenderFemale: "Женский",
  GenderMale: "Мужской",
  GetAccessToInfo:
    "Продукты для бизнеса, государства и граждан. Экономические прогнозы, аналитика, оценка последствий принимаемых мер по борьбе с коронавирусом, торговые инструменты для предпринимателей и другие возможности",
  GetDemoAccess: "Получить демо-доступ",
  GetProfit: "Получать прибыль",
  GetProfitDescription:
    "Побеждайте в торгах, зная заранее свои шансы. Сервис оценит ваш опыт участия в тендерах, финансовые возможности, судебные дела и ещё 25 параметров, проанализирует и порекомендует лучшие закупки.",
  GoToSystem: "Перейти в систему",
  HavePromo: "есть промо-код?",
  InactivePromo: "Неактивный промокод",
  IncorrectEmailEntered: "введен некорректный email",
  IncorrectPassword: "Неверный пароль",
  IncorrectPasswordEntered: "введен некорректный пароль:",
  IncorrectPhoneEntered: "введен некорректный телефон",
  InvalidPromo: "Неверный промокод",
  IsSendSms: "СМС не пришло?",
  LastName: "Фамилия",
  LicenseAgreement: "Лицензионное соглашение",
  LicenseCountFew: "{0} подписки",
  LicenseCountMany: "{0} подписок",
  LicenseCountOne: "{0} подписка",
  LinkHasExpired:
    "Ссылка из письма устарела. Запросите восстановление пароля заново.",
  Login: "Логин",
  LoginPageHeader: "Вход",
  LoginToAccess: "Логин для доступа в систему:",
  LogOff: "Выйти",
  ManageRisks: "Управлять рисками",
  ManageRisksDescription:
    "Проверяйте заказчиков и конкурентов, выявляйте закупки, заточенные под конкретного поставщика. С Seldon.Pro вы будете знать, с кем безопасно работать.",
  ManyDays: "{0} дней",
  MinPasswordLength: "Минимальная длина пароля –{0} символов.",
  NewPassword: "Новый пароль",
  NewsMailDescription: "Агрегатор новостей российских и зарубежных СМИ.",
  NewsRegistrationTitle:
    "Актуальные новости со всего мира, досье на компании, регионы, медийных персон.",
  NoActiveLicense: "Нет активных подписок",
  NotFound: "Ничего не найдено",
  NoTransactions: "Пока что у вас нет транзакций.",
  NotRobot: "Вы не подтвердили, что вы не робот",
  OGRN: "ОГРН",
  OgrnOrInn: "ОГРН или ИНН",
  OnDay: "На {0} день",
  OnFewDays: "На {0} дня",
  OnlinePayment: "Онлайн-оплата",
  OnManyDays: "На {0} дней",
  OpenAccess: "Открыт доступ",
  OrderDigitalSignature: "Заказать ЭЦП",
  OrderDigitalSignatureSettings: "Заказать настройку ЭЦП",
  Password: "Пароль",
  PasswordChanged: "Пароль изменен",
  PasswordHasAPartOfLoginName:
    "пароль не должен содержать имя учетной записи или часть полного имени пользователя длиной более двух рядом стоящих знаков",
  PasswordMail: "{0} — ваш пароль для {1}.",
  PasswordMustBeAtLeast8Symbols:
    "Пароль должен иметь длину не менее 8 символов",
  PasswordMustContainsLettersAndDigits:
    "Пароль должен содержать латинские заглавные буквы (от A до Z) и цифры (от 0 до 9), или латинские строчные буквы (от a до z) и цифры (от 0 до 9), или латинские заглавные буквы (от A до Z) и латинские строчные буквы (от a до z)",
  PasswordNotUpToDate: "Пароль не актуален, повторите регистрацию",
  PasswordRequirementsError:
    "Пароль не удовлетворяет требованиям безопасности:",
  PasswordsMismatch:
    "Значения полей «Новый пароль» и «Повторите» не совпадают, повторите попытку",
  PaymentButton: "Перейти к оплате",
  PaymentMethod: "Способ оплаты",
  PaymentText_Mail: "Ваша оплата сервиса {0} прошла успешно.",
  PersonalInfo: "Персональная информация",
  Phone: "Телефон",
  PhoneNumberAlreadyExist:
    "Вы не можете сменить значение телефона на указанное, так как такое значение уже есть в системе",
  PhoneOrEmailBusy: "Пользователь с таким телефоном или email уже существует",
  PlatformsWithContracts: "Площадки, с которыми заключены соглашения",
  PleaseFillRequirementFields:
    "Не все обязательные поля заполнены. Заполните поля {0}",
  Position: "Должность",
  PriceMailDescription:
    "Сервис для подбора контрактов для обоснования начальной максимальной цены.",
  PriceRegistrationTitle:
    "Подготовьте обоснование НМЦК максимально легко и быстро. Проанализируйте рынок электронных торгов, узнайте цены и активность конкурентов.",
  PriceTariffDescription1:
    "Подбирайте контракты для обоснования начальной цены за секунды. Выгружайте готовое обоснование одной кнопкой и включайте его в документацию закупки. Будьте уверены, что ваше ценовое предложение соответствует законодательству.",
  PriceTariffDescription2:
    "Анализируйте цены на товары и услуги, чтобы сделать свой бизнес более эффективным. Изучайте выполненные контракты конкурентов, смотрите динамику цен, наблюдайте сезонность спроса и предложения.",
  PriceTariffDescription3:
    "Используйте коэффициенты, чтобы сделать любую цену обоснованной и заинтересовать поставщиков. Можно задать повышающий коэффициент, дефлятор, учесть другие условия закупки. С помощью Seldon.Price в ваших закупках всегда будут заявки.",
  PriceTariffTitle1: "Обосновывать НМЦК",
  PriceTariffTitle2: "Анализировать рынок",
  PriceTariffTitle3: "Привлекать новых поставщиков",
  PrivacyPolicy: "Политика конфиденциальности",
  PrivacyPolicyAndOffer:
    "Нажимая «Перейти к оплате», вы подтверждаете, что ознакомились и принимаете условия {0}Политики конфиденциальности{1} и {2}Оферты{3}.",
  PrivatePolicyRegister:
    "Указывая свои персональные данные в форме, вы соглашаетесь на их обработку в соответствии с {0}Политикой конфиденциальности{1}",
  ProductCountFew: "на {0} продукта",
  ProductCountMany: "на {0} продуктов",
  ProductCountOne: "на {0} продукт",
  Prolong: "Продлить",
  ProMailDescription:
    "сервис с искусственным интеллектом для работы с тендерами.",
  PromoAnotherProduct: "Указан промокод на другой продукт",
  Promocode: "Промокод",
  PromoExpired: "Срок действия промокода истек",
  PublicOffer:
    "Публичная оферта (далее Оферта) согласно п. 2 ст. 437 Гражданского кодекса Российской Федерации является официальным предложением заключить с любым юридическим, физическим лицом, индивидуальным предпринимателем (далее Пользователь) договор на условиях, предусмотренных Офертой. Договор заключается не иначе как путем присоединения к нему в целом путем акцепта. Акцептом Оферты является уплата Пользователем лицензионного вознаграждения. Договор при этом считается заключенным в письменной форме и является равносильным договору, подписанному двумя сторонами.",
  PurchasingRegions: "Регионы вашей закупочной деятельности",
  RecoveryPassword: "Восстановление пароля",
  RecoveryPassword_Mail:
    "Уважаемый пользователь!{0}Для смены пароля пройдите по {1}ссылке{2}!{3}С уважением,{4}",
  RecoveryPassword2_Mail:
    "Вы воспользовались формой восстановления пароля, для восстановления пароля перейдите по {0}ссылке{1} .  Если это делали не Вы, то смените пароль и обратитесь в службу технической поддержки",
  Refill: "Пополнить",
  Register: "Регистрация",
  RegisterToStart: "Зарегистрируйтесь, чтобы начать работать",
  RegistrationСompleted: "Регистрация завершена",
  RememberMe: "Запомнить",
  RemovePromo: "Удалить промокод",
  RenewSubscription: "Продлить подписку",
  RepeatNewPassword: "Повторите новый пароль",
  RequiredField: "обязательное поле",
  Resend: "Отправить повторно",
  Restore: "Восстановить",
  Save: "Сохранить",
  SavePasswordSuccess: "Пароль успешно сохранен",
  SavePolicy:
    "Нажимая «Сохранить», вы подтверждаете, что ознакомились и принимаете условия {0}Политики конфиденциальности{1}.",
  SaveUnavailable: "Сохранение невозможно:",
  Search: "Поиск",
  SearchInBasis: "Найдите информацию о себе в Seldon.Basis",
  Security: "Безопасность",
  SelectedRegions: "Выбранные регионы",
  SelectRegion: "Выберите регион",
  Send: "Отправить",
  SendAgain: "Отправить еще раз",
  SendCode: "Код отправлен",
  SendPasswordOrCreateNew:
    'Ваш пароль был отправлен в качестве кода на телефон. Нажмите на кнопку "Сменить пароль", чтобы установить собственный пароль.',
  SendSmsWithPassword:
    "На этот номер мы вышлем SMS с паролем для входа в систему",
  SendToNumber: "Его мы отправили на ваш номер",
  Settings: "Настройки",
  Show: "Показать",
  ShowVideo: "Смотреть видео",
  SignIn: "Войти",
  SignInBaseID: "Войти с Основание ID",
  SignInToContinue: "Вход в сервис",
  SignInSystem: "Войдите в систему",
  SignUpWithDS: "Регистрация через ЭЦП",
  SincerelyTeam: "С уважением,{0} команда Seldon",
  SingleLoginSeldon: "Единый доступ ко всем сервисам РСПП",
  SliderBasisInfo:
    "Получайте аналитику, прогнозы и модели развития ситуации в области здравоохранения и экономики. Минимизируйте риски и принимайте взвешенные решения по управлению бизнес-процессами.",
  SliderNewsInfo:
    "Главный информационный центр по коронавирусу для граждан и предпринимателей в РФ, агрегирующий актуальную и достоверную информацию по данной теме.",
  SliderPriceInfo:
    "Подбирайте контракты для обоснования начальной максимальной цены. Анализируйте цены на товары и услуги, чтобы сделать свой бизнес более конкурентным.",
  SliderProInfo:
    "Находите деловых партнеров и заключайте сделки без сложных закупочных процедур. ",
  Snap: "Привязать",
  SnapDigitalSignature: "Привязать ЭЦП",
  StartLicense: "с",
  StartUsing: "Начать пользоваться",
  Subscribe: "Оформить подписку",
  SuccessRegister_Mail: "Вы успешно зарегистрировались в системе {0}.",
  SupportMailLink:
    "Если у вас появились вопросы, нажмите {0}«Узнать подробнее»{1} и специалист поможет вам.",
  SystemLanguage: "Язык в системе",
  Text403:
    "Вы не можете посмотреть эту страницу. Обратитесь в поддержку, если думаете, что произошла ошибка.",
  Text404:
    "Возможно, вы ошиблись, набирая ссылку.{0} Попробуйте начать с {1}главной страницы{2}",
  Text500:
    "Извините, но сервис пока недоступен. Попробуйте повторить запрос или перейдите на {0}главную страницу{1}.",
  TillDate: "до {0}",
  TimeLeft: "Осталось {0} секунд",
  Title403: "Доступ закрыт",
  Title404: "Страница не найдена",
  Title500: "Произошла ошибка",
  ToSaveTime: "Экономить время",
  ToSaveTimeDescription:
    "Вся информация — в одном месте и обновляется в реальном времени. Не нужно искать новые закупки, проверять прозрачность процедур, анализировать заказчиков. Тендеры, которые вам подойдут, теперь под рукой.",
  Total: "Итого:{0}",
  Transactions: "Транзакции",
  TryOtherProducts: "Попробуйте и другие наши продукты",
  UploadNewPhoto: "Загрузить другое фото",
  UserNotFound: "Пользователь не найден",
  WeNotFind: "Мы ничего не нашли, попробуйте {0}найти в {1}",
  WeSentCodeConfirm:
    "Мы отправили Вам код-пароль на номер телефона, подтвердите его получение:",
  WrongOldPassword: "Неправильный пароль.",
  WrongPassword: "Введен ошибочный текущий пароль, повторите попытку",
  YouCanFindInfoInBasis:
    "Вы всегда можете найти {0}информацию в Seldon Basis{1}",
  YourLogin: "Ваш логин:"
};
export default dictionary;
