import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Component
import Landing from "../views/Landing";
import * as landingAction from "../../../processes-domain/landing/actions";
import * as globalAction from "../../../processes-domain/global/global-actions";

const mapDispatchToProps = dispatch => {
  return {
    setSuccessModalShow: bindActionCreators(globalAction, dispatch)
      .setSuccessModalShow,
    setErrorModalShow: bindActionCreators(globalAction, dispatch)
      .setErrorModalShow,
    getAccessDemo: async (values, actions) => {
      let model = {
        name: values.name,
        email: values.email,
        phone: values.phone
      };

      var res = await bindActionCreators(landingAction, dispatch).getAccessDemo(
        model
      );

      //Success;
      if (!res.success) {
        actions.setErrors({ api: res.errors[0] });
        actions.setSubmitting(false);
        return res;
      }

      return res;
    },
    getLandingTariffs: bindActionCreators(landingAction, dispatch)
      .getLandingTariffs
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Landing));
