const dictionary = {
  " ActiveLicenses": "Лицензи",
  " Apply": "Приложи",
  " GetAccess": "Получете достъп",
  " IncorrectPassword":
    "Предоставената парола е невалидна. Моля, въведете валидна парола.",
  AccessDay: "1 ден",
  AccessHalfYear: "на {0}",
  AccessMonth: "{0} месец",
  AccessThreeMonth: "{0} месеца",
  AccessWeek: "на {0}",
  AccessYear: "{0} година",
  AccountWithTheSameDSAlreadyExist: "Запис РДЛ № {0}",
  AccountWithTheSameEmailAlreadyExist: "Запис РДЛ № {0}",
  Activate: "Активирате",
  ActivationTextBasis1: "Принадлежност",
  ActivationTextBasis2: "Регистрационни данни",
  ActivationTextBasis3: "Задължения, {0}",
  ActivationTextBasis4: "Счетоводен баланс",
  ActivationTextControl1: "Бъдете в течение",
  ActivationTextControl2: "Организации",
  ActivationTextControl3: "{0}{1} договора{2}",
  ActivationTextNews1: "Информация",
  ActivationTextNews2: "Получавайте",
  ActivationTextNews3: "Ключови новини",
  ActivationTextPrice1: "Стойност",
  ActivationTextPrice2: "Използвайте",
  ActivationTextPrice3: "Цена",
  ActivationTextPro1: "Бъдете в течение",
  ActivationTextPro3: "Финансово състояние",
  ActivationTextPro4: "Компании",
  ActivationTitleBasis1: "Принадлежност",
  ActivationTitleBasis2: "Извлечение от ЕГРЮЛ в pdf",
  ActivationTitleBasis3: "Доставчици",
  ActivationTitleBasis4: "Експресен анализ",
  ActivationTitleControl1: "{0} за {1}",
  ActivationTitleControl2: "Анализ",
  ActivationTitleControl3:
    "Мониторинг на развитие на проекта (целево използване на ресурсите, финансови показатели), маркетингова помощ, проектна консултация",
  ActivationTitleNews1: "Компании",
  ActivationTitleNews3: "Анализ",
  ActivationTitlePro1: "анализ",
  ActivationTitlePro2: "Прогноза",
  ActivationTitlePro3:
    "Индексът благонадеждност — това е оценка за надеждността на съществуващата търговската компания.",
  ActivationTitlePro4: "Възможности",
  ActiveFrom: "от {0}",
  ActiveLicense: "{0} лиценз",
  ActiveLicenseCountFew: "Лицензи",
  ActiveLicenseCountMany: "Лиценза",
  ActiveLicenseCountOne: "{0} лиценз",
  ActualTill: "Валиден до {0}",
  AddRemoveRegion: "Добави",
  AdminPasswordReset: "Нова парола",
  AlreadyRegistered: "Вече сте регистрирани? {0} Влезте в системата{1}.",
  AtLeastThanEightSymbols: "По - малко {0}",
  AuthorizeWithDS: "Потвърждение",
  Balance: "Баланс",
  BasisRegistrationTitle: "Проверка",
  BasisTariffDescription1: "Възможности",
  BasisTariffDescription2: "Задължения, {0}",
  BasisTariffDescription3: "Организации",
  BasisTariffDescription4: "Дейност",
  BasisTariffTitle1: "Намиране",
  BasisTariffTitle2: "Рискове",
  BasisTariffTitle3: "{0} събитията",
  BasisTariffTitle4: "Намиране",
  Bonuses: "Получавайте бонуси",
  BuySubscription: "Купи абонамент",
  CanSendIn: "Изпрати",
  ChangeData: "Редактирам",
  ChangePassword: "Промяна на паролата",
  ChangingEmail_SendToNewEmail: "С уважение, {0} екипа на {1}",
  ChangingEmail_SendToNewEmailTitle: "Потвърждение",
  ChangingEmail_SendToOldEmail: "С уважение, {0} екипа на {1}",
  ChangingPassword: "С уважение, {0} екипа на {1}",
  ChangingPasswordForAdmin: "Нова парола",
  CheckEmailRecoverPassword: "Възстановяване",
  ChooseFile: "Изберете файл",
  ChooseStrongPassword: "Използвайте",
  CommonSettings: "Настройки",
  CommonSignIn: "Потвърждение",
  CommonSignUp: "Регистрация",
  Company: "Компания",
  Confirm: "Потвърждавам",
  ConfirmActivationTitle: "Вече сте регистрирани? {0} Влезте в системата{1}.",
  ConfirmLicenseCreated_Mail: "Потребител",
  ConfirmLicenseUpdated_Mail: "Потребител",
  ConfirmRegister: "Потвърждение на регистрацията",
  ConfirmRegister_Mail: "Потребител",
  Consult:
    "Изпратете запитване, ако имате нужда от консултация. Ние ще се свържем с вас в най-скоро време.",
  ControlMailDescription: "Не",
  ControlRegistrationTitle: "Контролирайте",
  ControlTariffDescription1: "{} Организации",
  ControlTariffDescription2: "Рискове",
  ControlTariffTitle1: "Дейност",
  CreatePassword: "Създаване на парола",
  CurrentPassword: "Стара парола",
  CurrentSubsriptions: "Текущи",
  DataProtected: "Данните са защитени с международен стандарт PCI DSS",
  Day: "1 ден",
  DearUser: "Потребител",
  Demo: "Демо достъп",
  DemoAccess: "Демо достъп",
  DemoAccessDay: "Демо достъп",
  DemoAccessFewDays: "Демо достъп",
  DemoAccessManyDays: "Демо достъп",
  Department: "Подразделение на Централната Банка на Руската Федерация",
  DifferenceInExchangeRates: "Размер",
  DigitalSignatureAndCertificates: "и",
  DigitalSignatureExpired: "Валидност",
  DigitalSignatureWithdrawn: "Използвайте",
  Edit: "Редактирам",
  Email: "E-mail",
  EmailAlreadyExist: "на {0}",
  EmailOrPhone: "E-mail",
  EmailSignature: "екипа на Seldon.Basis",
  EndLicense: "До",
  EnterCode: "Въведете",
  EnterCodeConfirmPhone: "Съобщения",
  EnterEmailOrPhoneNumber: "Адреса на електронната поща",
  EnterMailOrPhone: "Адреса на електронната поща",
  EnterYourEmail: "Въведете e-mail адрес",
  ExceededRegisterPromo: "Брой",
  FewDays: "2 дни",
  Finances: "Финанси",
  FirstName: "Име",
  ForgotPassword: "Забравена парола?",
  ForgotPasswordError: "Всички записи",
  FreeAccessDayProduct: "1 ден",
  FreeAccessFewDays: "2 дни",
  FreeAccessFewDaysProduct: "2 дни",
  FreeAccessManyDays: "{0} дни",
  FreeAccessManyDaysProduct: "{0} дни",
  FreeAccessOneDay: "1 ден",
  FreeAccessProduct: "Безплатен достъп към системата {0}.",
  FutureLicense: "{0} лиценз",
  FutureSubscribeOne: "Абонамент",
  FutureSubscribesFew: "Още",
  FutureSubscribesMany: "Още",
  Gender: "Пол:",
  GenderFemale: "Женски",
  GenderMale: "Мъжки",
  GetAccessToInfo: "информация",
  GetDemoAccess: "Получте демо достъп",
  GetProfit: "Печалба",
  GetProfitDescription: "Възможности",
  HavePromo: "Имам промо код",
  IncorrectEmailEntered: "Некоректен {0}",
  IncorrectPassword:
    "Предоставената парола е невалидна. Моля, въведете валидна парола.",
  IncorrectPasswordEntered: "Некоректен {0}",
  IncorrectPhoneEntered: "Некоректен {0}",
  LastName: "Фамилия",
  LicenseAgreement: "Лицензно споразумение",
  LicenseCountFew: "Лицензи",
  LicenseCountMany: "Лиценза",
  LicenseCountOne: "{0} лиценз",
  LinkHasExpired: "Забравена парола",
  Login: "Потребителско име",
  LoginPageHeader: "Вход",
  LoginToAccess: "Потребителско име",
  LogOff: "Изход",
  ManageRisksDescription: "Клиенти",
  ManyDays: "{0} дни",
  NewPassword: "Нова парола",
  NewsMailDescription: "Ключови",
  NewsRegistrationTitle: "Компании",
  NoActiveLicense: "Лиценза",
  NotFound: "Нищо не е намерено.",
  NoTransactions: "Не",
  OGRN: "ОРГН",
  OgrnOrInn: "ОРГН",
  OnDay: "Достъп до услугата за 31 дена",
  OnFewDays: "2 дни",
  OnlinePayment: "Онлайн",
  OnManyDays: "{0} дни",
  Password: "Парола",
  PasswordChanged: "Парола",
  PasswordHasAPartOfLoginName: "Парола",
  PasswordMail: "Парола",
  PasswordMustBeAtLeast8Symbols: "Парола",
  PasswordMustContainsLettersAndDigits: "Парола",
  PasswordNotUpToDate: "Парола",
  PasswordRequirementsError: "Парола",
  PasswordsMismatch: "Нова парола",
  PaymentButton: "Отидете на плащане",
  PaymentMethod: "Метод на плащане:",
  PaymentText_Mail: "Плащане",
  PersonalInfo: "Персонална информация",
  Phone: "Телефон",
  PhoneNumberAlreadyExist: "на {0}",
  PhoneOrEmailBusy: "Потребител",
  PlatformsWithContracts: "от {0}",
  PleaseFillRequirementFields: "Всички",
  Position: "Длъжност",
  PriceMailDescription: "{0}{1} договора{2}",
  PriceTariffDescription1: "Съответства",
  PriceTariffDescription2: "Договори",
  PriceTariffTitle2: "Доставчици",
  PriceTariffTitle3: "Доставчици",
  PrivacyPolicy: "Политика",
  PrivacyPolicyAndOffer: "Отидете на плащане",
  PrivatePolicyRegister: "Лични данни",
  ProductCountFew: "на {0}",
  ProductCountMany: "на {0}",
  ProductCountOne: "Продукт",
  Prolong: "Продължете",
  ProMailDescription: "от {0}",
  PromoExpired: "Валидност",
  PublicOffer:
    "Публична оферта (по - нататък Оферта) съгласно п. 2 ст. 437 Гражданския кодекс на Руската Федерация се явява официално предложение за сключване с всички юридически, физически лица, индивидуални предприематели (по - нататък Ползвател) договорните условия посочени в Офертата. Сключване на Договора не е друг начин, за присъединяване към него като цяло с приемането. Акцента на Офертата се явява плащането на Ползвателя по отношение на лицензионното възнаграждение.  Договора при това се сключва в писмена форма и се явява еквивалентен на договор, подписан от двете страни.",
  PurchasingRegions: "Регион",
  RecoveryPassword: "Забравена парола",
  RecoveryPassword_Mail: "Потребител",
  RecoveryPassword2_Mail: "Парола",
  Refill: "Презаредете сметката",
  Register: "Регистрация",
  RegisterToStart: "Регистрирай се",
  RegistrationСompleted: "Регистрация",
  RememberMe: "Запомни ме",
  RenewSubscription: "Продължете абонамента",
  RepeatNewPassword: "Повторете новата парола",
  Resend: "Изпрати",
  Restore: "Възстановяване",
  Save: "Запиши",
  SavePasswordSuccess: "Парола",
  SavePolicy: "Запиши",
  SaveUnavailable: "Запазва се...",
  Search: "Търсене",
  SearchInBasis: "За мен:",
  SelectedRegions: "Регион",
  SelectRegion: "Регион",
  Send: "Изпрати",
  SendAgain: "Изпрати",
  SendCode: "Код",
  SendPasswordOrCreateNew: "Телефон",
  SendSmsWithPassword:
    "На този телефонен номер ние ще изпратим SMS с паролата за вход в системата",
  SendToNumber: "Номер",
  Settings: "Настройки",
  Show: "Покажи",
  ShowVideo: "Видео",
  SignIn: "Влез",
  SignInSystem: "Вече сте регистрирани? {0} Влезте в системата{1}.",
  SignUpWithDS: "Регистрация",
  SincerelyTeam: "С уважение, {0} екипа на {1}",
  SliderBasisInfo: "Задължения, {0}",
  SliderNewsInfo: "Бъдете в течение",
  SliderPriceInfo: "Договори",
  SliderProInfo: "от {0}",
  StartLicense: "от {0}",
  StartUsing: "Начало на използване",
  Subscribe: "Закупуването на абонамент се провали. Възникна грешка.\\n\\n%s",
  SupportMailLink: "Подробности",
  SystemLanguage: "Език в състемата",
  Text403: "Възникна грешка",
  Text404: "Опитайте",
  Text500: "На главната страница",
  TillDate: "До",
  Title404: "Страницата не е намерена",
  Title500: "Възникна грешка",
  ToSaveTimeDescription: "Прозрачност на процедурите",
  Total: "Общо: {0}",
  TryOtherProducts: "Опитайте",
  UploadNewPhoto: "Качване на нова снимка",
  UserNotFound: "Потребител",
  WeNotFind: "Опитайте",
  WeSentCodeConfirm: "Телефон",
  WrongOldPassword: "Грешна парола",
  WrongPassword: "Текуща парола",
  YouCanFindInfoInBasis: "Намиране",
  YourLogin: "Потребителско име"
};
export default dictionary;
