const dictionary = {
  " ActiveLicenses": "Assinaturas ativas",
  " Apply": "Aplicar",
  " GetAccess": "Obter acesso",
  " IncorrectPassword": "Código de verificação inválido",
  " LoginCredentials": "Dados de identificação para entrar no sistema:",
  " MinLengthFew": "O campo {0} deve conter pelo menos {1} caracteres",
  AccessDateActivation: "O acesso está válido de {0} a {1}",
  AccessDay: "Acesso a todas as funcionalidades do {0} durante um dia",
  AccessHalfYear: "Acesso a todas as funcionalidades do {0} durante seis meses",
  AccessMonth: "Acesso a todas as funcionalidades do {0} durante um mês",
  AccessThreeMonth:
    "Acesso a todas as funcionalidades do {0} durante três meses",
  AccessWeek: "Acesso a todas as funcionalidades do {0} durante uma semana",
  AccessYear: "Acesso a todas as funcionalidades do {0} durante um ano",
  AccountAlreadyContainsThisVIPCode:
    "a conta já contém este código promocional VIP",
  AccountWithTheSameDSAlreadyExist:
    "uma conta com esta assinatura eletrónica já existe",
  AccountWithTheSameEmailAlreadyExist: "uma conta com este e-mail já existe",
  Activate: "Ativar",
  ActivationConfirmation: "Confirmação de ativação",
  ActivationResult: "Resultado de ativação",
  ActivationTextBasis1:
    "Estude as comunicações entre as empresas, verifique a sua afiliação no esquema interativo “Estrutura em árvore”.",
  ActivationTextBasis2:
    "Veja os dados de registo de empresas e dirigentes, verifique o envolvimento maciço do endereço e número de telefone.",
  ActivationTextBasis3:
    "Relatamos sobre os contratos públicos celebrados por empresas e empreendedores. Conhecemos se eles cumpram conscienciosamente as suas obrigações de contrato.",
  ActivationTextBasis4:
    "Publicamos o balanço patrimonial e calculamos os indicadores de empresas: liquidez, solvibilidade, estabilidade financeira, rentabilidade e ativos líquidos.",
  ActivationTextControl1:
    "Esteja ciente da transparência e consciência das atividades de compras de acordo com os artigos 44-FZ e 223-FZ.",
  ActivationTextControl2:
    "Avaliação abrangente das atividades de compras de empresa. Ritmo de desenvolvimento orçamentário e eficiência de compras, indicadores de riscos.",
  ActivationTextControl3:
    "Notificação oportuna de todos os aspectos da atividade de licitação: execução de aquisição planejada, atraso de posição, impossibilidade de concluir um contrato, etc.",
  ActivationTextNews1:
    "Informações atuais sobre eventos no setor, no país e no mundo. Seja o primeiro a aprender sobre os principais eventos.",
  ActivationTextNews2:
    "Configure seu próprio feed de notícias e receba notícias importantes por seu perfil, informações atuais sobre parceiros, concorrentes.",
  ActivationTextNews3:
    "Veja o que está oculto. A tonalidade de notícias, o dossiê sobre de empresas e pessoas, a influência da mídia em sua imagem. O análise mais detalhado 24 horas por dia, 7 dias por semana.",
  ActivationTextPrice1:
    "Considere a variabilidade de preços e indique preços competitivos para que suas compras sempre tenham pedidos.",
  ActivationTextPrice2:
    "Use os coeficientes para argumentar qualquer preço do contrato, levando em conta diferentes condições de compra.",
  ActivationTextPrice3:
    "O seu preço confirmado pela argumentação corresponderá às exigências da lei e encontrará rapidamente o seu fornecedor.",
  ActivationTextPro1:
    "Observe o preço e a demanda de produtos e serviços, estará ciente de novas compras usando widgets.",
  ActivationTextPro2:
    "Participe apenas nos leilões em quais tem uma chance real de ganhar. Com base no historial de leilões, avaliação de potenciais participantes e outros fatores, são criadas previsões pessoais para a vitória.",
  ActivationTextPro3:
    "A inteligência artificial avaliará a situação financeira do cliente, o historial de aquisições, os casos de arbitragem, para que você saiba com quem vai trabalhar.",
  ActivationTextPro4:
    "O sistema seleciona pessoalmente propostas especializadas com base nos dados da empresa para que você não perca tempo para pesquisa independente.",
  ActivationTitleBasis1: "Comunicações afiliação",
  ActivationTitleBasis2: "Extrato de EGRUL",
  ActivationTitleBasis3: "Contratos e fornecedores desonestos",
  ActivationTitleBasis4: "Balanços e análise rápida",
  ActivationTitleControl1: "Preste atenção às violações",
  ActivationTitleControl2: "Análise de atividades de compras",
  ActivationTitleControl3: "Monitoramento de eventos",
  ActivationTitleNews1: "Notícias de todo o mundo",
  ActivationTitleNews2: "Feed de notícias pessoal",
  ActivationTitleNews3: "Análise e Inteligência Artificial",
  ActivationTitlePrice1: "Faixa de preços",
  ActivationTitlePrice2: "Corrija o preço",
  ActivationTitlePrice3: "Confirme o preço",
  ActivationTitlePro1: "Análise de licitações",
  ActivationTitlePro2: "Previsão de vencedores",
  ActivationTitlePro3: "Avaliação de fiabilidade",
  ActivationTitlePro4: "Melhores compras",
  ActiveFrom: "ativo a partir de",
  ActiveLicense: "Assinatura ativa",
  ActiveLicenseCountFew: "{0} assinaturas ativas",
  ActiveLicenseCountMany: "{0} assinaturas ativas",
  ActiveLicenseCountOne: "{0} assinatura ativa",
  ActualTill: "Está em vigor até",
  AddRemoveRegion: "Adicionar/remover a região",
  AdminPasswordReset:
    "Caro utilizador!{0} A sua palavra-passe foi reiniciada pelo administrador. Para definir uma nova palavra-passe vá para {1} link {2} !{3} Respeitosamente,{4}",
  AfterPayment: "Após o pagamento, você poderá:",
  AlreadyRegistered: "Já está registado?",
  AtLeastThanEightSymbols: "Pelo menos 8 caracteres.",
  AuthorizeWithDS: "Autorização através da assinatura eletrónica",
  Balance: "Saldo da conta",
  BasisMailDescription:
    "Serviço de análise e avaliação de empresas russas e estrangeiras.",
  BasisRegistrationTitle:
    "Pode verificar seus parceiros, encontrar clientes, aprender seus pontos fortes e fracos.",
  BasisTariffDescription1:
    "24 milhões de empresas e empreendedores russos estarão à mão. Suas capacidades e necessidades, seus pontos fortes e fracos. Pesquisa prática por nome, detalhes, contatos.",
  BasisTariffDescription2:
    "Estude os dossiês de organizações. Descubra se seu parceiro potencial cumpre conscienciosamente suas obrigações financeiras, contratuais e legais",
  BasisTariffDescription3:
    "Receba notificações sobre eventos da vida de empresas. O Seldon.Basis avisará e mostrará o que há de errado com seu parceiro, concorrente. Use estatísticas e análises para obter uma visão completa de empresa.",
  BasisTariffDescription4:
    "Estude as atividades das empresas dos países da CEI. Verifique-as para uma participação justa no leilão, analise a situação financeira. Desenvolva seu negócio — trabalhe com parceiros estrangeiros.",
  BasisTariffTitle1: "Procurar novos clientes",
  BasisTariffTitle2: "Minimizar riscos",
  BasisTariffTitle3: "Esteja ciente dos eventos",
  BasisTariffTitle4: "Procurar os parceiros da CEI",
  Bonuses: "Bónus",
  BuySubscription: "Comprar uma assinatura",
  CanSendIn: "Pode enviar através de {0}",
  ChangeData: "Editar os dados",
  ChangePassword: "Alterar a palavra-passe",
  ChangesSaved: "As alterações foram guardadas",
  ChangingEmail_SendToNewEmail:
    "Caro utilizador!{0} Para confirmar a mudança do endereço de e-mail, siga o {1} link {2} !{3} Respeitosamente,{4}",
  ChangingEmail_SendToNewEmailTitle:
    "Confirmação da mudança do endereço de e-mail",
  ChangingEmail_SendToOldEmail:
    "Caro utilizador!{0}Alterou o endereço de e-mail, o novo e-mail: {1} Se você não fez isso, altere a palavra-passe e entre em contacto com o serviço de suporte técnico {2}Respeitosamente,{3}",
  ChangingEmail_SendToOldEmailTitle:
    "Notificação sobre a mudança do endereço de e-mail",
  ChangingPassword:
    "Caro utilizador!{0}A sua palavra-passe foi alterada. Se você não fez isso, altere a palavra-passe e entre em contacto com o serviço de suporte técnico{1}Respeitosamente, {2}",
  ChangingPasswordForAdmin:
    "Caro utilizador!{0}A sua palavra-passe foi alterada pelo administrador. A sua palavra-passe é {1} Respeitosamente,{2}",
  ChangingPasswordTitle: "Notificação sobre a mudança da palavra-passe",
  CheckEmailRecoverPassword:
    "Verifique o seu e-mail para recuperar a palavra-passe.",
  ChooseCertificate: "Selecione um certificado",
  ChooseFile: "Escolher um ficheiro",
  ChooseStrongPassword:
    "Crie uma palavras-passe forte e não a use para outras contas.",
  CommonSettings: "Configurações gerais",
  CommonSignIn: "Autorização ordinária",
  CommonSignUp: "Registo ordinário",
  Company: "Empresa",
  Confirm: "Confirmar",
  ConfirmActivationTitle: "Você já está registado. Tenha acesso num clique",
  ConfirmLicenseCreated_Mail:
    "Caro utilizador!{0}Comprou com sucesso uma assinatura do produto {1}Respeitosamente,{2}",
  ConfirmLicenseCreated_MailTitle: "Comprar a subscrição",
  ConfirmLicenseUpdated_Mail:
    "Caro utilizador!{0}Renovou com sucesso a sua assinatura do produto {1}Respeitosamente,{2}",
  ConfirmLicenseUpdated_MailTitle: "Renovação de assinatura",
  ConfirmRegister: "Corfirmação de registo",
  ConfirmRegister_Mail:
    "Caro utilizador!{0}Foi registado com sucesso no serviço MySeldon. Para alterar a palavra-passe siga o {1}link{2}!{3}Respeitosamente,{4}",
  Consult: "Consulta",
  ConsultText:
    "Se tiver alguma pergunta ou não tinha tempo {0} para usar o acesso de demonstração, {0} clique no botão “Consulta”.",
  ControlMailDescription:
    "Sistema de prevenção de violações, análise e monitoramento de atividades de compras.",
  ControlRegistrationTitle:
    "Controle sua atividade de compras. Analise despesas e otimize a participação em licitações segundo 44-FZ e 223-FZ..",
  ControlTariffDescription1:
    "A análise rápida e detalhada das atividades de compras de organizações poupará tempo, recursos e ajudará a tomar uma decisão consciente.",
  ControlTariffDescription2:
    "O sistema minimizará seus riscos e realizará medidas preventivas para evitar potenciais violações e multas nos procedimentos no âmbito das Leis Federais 44-FZ e 223-FZ.",
  ControlTariffTitle1: "Analisar e monitorar as atividades de leilão público",
  CreatePassword: "Criação de palavra-passe",
  CurrentPassword: "Antiga palavra-passe",
  CurrentSubsriptions: "Subscrições atuais",
  DataProtected: "Os dados são protegidos pelo padrão internacional PCI DSS",
  Day: "{0} dia",
  DearUser: "Caro utilizador!",
  Demo: "Acesso de demonstração",
  DemoAccess: "Acesso de demonstração",
  DemoAccessDateActivation: "Acesso de demonstração foi ativado de {0} à {1}",
  DemoAccessDay: "Acesso de demonstração por {0} dia",
  DemoAccessFewDays: "Acesso de demonstração por {0} dias",
  DemoAccessManyDays: "Acesso de demonstração por {0} dias",
  Department: "Subdivisão",
  DifferenceInExchangeRates:
    "No caso de pagamento em moeda estrangeira, o montante retirado da sua conta pode diferir do montante indicado desta tarifa devido à diferença de taxa de câmbio durante a realização das operações de câmbio bancárias no momento do pagamento.",
  DigitalSignatureActivated: "A assinatura eletrónica está ativada",
  DigitalSignatureAlreadyBinded:
    "Esta assinatura eletrónica já está ligada à sua conta",
  DigitalSignatureAndCertificates: "Assinatura eletrónica e certificados",
  DigitalSignatureBinded: "A assinatura eletrónica foi ligada com sucesso",
  DigitalSignatureBindedToAnotherAccount:
    "Esta assinatura eletrónica não pode ser ligada à sua conta porque já é usada por outro utilizador",
  DigitalSignatureExpired:
    "O prazo de validade do certificado expirou, utilize outra chave da assinatura eletrónica",
  DigitalSignatureIsNotConfidential:
    "A assinatura eletrónica foi dada ao Centro de certificação que não está incluído à zona de confiança",
  DigitalSignatureSettings: "Configurações da assinatura eletrónica",
  DigitalSignatureWithdrawn:
    "A assinatura eletrónica está no Registo de certificados revogados, utilize outra chave da assinatura eletrónica",
  Edit: "Editar",
  Email: "E-mail",
  EmailAlreadyExist:
    "Não pode alterar o valor do endereço de e-mail para especificado porque tal valor já existe no sistema",
  EmailOrPhone: "E-mail ou número de telefone",
  EmailSignature: "Equipa {0}",
  EndLicense: "até o dia",
  EnterCode: "Introduza o código de SMS",
  EnterCodeConfirmPhone:
    "Introduza o código da mensagem para confirmar o novo número de telefone",
  EnterEmailOrPhoneNumber: "Introduza o seu e-mail ou número de telefone",
  EnterMailOrPhone: "Introduza o seu e-mail ou número de telefone",
  EnterYourEmail:
    "Introduza o seu e-mail – enviaremos um link de entrada e outras instruções.",
  ExceededRegisterPromo:
    "O número de inscrições para este código promocional foi excedido",
  ExtendedTestAccess: "O acesso experimental foi prolongado",
  ExtendedTestAccessText:
    "O acesso experimental ao sistema mySeldon foi prolongado.",
  FewDays: "{0} dias",
  Finances: "Finanças",
  FirstName: "Nome",
  ForgotPassword: "Esqueceu a palavra-passe?",
  ForgotPasswordError:
    "A conta pessoal com o endereço de e-mail ou número de telefone. que você especificou, não existe. Por favor, {0}inscreva-se{1}",
  FreeAccessDayProduct: "Acesso gratuito por {0} dia {1}ao {2}",
  FreeAccessFewDays: "Acesso gratuito por {0} dias",
  FreeAccessFewDaysProduct: "Acesso gratuito por {0} dias {1}ao {2}",
  FreeAccessManyDays: "Acesso gratuito por {0} dias",
  FreeAccessManyDaysProduct: "Acesso gratuito por {0} dias {1}ao {2}",
  FreeAccessOneDay: "Acesso gratuito por {0} dia",
  FreeAccessProduct: "Acesso ao sistema {0}",
  FreeAccessProductActivate: "Acesso gratuito ao {0} foi ativado",
  FutureLicense: "Assinatura adiada",
  FutureSubscribeOne: "Tem {0} futura assinatura",
  FutureSubscribesFew: "Tem {0} futuras assinaturas",
  FutureSubscribesMany: "Tem {0} futuras assinaturas",
  Gender: "Sexo",
  GenderFemale: "Feminino",
  GenderMale: "Masculino",
  GetAccessToInfo:
    "Obtenha acesso às informações de empresas, leilões, eventos. Observe o seu ambiente.",
  GetDemoAccess: "Obter um acesso à demonstração",
  GetProfit: "Obter lucros",
  GetProfitDescription:
    "Ganhe nas licitações, sabendo antecipadamente sobre suas chances. O serviço avaliará sua experiência de participação nas licitações, oportunidades financeiras, processos judiciais e outros 25 parâmetros, analisará e recomendará as melhores compras.",
  GoToSystem: "Ir para o sistema",
  HavePromo: "Você tem um código de promoção?",
  InactivePromo: "Código promocional inativo",
  IncorrectEmailEntered: "introduziu um e-mail incorreto",
  IncorrectPassword: "Palavra-passe incorreta",
  IncorrectPasswordEntered: "introduziu uma palavra-passe incorreta",
  IncorrectPhoneEntered: "introduziu um número de telefone incorreto",
  InvalidPromo: "Código promocional incorreto",
  IsSendSms: "Não recebeu SMS?",
  LastName: "Sobrenome",
  LicenseAgreement: "Acordo de licença",
  LicenseCountFew: "{0} assinaturas",
  LicenseCountMany: "{0} assinaturas",
  LicenseCountOne: "{0} assinatura",
  LinkHasExpired:
    "O link de e-mail é obsoleto. Solicite novamente a recuperação da palavra-passe.",
  Login: "Nome de utilizador",
  LoginPageHeader: "Entrar",
  LoginToAccess: "Nome de utilizador para acessar o sistema:",
  LogOff: "Sair",
  ManageRisks: "Controlar os riscos",
  ManageRisksDescription:
    "Verifique clientes e concorrentes, identifique as aquisições que são adaptadas para um fornecedor específico. Com o Seldon.Pro você saberá com quem é seguro trabalhar.",
  ManyDays: "{0} dias",
  MinPasswordLength: "O comprimento mínimo da palavra-passe é {0} caracteres.",
  NewPassword: "Nova palavra-passe",
  NewsMailDescription:
    "Agregador de notícias das mídias russas e estrangeiras.",
  NewsRegistrationTitle:
    "Notícias atuais de todo o mundo, dossiê sobre empresas, regiões, pessoas da mídia.",
  NoActiveLicense: "Não há assinaturas ativas",
  NotFound: "Nada foi encontrado",
  NoTransactions: "Até agora, não tem transações.",
  NotRobot: "Por favor, confirme que você não é um robô",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN ou NIF",
  OnDay: "Por {0} dia",
  OnFewDays: "Por {0} dias",
  OnlinePayment: "Pagamento em linha",
  OnManyDays: "Por {0} dias",
  OpenAccess: "Acesso livre",
  OrderDigitalSignature: "Solicitar a assinatura eletrónica",
  OrderDigitalSignatureSettings:
    "Solicitar a configuração da assinatura eletrónica",
  Password: "Palavra-passe",
  PasswordChanged: "A palavra-passe foi alterada",
  PasswordHasAPartOfLoginName:
    "a palavra-passe não deve conter o nome da conta ou uma parte do nome completo de utilizador e o comprimento da palavra-passe deve ser mais de dois caracteres consecutivos",
  PasswordMail: "{0} — sua palavra-passe para {1}.",
  PasswordMustBeAtLeast8Symbols:
    "A palavra-passe deve conter pelo menos 8 caracteres",
  PasswordMustContainsLettersAndDigits:
    "A palavra-passe deve conter letras maiúsculas latinas (de A a Z) e dígitos (de 0 a 9) ou letras minúsculas latinas (de a a z) e dígitos (de 0 a 9) ou letras maiúsculas latinas (de A para Z) e letras minúsculas latinas (de a a z)",
  PasswordNotUpToDate:
    "A palavra-passe não está atualizada, por favor registe-se novamente",
  PasswordRequirementsError:
    "A palavra-passe não responde aos requisitos de segurança:",
  PasswordsMismatch:
    'Os valores dos campos "Nova palavra-passe" e "Repita" não correspondem, tente novamente.',
  PaymentButton: "Passar ao pagamento",
  PaymentMethod: "Modo de pagamento",
  PaymentText_Mail:
    "O seu pagamento pelo serviço {0} foi concluído com sucesso.",
  PersonalInfo: "Informação pessoal",
  Phone: "Número de telefone",
  PhoneNumberAlreadyExist:
    "Não pode alterar o valor do número de telefone para especificado porque tal valor já existe no sistema",
  PhoneOrEmailBusy:
    "Um utilizador com este número de telefone ou endereço de e-mail já existe",
  PlatformsWithContracts:
    "Plataformas com as quais os acordos estão concluídos",
  PleaseFillRequirementFields:
    "Todos os campos obrigatórios não são preenchidos. Preencha os campos {0}",
  Position: "Cargo",
  PriceMailDescription:
    "Serviço de seleção de contratos para argumentar do preço inicial máximo total.",
  PriceRegistrationTitle:
    "Prepare a argumentação do preço inicial máximo total do contrato rapidamente e facilmente. Analise o mercado de negociação eletrônica, descubra os preços e a atividade dos concorrentes.",
  PriceTariffDescription1:
    "Selecione contratos para argumentar o preço inicial em poucos segundos. Faça o download da argumentação completa com um clique e inclua-o à documentação de compra. Certifique-se de que sua proposta de preço esteja em conformidade com a lei.",
  PriceTariffDescription2:
    "Analise os preços dos produtos e serviços para tornar seu negócio mais concorrente. Estude os contratos executados dos concorrentes, verifique a dinâmica dos preços, observe a sazonalidade da procura e oferta.",
  PriceTariffDescription3:
    "Use os coeficientes para argumentar qualquer preço e atrair fornecedores. Pode especificar um coeficiente multiplicador e deflator, levar em conta outras condições de compra. Graças ao Seldon.Price, você sempre terá pedidos de suas compras.",
  PriceTariffTitle1: "Argumentar o preço inicial máximo total do contrato",
  PriceTariffTitle2: "Analisar o mercado",
  PriceTariffTitle3: "Atrair novos fornecedores",
  PrivacyPolicy: "Política de privacidade",
  PrivacyPolicyAndOffer:
    "Clicando no botão “Passar ao pagamento”, confirma que leu e concordou com os termos da {0}Política de privacidade{1} e {2}Oferta{3}.",
  PrivatePolicyRegister:
    "Especificando os seus dados pessoais no formulário, concorda com o seu processamento de acordo com a {0}Política de privacidade{1}",
  ProductCountFew: "para {0} produtos",
  ProductCountMany: "para {0} produtos",
  ProductCountOne: "para {0} produto",
  Prolong: "Prolongar",
  ProMailDescription:
    "serviço com inteligência artificial para trabalhar com propostas.",
  PromoAnotherProduct:
    "Um código promocional foi especificado para outro produto",
  Promocode: "Código promocional",
  PromoExpired: "O prazo de validade do código promocional expirou",
  PublicOffer:
    "De acordo com o parágrafo 2 do artigo 437 do Código Civil da Federação da Rússia, a Oferta pública (daqui em diante designada como “Oferta”) é uma proposta oficial de concluir o Contrato com qualquer pessoa física, entidade jurídica, empresário em nome individual (daqui em diante designado como “Utilizador”) nas condições previstas pela Oferta. O Contrato é concluído através do consentimento em relação à Oferta. O consentimento em relação à Oferta é o pagamento da remuneração de licença pelo Utilizador. O Contrato é considerado como concluído por escrito e é equivalente ao Contrato assinado pelas duas Partes Contratantes.",
  PurchasingRegions: "Regiões da sua atividade de compras",
  RecoveryPassword: "Recuperação da palavra-passe",
  RecoveryPassword_Mail:
    "Caro utilizador!{0}Para alterar a sua palavra-passe, siga o {1}link{2}!{3}Respeitosamente,",
  RecoveryPassword2_Mail:
    "Usou o formulário de recuperação da palavra-passe, para recuperar sua palavra-passe, passe para o {0}link{1}. Se você não fez isso, altere a palavra-passe e entre em contacto com o serviço de suporte técnico.",
  Refill: "Recarregar",
  Register: "Registo",
  RegisterToStart: "Registe-se para começar a trabalhar",
  RegistrationСompleted: "O registo está concluído",
  RememberMe: "Lembrar-se de mim",
  RemovePromo: "Remover código promocional",
  RenewSubscription: "Prolongar a assinatura",
  RepeatNewPassword: "Confirme a sua nova palavra-passe",
  RequiredField: "campo obrigatório",
  Resend: "Enviar novamente",
  Restore: "Recuperar",
  Save: "Guardar",
  SavePasswordSuccess: "A palavra-passe foi guardada com sucesso",
  SavePolicy:
    "Clicando no botão “Guardar”, confirma que leu e concordou com os termos da {0}Política de privacidade{1}.",
  SaveUnavailable: "Não é possível guardar:",
  Search: "Pesquisa",
  SearchInBasis: "Encontre informações sobre si mesmo no Seldon.Basis",
  Security: "Segurança",
  SelectedRegions: "Regiões selecionadas",
  SelectRegion: "Escolha uma região",
  Send: "Enviar",
  SendAgain: "Enviar novamente",
  SendCode: "O código foi enviado",
  SendPasswordOrCreateNew:
    "A sua palavra-passe foi enviada como um código no seu número de telefone. Clique no botão “Alterar a sua palavra-passe” para definir sua própria palavra-passe.",
  SendSmsWithPassword:
    "Enviaremos um SMS com uma palavra-passe neste número de telefone para entrada no sistema",
  SendToNumber: "Enviamos o código para o seu número de telefone",
  Settings: "Configurações",
  Show: "Mostrar",
  ShowVideo: "Ver o vídeo",
  SignIn: "Entrar",
  SignInSystem: "Entre ao sistema",
  SignUpWithDS: "Registo através da assinatura eletrónica",
  SincerelyTeam: "Respeitosamente, {0} equipa do Seldon",
  SingleLoginSeldon: "Acesso único a todos os serviços do Seldon",
  SliderBasisInfo:
    "Utilize o serviço para uma avaliação abrangente de parceiros e concorrentes. Descubra se executem conscienciosamente suas obrigações: financeiras, contratuais, jurídicas.",
  SliderNewsInfo:
    "Esteja ciente dos eventos da indústria, na Rússia e em todo o mundo. Ajuste o seu próprio feed de notícias e leia apenas as novidades que lhe interessa.",
  SliderPriceInfo:
    "Escolha contratos para argumentar o preço inicial máximo. Analise os preços dos produtos e serviços para tornar seu negócio mais competitivo.",
  SliderProInfo:
    "O serviço de inteligência artificial ajudará a ganhar um leilão. Dá umas recomendações pessoais para todos — a respeito de perfil de atividades e oportunidades, analisa clientes e concorrentes, avalia as probabilidades de ganhar.",
  Snap: "Ligar",
  SnapDigitalSignature: "Ligar a assinatura eletrónica",
  StartLicense: "a partir de",
  StartUsing: "Começar a usar",
  Subscribe: "Subscrever",
  SuccessRegister_Mail: "Foi registado com sucesso no sistema {0}.",
  SupportMailLink:
    'Se você tiver alguma dúvida, clique em {0} "Saiba mais" {1} e o especialista vai ajudá-lo.',
  SystemLanguage: "Idioma do sistema",
  Text403:
    "Não pode ver esta página. Entre em contacto com nossa equipa de suporte se você achar que ocorreu um erro.",
  Text404:
    "O link parece ser inválido. {0} Por favor, vá para {1}Página inicial{2} e tente novamente",
  Text500:
    "Desculpe, mas o serviço ainda não está disponível. Por favor, tente novamente ou vá para {0}Página inicial{1}.",
  TillDate: "até {0}",
  TimeLeft: "{0} segundos restantes",
  Title403: "Acesso negado",
  Title404: "Página não encontrada",
  Title500: "Ocorreu um erro",
  ToSaveTime: "Poupar tempo",
  ToSaveTimeDescription:
    "Todas as informações estão num só lugar e são atualizadas em tempo real. Não precisa procurar novas compras, verificar a transparência dos procedimentos, analisar os clientes. As propostas que o atenderão estão agora em mãos.",
  Total: "Total:{0}",
  Transactions: "Transações",
  TryOtherProducts: "Experimente nossos outros produtos",
  UploadNewPhoto: "Carregar uma nova foto",
  UserNotFound: "Utilizador não encontrado",
  WeNotFind: "Não encontramos nada, tente {0}procurar em {1}",
  WeSentCodeConfirm:
    "Enviámos o código de segurança para o seu número de telefone, confirme o recebimento:",
  WrongOldPassword: "Palavra-passe incorreta.",
  WrongPassword: "Entrou uma palavra-passe atual incorreta, tente novamente",
  YouCanFindInfoInBasis:
    "Sempre pode encontrar {0}informações no Seldon Basis{1}",
  YourLogin: "Nome de utilizador"
};
export default dictionary;
