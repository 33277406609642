// Library
import React, { useState, useEffect } from "react";
import ModalBootstrap from "react-bootstrap/Modal";

// Image
import closeIcon from "@images/landing/close.svg";

const Modal = ({
  show = false,
  onHide = () => {},
  title = "",
  children,
  botton = [],
  size = "",
  headerShow = true,
  footerShow = true,
  classBody,
  ...props
}) => {
  return (
    <ModalBootstrap show={show} size={size} onHide={onHide} {...props}>
      <ModalBootstrap.Body className={classBody}>
        <ModalBootstrap.Title>{title}</ModalBootstrap.Title>
        <div
          className="cursor-pointer position-absolute"
          style={{ right: "17.51px", top: "17.51px" }}
          onClick={onHide}
        >
          <img src={closeIcon} alt="" />
        </div>
        {children}
      </ModalBootstrap.Body>
    </ModalBootstrap>
  );
};

export default Modal;
