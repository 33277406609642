import * as Yup from "yup";
import format from "extensions/stringExt";

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^\d+$/; //RSPP-529

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Необходимо заполнить поле "Имя"')
    .min(2, format("Имя не может быть короче {0}-х символов", 2)),
  email: Yup.string()
    .required('Необходимо заполнить поле "Почта"')
    .matches(
      /([A-Za-z0-9_.-]+)@([a-z0-9.-]+\.[a-z]+)/,
      "Поле Почта заполнено некорректно"
    ),
  phone: Yup.string()
    .required('Необходимо заполнить поле "Телефон"')
    .matches(phoneRegExp, "Должны быть только цифры")
    //.min(6, "Слишком короткий номер телефона")
    //.max(10, "Слишком длинный номер телефона")
    .max(15, "Слишком длинный номер телефона")
});

export default ValidationSchema;
