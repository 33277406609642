import * as types from "./action-types";

const initialState = {
  lang: "ru",
  loading: false,
  successModalShow: { show: false, title: "Успешно!", text: "" },
  errorModalShow: { show: false, title: "Операция не прошла", text: "" }
};

const globalReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_LANG:
      return { ...state, lang: action.lang };
    case types.LOADING_SPINER:
      return { ...state, loading: action.loading };
    case types.SUCCESS_MODAL_PROCESS:
      return {
        ...state,
        successModalShow: {
          ...state.successModalShow,
          ...action.successModalShow
        }
      };
    case types.ERROR_MODAL_PROCESS:
      return {
        ...state,
        errorModalShow: { ...state.errorModalShow, ...action.errorModalShow }
      };
    default:
      return state;
  }
};

export default globalReducer;
