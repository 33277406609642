// Landing
import React from "react";
import styled from "styled-components";

// Image
import bg from "@images/landing/bg-info-block.jpg";
import bgPicture from "@images/landing/bg-picture-info-block.png";
import bgPictureFull from "@images/landing/bg-picture-info-block-full.png";
import infoIcon1 from "@images/landing/info-icon-1.svg";
import infoIcon2 from "@images/landing/info-icon-2.svg";
import infoIcon3 from "@images/landing/info-icon-3.svg";
import logo from "@images/landing/logo.svg";

// UI
import ImgWrap from "@ui/ImgWrap";

const Wrapper = styled.div`
  background: url(${bg});
  background-size: cover;
`;

const LandingInfoBlock = ({ toTestAccess }) => (
  <Wrapper className="py-5 overflow-hidden">
    <div className="container">
      <ImgWrap
        className="d-sm-none"
        src={logo}
        alt=""
        styleImg={{ width: 239 }}
      />
      <div className="row mt-3 mt-sm-0">
        <div className="col-sm-6 d-flex flex-column justify-content-center">
          <div className="text-secondary mb-2">
            <span>Малым предпринимателям</span>
            <span className="mx-1">|</span>
            <span>Среднему и крупному бизнесу</span>
          </div>
          <h2 className="text-black">
            <b>
              Торговая площадка <br className="d-none d-md-block" />
              для бизнеса
            </b>
          </h2>
          <p className="mt-2">
            Единая среда для бизнеса всех уровней — от индивидуальных
            предпринимателей до ведущих холдингов, позволяющая проводитьбыстрые
            и безопасные сделки без посредников.
          </p>

          <ImgWrap classImg="img-fluid d-sm-none mt-3" src={bgPictureFull} />
          <div>
            <div
              className="mt-4 d-flex d-sm-inline-block justify-content-center btn btn-lg btn-warning text-white"
              onClick={() => toTestAccess()}
            >
              Попробовать бесплатно
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-sm-4 d-flex flex-row flex-sm-column">
              <ImgWrap
                className="d-flex align-items-center mr-2 mr-sm-0"
                src={infoIcon1}
              />
              <div>
                <h4 className="text-dark">
                  <b>Быстрые сделки</b>
                </h4>
                <p>В один клик, безопасно и без посредников</p>
              </div>
            </div>
            <div className="col-sm-4 d-flex flex-row flex-sm-column mt-3 mt-sm-0">
              <ImgWrap
                className="d-flex align-items-center mr-2 mr-sm-0"
                src={infoIcon2}
              />
              <div>
                <h4 className="text-dark">
                  <b>Аналитика рынка</b>
                </h4>
                <p>Выстраивайте стратегию продаж по регионам и отраслям</p>
              </div>
            </div>
            <div className="col-sm-4 d-flex flex-row flex-sm-column mt-3 mt-sm-0">
              <ImgWrap
                className="d-flex align-items-center mr-2 mr-sm-0"
                src={infoIcon3}
              />
              <div>
                <h4 className="text-dark">
                  <b>Защищенные чаты</b>
                </h4>
                <p>
                  Безопасное общение внутри сервиса в защищенных чатах и
                  конференциях
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 position-relative d-none d-sm-block">
          <ImgWrap className="position-absoolute l-0 t-0" src={bgPicture} />
        </div>
      </div>
    </div>
  </Wrapper>
);

export default LandingInfoBlock;
