const dictionary = {
  " ActiveLicenses": "Gültige Abonnements",
  " Apply": "Anwenden",
  " GetAccess": "Zugang bekommen",
  " IncorrectPassword": "Falscher Zustimmung-Code",
  " LoginCredentials": "Anmeldedaten:",
  " MinLengthFew": "Das Feld {0} muss mindestens {1} Zeichen enthalten",
  AccessDateActivation: "Der Zugang wurde vom {0} bis zum {1} aktiviert",
  AccessDay: "Vollzugang zu {0} für einen Tag",
  AccessHalfYear: "Vollzugang zu {0} für 6 Monate",
  AccessMonth: "Vollzugang zu {0} für einen Monat",
  AccessThreeMonth: "Vollzugang zu {0} für 3 Monate",
  AccessWeek: "Vollzugang zu {0} für eine Woche",
  AccessYear: "Vollzugang zu {0} für ein Jahr",
  AccountAlreadyContainsThisVIPCode: "Das Konto enthält schon diesen VIP-Code",
  AccountWithTheSameDSAlreadyExist:
    "Es gibt schon ein Konto mit dieser elektronischen Signatur",
  AccountWithTheSameEmailAlreadyExist:
    "ein Konto mit dieser E-Mail-Adresse existiert bereits",
  Activate: "Aktivieren",
  ActivationConfirmation: "Aktivierungsbestätigung",
  ActivationResult: "Ergebnis der Aktivierung",
  ActivationTextBasis1:
    'Untersuchen Sie die Verbindungen zwischen Unternehmen und prüfen Sie ihre Affiliation anhand eines interaktiven Schemas, das als "Verbindungsbaum" bezeichnet wurde.',
  ActivationTextBasis2:
    "Sehen Sie sich Registrierungsdaten von Unternehmen und ihren Leitern an, prüfen Sie die Adressen und die Telefonnummer auf Massenregistrierung.",
  ActivationTextBasis3:
    "Wir berichten über die von den Unternehmen und Unternehmern geschlossenen Staatsvertäge. Wir verfügen über Informationen darüber, wie gewissenhaft sie ihre vertraglichen Verpflichtungen erfüllen.",
  ActivationTextBasis4:
    "Wir veröffentlichen Buchhalterbilanz und berechnen Finanzkennzahlen von Unternehmen: Liquidität, Zahlungsfähigkeit, Finanzstabilität, Profitabilität, Reinvermögen.",
  ActivationTextControl1:
    "Wissen Sie Bescheid über Transparenz und Gewissenhaftigkeit des Vergabeverfahrens gemäß Artikeln 44-FZ und 223-FZ.",
  ActivationTextControl2:
    "Eine umfassende Bewertung der Beschaffungsaktivitäten des Unternehmens. Budgetauslastung und Beschaffungseffizienz, Risikoindikatoren.",
  ActivationTextControl3:
    "Erfahren Sie über aktuelle Aspekte der Beschaffungsaktivität: durchführung von planmässigen Ausschreibungen, Verzug eines Artikels, Störnierung eines Vertragsabschlusses usw.",
  ActivationTextNews1:
    "Aktuellste Nachrichten aus der Branche in Russland und weltweit. Erfahren Sie über die wichtigsten Ereignisse als Erster.",
  ActivationTextNews2:
    "Stellen Sie Ihren eigenen Feed ein und erhalten Sie wichtige Nachrichten sowie aktuelle Informationen zu Partnern und Mitbewerbern.",
  ActivationTextNews3:
    "Erkennen Sie das Verborgene: Tonalität der Berichterstattung in Medien, ausführliche Profile von Unternehmen und Personen.  Bekommen Sie fortgeschrittene Analytik 24/7.",
  ActivationTextPrice1:
    "Berücksichtigen Sie die Preisspanne und bestimmen Sie den wettbewerbsfähigen Preis, sodass Ihre Einkäufe immer Angebote enthalten.",
  ActivationTextPrice2:
    "Verwenden Sie Koeffizienten, um einen Vertragspreis unter Berücksichtigung der unterschiedlichen Einkaufsbedingungen zu begründen.",
  ActivationTextPrice3:
    "Ihr Preis wird, gestützt durch eine Begründung, den gesetzlichen Anforderungen entsprechen und seinen Lieferanten schnell finden.",
  ActivationTextPro1:
    "Verfolgen Sie den Preis und die Nachfrage nach Waren und Dienstleistungen nach, informieren Sie sich über neue Ausschreibungen.",
  ActivationTextPro2:
    "Nehmen Sie nur an den Versteigerungen teil, wo es reale Gewinnchancen gibt. Aufgrund der Ausschreibungsgeschichte, Bewertung potenzieller Teilnehmer und anderer Faktoren werden persönliche Prognose erarbeitet.",
  ActivationTextPro3:
    "Künstliche Intelligenz bewertet Finanzlage des Auftraggebers, Ausschreibungsgeschichten, Schiedsgerichtsfälle, damit Sie ausführliche Informantinnen über Ihre Partner bekommen.",
  ActivationTextPro4:
    "Das System sucht aufgrund der Unternehmensdaten spezialisierte Angebote aus, damit Sie keine Zeit mit einer selbständigen Suche verschwenden.",
  ActivationTitleBasis1: "Verbindungen und Affiliation",
  ActivationTitleBasis2: "Auszug aus dem EGRUL",
  ActivationTitleBasis3: "Verträge und unzuverlässige Lieferanten",
  ActivationTitleBasis4: "Bilanzen und Schnellbewertung",
  ActivationTitleControl1: "Überwachung von Verstößen",
  ActivationTitleControl2: "Analyse von Beschaffungsaktivitäten",
  ActivationTitleControl3: "Überwachung von Aktivitäten",
  ActivationTitleNews1: "Nachrichten aus aller Welt",
  ActivationTitleNews2: "Personalisierung",
  ActivationTitleNews3: "Leistungsstarke Analytik und künstliche Intelligenz",
  ActivationTitlePrice1: "Preisspanne",
  ActivationTitlePrice2: "Preiskorrektur",
  ActivationTitlePrice3: "Preisbestätigung",
  ActivationTitlePro1: "Analyse von Ausschreibungen",
  ActivationTitlePro2: "Prognose von Gewinnchancen",
  ActivationTitlePro3: "Due-Diligence-Prüfung",
  ActivationTitlePro4: "Empfehlungen",
  ActiveFrom: "gültig seit",
  ActiveLicense: "Gültiges Abonnement",
  ActiveLicenseCountFew: "{0} gültige Abonnements",
  ActiveLicenseCountMany: "{0} gültige Abonnements",
  ActiveLicenseCountOne: "{0} gültiges Abonnement",
  ActualTill: "Gültig bis",
  AddRemoveRegion: "Region hinzufügen/löschen",
  AdminPasswordReset:
    "Lieber Nutzer,{0}Ihr Passwort wurde vom Webseitenbetreiber zurückgesetzt. Um ein neues Passwort zu erstellen, folgen Sie dem {1} Link {2} !{3} Mit freundlichen Grüßen,{4}",
  AfterPayment: "Nach der Bezahlung können Sie:",
  AlreadyRegistered: "Schon registriert?",
  AtLeastThanEightSymbols: "Mindestens 8 Zeichen lang.",
  AuthorizeWithDS: "Anmeldung durch elektronische Signatur",
  Balance: "Guthaben",
  BasisMailDescription:
    "Service zur Analyse und Bewertung von russischen und ausländischen Unternehmen",
  BasisRegistrationTitle:
    "Sie können Ihre Partner prüfen, Kunden finden, ihre Stärken und Schwächen kennenlernen.",
  BasisTariffDescription1:
    "24 Mio. russische Unternehmen und Einzelunternehmer bei der Hand. Ihre Möglichkeiten und Bedürfnisse, Stärke und Schwäche. Schnelle Suche nach Name, Bankangaben, Kontakten.",
  BasisTariffDescription2:
    "Sehen Sie das Unternehmensdossier durch. Erfahren Sie, wie gewissenhaft Ihr potentieller Handelspartner die übernommenen Verpflichtungen (finanzielle, vertragliche, juristische) erfüllt.",
  BasisTariffDescription3:
    "Erhalten Sie Benachrichtigungen über die Ereignisse von Unternehmen. Seldon.Basis weist auf die Fehler Ihres Partners oder Ihrer Konkurrenz hin. Benutzen Sie statistische und analytische Angaben, um sich ein Bild vom Unternehmen zu machen.",
  BasisTariffDescription4:
    "Lernen Sie die Tätigkeit von Unternehmen aus GUS-Ländern kennen. Überprüfen Sie, wie gewissenhaft die Unternehmen bei der Teilnahme an Ausschreibungen sind. Analysieren Sie die Finanzlage der Unternehmen. Erweitern Sie Ihre Geschäfte, indem Sie mit ausländischen Partnern zusammenarbeiten.",
  BasisTariffTitle1: "Neue Kunden finden",
  BasisTariffTitle2: "Risiken minimieren",
  BasisTariffTitle3: "Auf dem Laufenden sein",
  BasisTariffTitle4: "Partner aus der GUS suchen",
  Bonuses: "Bonus",
  BuySubscription: "Abonnement kaufen",
  CanSendIn: "Kann über {0} gesendet werden",
  ChangeData: "Persönliche Daten bearbeiten",
  ChangePassword: "Passwort ändern",
  ChangesSaved: "Änderungen wurden gespeichert",
  ChangingEmail_SendToNewEmail:
    "Lieber Benutzer,{0} Um die Änderung der E-Mail-Adresse zu bestätigen, klicken Sie auf den {1} Link {2} !{3} Mit freundlichen Grüßen,{4}",
  ChangingEmail_SendToNewEmailTitle:
    "Bestätigung zur Änderung der E-Mail-Adresse",
  ChangingEmail_SendToOldEmail:
    "Lieber Benutzer,{0}Sie haben die Änderung der E-Mail-Adresse vorgenommen, Ihre neue E-Mail-Adresse: {1} Haben Sie nicht selbst die Änderung vorgenommen, ändern Sie Ihr Passwort und setzen Sie sich mit Technischem Support in Verbindung {2}Mit freundlichen Grüßen,{3}",
  ChangingEmail_SendToOldEmailTitle:
    "Benachrichtigung zur Änderung der E-Mail-Adresse",
  ChangingPassword:
    "Lieber Benutzer,{0}Ihr Passwort wurde geändert. Haben Sie nicht selbst die Änderung vorgenommen, ändern Sie Ihr Passwort und setzen Sie sich mit Technischem Support in Verbindung {1}Mit freundlichen Grüßen, {2}",
  ChangingPasswordForAdmin:
    "Lieber Nutzer,{0}Ihr Passwort wurde vom Webseitenbetreiber geändert. Ihr neues Passwort ist {1} Mit freundlichen Grüßen,{2}",
  ChangingPasswordTitle: "Benachrichtigung über Passwort-Änderung",
  CheckEmailRecoverPassword:
    "Überprüfen Sie bitte Ihre E-Mail, um das Passwort wiederherzustellen.",
  ChooseCertificate: "Wählen Sie Zertifikat aus",
  ChooseFile: "Datei wählen",
  ChooseStrongPassword:
    "Erstellen Sie ein sicheres Passwort und benutzen Sie es nirgendwo mehr.",
  CommonSettings: "Allgemeine Einstellungen",
  CommonSignIn: "Normale Autorisierung",
  CommonSignUp: "Normale Registrierung",
  Company: "Unternehmen",
  Confirm: "Bestätigen",
  ConfirmActivationTitle:
    "Sie sind registriert worden. Erhalten Sie den Zugang in einem Klick",
  ConfirmLicenseCreated_Mail:
    "Lieber Nutzer,{0}Sie haben Ihr Abonnement erfolgreich erworben {1}Mit freundlichen Grüßen,{2}",
  ConfirmLicenseCreated_MailTitle: "Abo erwerben",
  ConfirmLicenseUpdated_Mail:
    "Lieber Nutzer,{0}Ihr Abonnement wurde erfolgreich verlängert {1}Mit freundlichen Grüßen,{2}",
  ConfirmLicenseUpdated_MailTitle: "Abonnement-Verlängerung",
  ConfirmRegister: "Registrierungsbestätigung",
  ConfirmRegister_Mail:
    "Lieber Nutzer,{0}Sie haben sich bei MySeldon erfolgreich registriert. Um das Passwort zu ändern, klicken Sie auf den {1}Link{2}.{3} Mit freundlichen Grüßen,{4}",
  Consult: "Beratung",
  ConsultText:
    'Haben Sie Fragen oder kamen Sie nicht dazu, {0} den Testzugang zu nutzen, {0} dann klicken Sie auf "Beratung"',
  ControlMailDescription:
    "Vorbeugungssystem gegen Verstöße für sichere Analyse und Monitoring von Beschaffungsaktivitäten.",
  ControlRegistrationTitle:
    "Kontrollieren Sie Ihre Beschaffungsaktivitäten. Analysieren Sie die Verfahrenskosten und optimieren Sie Ihre Präsenz auf den Vergabeplattformen gemäß 44-FZ und 223-FZ.",
  ControlTariffDescription1:
    "Schnelle und eingehende Analyse der Ausschreibungstätigkeit von Unternehmen: spart Zeit, Einsatzmittel und hilft ausgewogene Entscheidungen zu treffen.",
  ControlTariffDescription2:
    "Das System ermöglicht es, Risiken zu minimieren und Präventionsmassnahmen zu treffen: potenzielle Verbrechen und Strafen bei Verfahren im Rahmen der Gesetze 44-FZ und 223-FZ zu vermeiden.",
  ControlTariffTitle1: "Ausschreibungsaktivitäten analysieren und beobachten",
  CreatePassword: "Passworterstellung",
  CurrentPassword: "Altes Passwort",
  CurrentSubsriptions: "Aktuelle Abonnements",
  DataProtected:
    "Daten werden nach dem internationalen Standard PCI DSS geschützt",
  Day: "1 Tag",
  DearUser: "Lieber Nutzer,",
  Demo: "Testzugang",
  DemoAccess: "Testzugang",
  DemoAccessDateActivation:
    "Der Testzugang wurde vom {0} bis zum {1} aktiviert",
  DemoAccessDay: "Testzugang für {0} Tag",
  DemoAccessFewDays: "Testzugang für {0} Tage",
  DemoAccessManyDays: "Testzugang für {0} Tage",
  Department: "Abteilung",
  DifferenceInExchangeRates:
    "Bei der Zahlung in Fremdwährung kann sich die von Ihrem Konto abgezogene Summe von der entsprechenden Tarifsumme unterscheiden, was auf die Abweichungen im Wechsekurs während der für diese Transaktion benötigten Konvertierung durch die Banken zurückzuführen ist.",
  DigitalSignatureActivated: "Elektronische Signatur wurde aktiviert",
  DigitalSignatureAlreadyBinded:
    "Diese elektronische Signatur ist schon zu Ihrem Konto hinzugefügt",
  DigitalSignatureAndCertificates: "Elektronische Signatur und Zertifikate",
  DigitalSignatureBinded:
    "Elektronische Signatur wurde erfolgreich hinzugefügt",
  DigitalSignatureBindedToAnotherAccount:
    "Diese elektronische Signatur kann nicht zu Ihrem Konto hinzugefügt werden, weil sie schon von einem anderen Benutzer genutzt wird",
  DigitalSignatureExpired:
    "Gültigkeit des Zertifikats ist abgelaufen, nutzen Sie einen anderen Signaturschlüssel",
  DigitalSignatureIsNotConfidential:
    "Elektronische Signatur wurde von einer Zertifizierungsstelle erteilt, die nicht vertrauenswürdig ist",
  DigitalSignatureSettings: "Elektronische Signatur",
  DigitalSignatureWithdrawn:
    "Die elektronische Signatur ist im Register der widerrufenen Zertifikate eingetragen, nutzen Sie einen anderen Signaturschlüssel",
  Edit: "Ändern",
  Email: "E-Mail",
  EmailAlreadyExist:
    "Man kann nicht die E-Mail-Adresse durch die angegeben ersetzen, da derselbe Wert schon im System gespeichert wurde",
  EmailOrPhone: "E-Mail oder Telefonnummer",
  EmailSignature: "{0}-Gruppe",
  EndLicense: "bis zum",
  EnterCode: "Geben Sie den Code aus der SMS ein",
  EnterCodeConfirmPhone:
    "Geben Sie den Code aus SMS ein, um Ihre neue Telefonnummer zu bestätigen",
  EnterEmailOrPhoneNumber:
    "Geben Sie Ihre E-Mail-Adresse oder Ihre Telefonnummer an",
  EnterMailOrPhone: "Geben Sie Ihre E-Mail-Adresse oder Ihre Telefonnummer an",
  EnterYourEmail:
    "Geben Sie Ihre E-Mail-Adresse ein – und wir schicken Ihnen den Anmeldelink sowie weitere Instruktionen.",
  ExceededRegisterPromo:
    "Das Registrationslimit mit diesem Promo-Code ist überschritten",
  ExtendedTestAccess: "Testzugang verlängert",
  ExtendedTestAccessText:
    "Ihr Testzugang zum System mySeldon wurde verlängert.",
  FewDays: "2 Tage",
  Finances: "Finanzen",
  FirstName: "Vorname",
  ForgotPassword: "Passwort vergessen?",
  ForgotPasswordError:
    "Kein Konto mit solcher E-mail oder solcher Telefonnummer gefunden. Bitte {0}registrieren Sie sich{1}",
  FreeAccessDayProduct: "Kostenloser Zugriff für {0} Tag {1}zu {2}",
  FreeAccessFewDays: "Kostenloser Zugang für {0} Tage",
  FreeAccessFewDaysProduct: "Kostenloser Zugriff für {0} Tage {1}zu {2}",
  FreeAccessManyDays: "Kostenloser Zugang für {0} Tage",
  FreeAccessManyDaysProduct: "Kostenloser Zugriff für {0} Tage {1}zu {2}",
  FreeAccessOneDay: "Kostenloser Zugang für {0} Tag",
  FreeAccessProduct: "Zugang zu {0}",
  FreeAccessProductActivate: "Der freie Zugang zum {0} wurde aktiviert",
  FutureLicense: "Abo zur künftigen Nutzung",
  FutureSubscribeOne: "Sie haben noch ein {0} Abo zur künftigen Nutzung",
  FutureSubscribesFew: "Sie haben noch {0} Abos für künftige Nutzung",
  FutureSubscribesMany: "Sie haben noch {0} Abos für künftige Nutzung",
  Gender: "Geschlecht",
  GenderFemale: "Weiblich",
  GenderMale: "Männlich",
  GetAccessToInfo:
    "Bekommen Sie den Zugang zu Informationen über Unternehmen, Versteigerungen, Ereignisse. Seien Sie auf dem Laufenden.",
  GetDemoAccess: "Testzugang bekommen",
  GetProfit: "Gewinn erzielen",
  GetProfitDescription:
    "Gewinnen Sie Versteigerungen, indem Sie bereits Ihre Chancen kennen. Der Service bewertet Ihre Ausschreibungserfahrung, Finanzmöglichkeiten, Gerichsfälle und 25 weitere Parameter, analysiert und empfiehlt die besten Ausschreibungen für Sie.",
  GoToSystem: "Zum System",
  HavePromo: "Haben Sie Promotionscode?",
  InactivePromo: "Der Promo-Code ist nicht aktiviert",
  IncorrectEmailEntered: "Falsche E-Mail-Adresse angegeben",
  IncorrectPassword:
    "Das eingegebene Passwort ist ungültig. Bitte geben Sie ein gültiges Passwort ein.",
  IncorrectPasswordEntered: "Falsches Passwort angegeben:",
  IncorrectPhoneEntered: "Falsche Telefonnummer angegeben",
  InvalidPromo: "Ungültiger Promo-Code",
  IsSendSms: "Keine SMS empfangen?",
  LastName: "Nachname",
  LicenseAgreement: "Lizenzvereinbarung",
  LicenseCountFew: "{0} Abonnements",
  LicenseCountMany: "{0} Abonnements",
  LicenseCountOne: "{0} Abonnement",
  LinkHasExpired:
    "Dieser Link ist nicht mehr gültig. Fordern Sie die Passwort-Wiederherstellung erneut an.",
  Login: "Benutzername",
  LoginPageHeader: "Anmelden",
  LoginToAccess: "Benutzername zum Zugang zum System:",
  LogOff: "Abmelden",
  ManageRisks: "Risiken steuern",
  ManageRisksDescription:
    "Überprüfen Sie Auftraggeber und Konkurrenten, finden Sie an einen bestimmten Lieferanten angepasste Ausschreibungen heraus. Mit Seldon.Pro erfahren Sie, wie Sie Ihre Risiken minimieren.",
  ManyDays: "{0} Tage",
  MinPasswordLength: "Minimale Passwortlänge ist {0} Zeichen.",
  NewPassword: "Neues Passwort",
  NewsMailDescription:
    "Aggregator für Nachrichten aus russischen und ausländischen Medien.",
  NewsRegistrationTitle:
    "Aktuelle Nachrichten aus aller Welt, Informationen zu Unternehmen, Regionen und Mediapersönlichkeiten.",
  NoActiveLicense: "Keine gültigen Abonnements",
  NotFound: "Keine Treffer gefunden",
  NoTransactions: "Sie haben noch keine Transaktionen.",
  NotRobot: "Bitte bestätigen Sie, dass Sie kein Roboter sind",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN oder Steuer-ID",
  OnDay: "Für {0} Tag",
  OnFewDays: "Für {0} Tage",
  OnlinePayment: "Online-Bezahlung",
  OnManyDays: "Für {0} Tage",
  OpenAccess: "Zugang eröffnet",
  OrderDigitalSignature: "Elektronische Signatur beantragen",
  OrderDigitalSignatureSettings:
    "Erstellung der elektronischen Signatur beantragen",
  Password: "Passwort",
  PasswordChanged: "Passwort wurde geändert",
  PasswordHasAPartOfLoginName:
    "Das Passwort darf nicht den Kontonamen des Benutzers oder mehr als zwei Zeichen enthalten, die nacheinander im vollständigen Namen des Benutzers vorkommen.",
  PasswordMail: "{0} ist Ihr Passwort für {1}.",
  PasswordMustBeAtLeast8Symbols:
    "Das Passwort muss mindestens 8 Zeichen lang sein",
  PasswordMustContainsLettersAndDigits:
    "Das Passwort muss entweder lateinische Großbuchstaben (A bis Z) und Zahlen (0 bis 9) oder lateinische Kleinbuchstaben (a bis z) und Zahlen (0 bis 9) oder lateinische Großbuchstaben (A bis Z) und lateinische Kleinbuchstaben (a bis z) enthalten",
  PasswordNotUpToDate:
    "Das Passwort ist nicht mehr gültig, registrieren Sie sich erneut",
  PasswordRequirementsError:
    "Ihr Passwort erfüllt nicht die Sicherheitsanforderungen:",
  PasswordsMismatch:
    'Werte für Felder "Neues Passwort" und "Wiederholen" stimmen nicht überein, versuchen Sie es noch einmal.',
  PaymentButton: "Zur Zahlung übergehen",
  PaymentMethod: "Zahlungsart",
  PaymentText_Mail:
    "Ihre Zahlung für den Service {0} wurde erfolgreich abgeschlossen.",
  PersonalInfo: "Persönliche Daten",
  Phone: "Telefonnummer",
  PhoneNumberAlreadyExist:
    "Man kann nicht die Telefonnummer durch die angegebene ersetzen, da derselbe Wert schon im System gespeichert wurde",
  PhoneOrEmailBusy:
    "Ein Benutzer mit dieser Telefonnummer oder mit dieser E-Mail-Adresse existiert bereits",
  PlatformsWithContracts:
    "Plattformen, mit denen Vereinbarungen abgeschlossen sind",
  PleaseFillRequirementFields:
    "Nicht alle obligatorischen Felder wurden ausgefüllt. Bitte füllen Sie Felder {0} aus",
  Position: "Position",
  PriceMailDescription:
    "Service für die Auswahl von Verträgen zur Begründung des ursprünglichen Höchstpreises.",
  PriceRegistrationTitle:
    "Bereiten Sie die Bestätigung des ursprünglichen Höchspreisdes des Vertrages möglichst schnell und einfach. Analysieren Sie die E-Vergabebereich, erfahren Sie über Aktivitäten Ihrer Vertragspartner und ihre Preise.",
  PriceTariffDescription1:
    "Suchen Sie Verträge zum Nachweis des Anfangshöchstpreises in Sekunden aus. Laden Sie den fertigen Nachweis durch einen Klick herunter und fügen Sie ihn zu den Ausschreibungsdokumenten hinzu. Dann ist Ihr Preisangebot sicher rechtmäßig.",
  PriceTariffDescription2:
    "Analysieren Sie Preise für Waren und Dienstleistungen, um konkurrenzfähiger zu werden. Studieren Sie erfüllte Verträge Ihrer Konkurrenz, sehen Sie die Preisdynamik durch, beobachten Sie den Saisoncharakter von Nachfrage und Angebot.",
  PriceTariffDescription3:
    "Verwenden Sie Kennzahlen, um jeweiligen Vertragspreis nachweisbar zu machen und Lieferanten zu gewinnen. Sie können Multiplikator und Deflator einstellen sowie andere Vergabebedingungen berücksichtigen. Dank Seldon.Price sind Ihre Gebote immer attraktiv.",
  PriceTariffTitle1: "Anfangshöchstpreis des Vertrags nachweisen",
  PriceTariffTitle2: "Markt analysieren",
  PriceTariffTitle3: "Neue Auftragnehmer anziehen",
  PrivacyPolicy: "Datenschutzerklärung",
  PrivacyPolicyAndOffer:
    'Wenn Sie auf "Zur Zahlung übergehen" klicken, bestätigen Sie, dass Sie die Bedingungen der {0}Datenschutzrichtlinien{1} und des {2}Angebots{3} gelesen haben.',
  PrivatePolicyRegister:
    "Mit der Eingabe Ihrer persönlichen Daten akzeptieren Sie deren Verarbeitung gemäß den {0}Datenschutzerklärung{1}",
  ProductCountFew: "für {0} Produkte",
  ProductCountMany: "für {0} Produkte",
  ProductCountOne: "für {0} Produkt",
  Prolong: "Verlängern",
  ProMailDescription: "KI-basierter Service zum Umgang mit Ausschreibungen",
  PromoAnotherProduct: "Der Promo-code bezieht sich auf ein anderes Produkt",
  Promocode: "Promocode",
  PromoExpired: "Der Promo-code ist nicht mehr gültig",
  PublicOffer:
    'Das öffentliche Angebot (nachfolgend "Angebot" genannt) ist gemäß Art. 437 Abs.2 des Zivilgesetzbuchs der Russischen Föderation das öffentliche Angebot, mit jeglicher juristischen, natürlichen Person sowie Einzelunternehmer (nachfolgend "Nutzer" genannt) zu den im Angebot vorgeschriebenen Bedingungen den Vertrag abzuschließen. Der Vertrag wird nur durch vollen Vertragsbeitritt mittels Angebotsannahme abgeschlossen. Als Angebotsannahme gilt die Zahlung der Lizenzgebühr durch den Nutzer. Hiermit gilt der Vertrag als in Schriftform abgeschlossen und ist gleichwertig dem von beiden Seiten unterschriebenen Vertrag.',
  PurchasingRegions: "Regionen Ihrer Beschaffungstätigkeit",
  RecoveryPassword: "Passwort-Wiederherstellung",
  RecoveryPassword_Mail:
    "Lieber Benutzer,{0}Um das Passwort zu ändern, klicken Sie auf den {1}Link{2}!{3} Mit freundlichen Grüßen,{4}",
  RecoveryPassword2_Mail:
    "Sie haben das Folmular zur Passwort-Wiederherstellung genutzt. Um Ihr Passwort wiederherzustellen, klicken Sie auf den {0}Link{1}. Haben Sie nicht selbst dies vorgenommen, ändern Sie Ihr Passwort und setzen Sie sich mit Technischem Support in Verbindung.",
  Refill: "Aufladen",
  Register: "Registrieren",
  RegisterToStart: "Registrieren Sie sich, um zu starten",
  RegistrationСompleted: "Registrierung abgeschlossen",
  RememberMe: "Mich speichern",
  RemovePromo: "Promocode löschen",
  RenewSubscription: "Abonnement verlängern",
  RepeatNewPassword: "Bestätigen Sie das neue Passwort",
  RequiredField: "Pflichtfeld",
  Resend: "Erneut senden",
  Restore: "Wiederherstellen",
  Save: "Speichern",
  SavePasswordSuccess: "Passwort wurde erfolgreich gespeichert",
  SavePolicy:
    'Wenn Sie auf "Speichern" klicken, bestätigen Sie, dass Sie die Bedingungen der {0}Datenschutzrichtlinien{1} gelesen haben und den Bedingungen zustimmen.',
  SaveUnavailable: "Speichern nicht möglich:",
  Search: "Suche",
  SearchInBasis: "Finden Sie Informationen über sich in Seldon.Basis",
  Security: "Sicherheit",
  SelectedRegions: "Ausgewählte Regionen",
  SelectRegion: "Region wählen",
  Send: "Senden",
  SendAgain: "Erneut senden",
  SendCode: "Code wurde gesendet",
  SendPasswordOrCreateNew:
    'Ihr Passwort wurde als Code auf Ihr Handy gesendet. Klicken Sie auf "Passwort ändern", um Ihr eigenes Passwort zu erstellen.',
  SendSmsWithPassword:
    "An diese Nummer wird eine SMS mit einem Kennwort gesendet",
  SendToNumber: "Der Сode wurde an Ihre Nummer gesendet",
  Settings: "Einstellungen",
  Show: "Anzeigen",
  ShowVideo: "Video ansehen",
  SignIn: "Anmelden",
  SignInSystem: "Anmelden",
  SignUpWithDS: "Registrierung durch elektronische Signatur",
  SincerelyTeam: "Mit freundlichen Grüßen,{0}-Gruppe",
  SingleLoginSeldon: "Einheitlicher Zugang zu allen Seldon Services",
  SliderBasisInfo:
    "Nutzen Sie das System, um Ihre Handelspartner allseitig zu bewerten. Erfahren Sie wie gewissenhaft Ihre Handelspartner die übernommenen Verpflichtungen (finanzielle, vertragliche, juristische) erfüllen.",
  SliderNewsInfo:
    "Wissen Sie Bescheid von den Neuigkeiten in Ihrem Bereich, in Russland, in der Welt. Passen Sie Ihre Seiten-Feeds an und lesen Sie nur die Neuigkeiten, die Sie interessant finden.",
  SliderPriceInfo:
    "Wählen Sie Verträge zum Nachweis des Anfangshöchstpreises in Sekunden aus. Analysieren Sie Preise für Waren und Dienstleistungen, um bessere Konkurrenzchancen zu haben.",
  SliderProInfo:
    "KI-basierter Dienst für die erfolgreiche Teilnahme an Ausschreibungen. Persönliche Empfehlungen — unter Berücksichtigung des Tätigkeitsprofils und der Möglichkeiten, Bewertung der Auftragnehmer und der Konkurrenten sowie der Gewinnchancen.",
  Snap: "Hinzufügen",
  SnapDigitalSignature: "Elektronische Signatur hinzufügen",
  StartLicense: "ab",
  StartUsing: "Starten",
  Subscribe: "Abonnieren",
  SuccessRegister_Mail: "Sie wurden erfolgreich im System {0} registriert.",
  SupportMailLink:
    "Haben Sie Fragen, dann klicken Sie auf {0}«Mehr erfahren»{1} und unser Spezialist hilft Ihren.",
  SystemLanguage: "Systemsprache",
  Text403:
    "Sie können diese Seite nicht anzeigen. Wenden Sie sich an den Support, wenn Sie der Meinung sind, dass ein Fehler aufgetreten ist.",
  Text404:
    "Der Link wurde fehlerhaft getippt. {0} Versuchen Sie es noch Mal von der {1}Startseite{2}",
  Text500:
    "Leider, ist der Service noch nicht verfügbar. Versuchen Sie, die Anfrage zu wiederholen oder gehen Sie zur {0}Startseite{1}.",
  TillDate: "bis {0}",
  TimeLeft: "{0} Sekunden geblieben",
  Title403: "Nicht gestattet",
  Title404: "Die Seite wurde nicht gefunden",
  Title500: "Ein Fehler ist aufgetreten",
  ToSaveTime: "Zeit sparen",
  ToSaveTimeDescription:
    "Alle Informationen sind an einem Ort und werden in Echtzeit aktualisiert. Sie brauchen nicht mehr, nach neuen Ausschreibungen zu suchen, die Durchsichtigkeit des Verfahrens zu überprüfen, Auftraggeber zu bewerten. Sie haben jetzt alle passenden Ausschreibungen bei der Hand.",
  Total: "Insgesamt:{0}",
  Transactions: "Transaktionen",
  TryOtherProducts: "Testen Sie andere Produkte von Seldon",
  UploadNewPhoto: "Ein anderes Foto hinzufügen",
  UserNotFound: "Benutzer nicht gefunden",
  WeNotFind:
    "Leider konnten keine Treffer gefunden werden, {0}suchen Sie in {1}",
  WeSentCodeConfirm:
    "Wir haben Ihnen einen Bestätigungscode auf Ihr Handy gesendet. Bestätigen Sie, dass Sie den Code erhalten haben:",
  WrongOldPassword: "Ungültiges Passwort",
  WrongPassword:
    "Das eingegebene Passwort ist falsch. Versuchen Sie es noch einmal, bitte.",
  YouCanFindInfoInBasis:
    "Sie können immer {0}Informationen in Seldon Basis{1} finden",
  YourLogin: "Ihr Login-Name:"
};
export default dictionary;
