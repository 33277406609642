import * as types from "./action-types";

const initialState = {
  partnerId: null,
  initialProduct: {
    returnUrl: "",
    productName: ""
  }
};

const initialReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.ADD_INITIAL_PRODUCT:
      return { ...state, initialProduct: action.initialProduct };
    case types.ADD_PARTNER_ID:
      return { ...state, partnerId: action.partnerId };
    default:
      return state;
  }
};

export default initialReducer;
