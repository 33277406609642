import devConfig from "./dev-config";
import prodConfig from "./prod-config";
import mainConfig from "./main-config";
import testConfig from "./test-config";
import debugConfig from "./debug-config";

let env;

if (process.env.CONFIG_TYPE === "dev") {
  env = devConfig;
} else if (process.env.CONFIG_TYPE === "test") {
  env = testConfig;
} else if (process.env.CONFIG_TYPE === "prod") {
  env = prodConfig;
} else if (process.env.CONFIG_TYPE === "debug") {
  env = debugConfig;
}

const config = Object.assign({}, mainConfig, env);

export default config;
