// Landing
import React from "react";

// Component
import LandingSliderLaptop from "../LandingSliderLaptop";

// Image
import mainBg from "@images/landing/pattern.jpg";

const LandingSliderInfo = () => (
  <div
    className="py-5 bg-light"
    style={{ background: `url(${mainBg})`, backgroundSize: "cover" }}
  >
    <h2 className="px-2 px-sm-0 text-sm-center text-black">
      <b>Держите ситуацию под контролем</b>
    </h2>
    <p className="px-2 px-sm-0 h5 mt-2 text-sm-center">
      Инструменты для агрегации и анализа информации позволяют анализировать
      информацию
      <br className="d-none d-md-block" /> как по РФ в целом, так и по каждому
      отдельно взятому региону.
    </p>
    <div className="mt-4">
      <LandingSliderLaptop />
    </div>
  </div>
);

export default LandingSliderInfo;
