const dictionary = {
  " ActiveLicenses": "Licencias activas",
  " Apply": "Aplicar",
  " GetAccess": "Obtener acceso",
  " IncorrectPassword": "Código de confirmación invalido",
  " LoginCredentials": "Datos de identificación para acceder al sistema:",
  " MinLengthFew": "El campo {0} debe contener un mínimo de {1} caracteres",
  AccessDateActivation: "Acceso demo activado de {0} a {1}",
  AccessDay: "Acceso ilimitado a {0} durante un día",
  AccessHalfYear: "Acceso de seis meses a todos los beneficios de {0}",
  AccessMonth: "Acceso ilimitado a {0} durante un mes",
  AccessThreeMonth: "Acceso de tres meses a todos los beneficios de {0}",
  AccessWeek: "Acceso ilimitado a {0} durante una semana",
  AccessYear: "Acceso ilimitado a {0} durante un año",
  AccountAlreadyContainsThisVIPCode:
    "la cuenta ya contiene este código promocional VIP",
  AccountWithTheSameDSAlreadyExist:
    "La cuenta con dicha firma digital ya existe",
  AccountWithTheSameEmailAlreadyExist: "la cuenta con dicha email ya existe",
  Activate: "Activar",
  ActivationConfirmation: "Confirmaciones de activacion",
  ActivationResult: "Resultado de activación",
  ActivationTextBasis1:
    'Estudie los vínculos entre empresas, verifique su afiliación en el esquema interactivo: "El árbol de conexiones".',
  ActivationTextBasis2:
    "Consulte los datos de registro de las empresas y los gerentes, verifique la dirección general y los números de teléfono.",
  ActivationTextBasis3:
    "Hablamos de contratos con el gobierno concluidos por empresas y empresarios. Tenemos información sobre cómo a conciencia cumplen con sus obligaciones contractuales.",
  ActivationTextBasis4:
    "Publicamos el balance y calculamos los indicadores de las empresas: liquidez, solvencia, estabilidad financiera, rentabilidad, activos netos.",
  ActivationTextControl1:
    "Tenga en cuenta la transparencia y la integridad de las actividades de contratación conforme a los artículos 44-ФЗ y 223-ФЗ.",
  ActivationTextControl2:
    "Evaluación integral de las actividades de compras de la organización. Tasas de utilización del presupuesto y eficiencia de adquisiciones, indicadores de riesgo.",
  ActivationTextControl3:
    "Notificación oportuna de todos los aspectos de la actividad de licitación: llevar a cabo la contratación prevista, retrasar la posición, imposibilidad de celebrar un contrato, etc.",
  ActivationTextNews1:
    "Información actual sobre eventos en la industria, en el país y en el mundo. Sé el primero en conocer eventos clave.",
  ActivationTextNews2:
    "Configure su propio feed y reciba noticias clave sobre su perfil, información actual sobre socios y competidores.",
  ActivationTextNews3:
    "Mira lo que está escondido. La tonalidad de las noticias, el dossier sobre la empresa y la persona, la influencia de los medios en su imagen. El analista más detallado las 24 horas del día, los 7 días de la semana.",
  ActivationTextPrice1:
    "Considere la variabilidad de precios e indique precios competitivos, de modo que sus compras siempre tengan ofertas.",
  ActivationTextPrice2:
    "Utilice los coeficientes para justificar cualquier precio de contrato, dadas las diferentes condiciones de compra.",
  ActivationTextPrice3:
    "Su precio, respaldado por una justificación, cumplirá con los requisitos de la ley y encontrará rápidamente a su proveedor.",
  ActivationTextPro1:
    "Haga un seguimiento del precio y la demanda de bienes y servicios, manténgase al día con las nuevas compras usando widgets.",
  ActivationTextPro2:
    "Participe solo en aquellas subastas donde las posibilidades de ganar sean las más altas posible. Sobre la base del historial de licitaciones, la evaluación de los posibles participantes y otros factores, se crean predicciones personales de la victoria.",
  ActivationTextPro3:
    "La inteligencia artificial evaluará la condición financiera del cliente, el historial de adquisiciones, los casos de arbitraje, para que sepa con quién trabajará.",
  ActivationTextPro4:
    "El sistema selecciona personalmente ofertas especializadas basadas en datos de la empresa para que no pierda tiempo en una búsqueda independiente.",
  ActivationTitleBasis1: "Comunicación y afiliación.",
  ActivationTitleBasis2: "Certificado de Incorporación",
  ActivationTitleBasis3: "Contratos y proveedores sin escrúpulos.",
  ActivationTitleBasis4: "Balances y análisis expreso.",
  ActivationTitleControl1: "Estar atento a las violaciones",
  ActivationTitleControl2: "Analítica de adquisiciones",
  ActivationTitleControl3: "Monitoreo de eventos",
  ActivationTitleNews1: "Noticias de todo el mundo.",
  ActivationTitleNews2: "Cinta personal",
  ActivationTitleNews3: "Analítica e Inteligencia Artificial",
  ActivationTitlePrice1: "Rango de precios",
  ActivationTitlePrice2: "Ajustar el precio",
  ActivationTitlePrice3: "Confirmar el precio",
  ActivationTitlePro1: "Análisis de licitaciones.",
  ActivationTitlePro2: "Pronóstico gana",
  ActivationTitlePro3: "Evaluación de fiabilidad",
  ActivationTitlePro4: "Mejores compras",
  ActiveFrom: "activo desde",
  ActiveLicense: "Licencia activa",
  ActiveLicenseCountFew: "{0} licencias activas",
  ActiveLicenseCountMany: "{0} licencias activas",
  ActiveLicenseCountOne: "{0} licencia activa",
  ActualTill: "Válido hasta",
  AddRemoveRegion: "Agregar/borrar región",
  AdminPasswordReset:
    "¡Estimado usuario!{0} Su contraseña ha sido cambiada por el administrador. Para establecer una nueva contraseña, siga el {1} enlace {2} .{3}Atentamente,{4}",
  AfterPayment: "Después del pago podrá:",
  AlreadyRegistered: "¿Ya está registrado?",
  AtLeastThanEightSymbols: "Al menos 8 caracteres.",
  AuthorizeWithDS: "Autorización a través de firma digital",
  Balance: "Saldo de cuenta",
  BasisMailDescription:
    "Servicio de análisis y evaluación de empresas rusas y extranjeras.",
  BasisRegistrationTitle:
    "Puede consultar a sus socios, encontrar clientes, conocer sus fortalezas y debilidades.",
  BasisTariffDescription1:
    "24 millones de empresas y emprendedores rusos estarán a su alcance. Sus capacidades y necesidades, fortalezas y debilidades. Búsqueda conveniente por nombre, requisitos, contactos.",
  BasisTariffDescription2:
    "Estudie el expediente sobre la empresa. Descubra cuáles son fiables en el cumplimiento de sus obligaciones en la esfera financiera, contractual y judicial.",
  BasisTariffDescription3:
    "Reciba notificaciones sobre eventos en la vida de empresas. Seldon.Basis mostrará y advertirá sobre los socios o competidores poco seguros. Use estadísticas y análisis para obtener una vista completa de empresa.",
  BasisTariffDescription4:
    "Estudie las actividades de empresas de los países de la CEI. Compruebe su participación justa en la licitación, analice su situación financiera. Expanda su negocio — trabaje con socios extranjeros.",
  BasisTariffTitle1: "Buscar nuevos clientes",
  BasisTariffTitle2: "Minimizar riesgos",
  BasisTariffTitle3: "Mantenerse informado",
  BasisTariffTitle4: "Buscar socios de la CEI",
  Bonuses: "Puntos de bonificación",
  BuySubscription: "Comprar suscripción",
  CanSendIn: "Puede ser enviado a través de {0}",
  ChangeData: "Editar datos",
  ChangePassword: "Cambiar contraseña",
  ChangesSaved: "Los cambios han sido guardados",
  ChangingEmail_SendToNewEmail:
    "¡Estimado usuario!{0} Siga el {1} enlace {2} para confirmar nuevo email.{3} Atentamente,{4}",
  ChangingEmail_SendToNewEmailTitle: "Confirmar nuevo email",
  ChangingEmail_SendToOldEmail:
    "¡Estimado usuario!{0}Ha cambiado su email a {1} Si no lo ha hecho, cambie la contraseña y contacte a soporte técnico {2}Atentamente,{3}",
  ChangingEmail_SendToOldEmailTitle: "Notificación de cambio de email",
  ChangingPassword:
    "¡Estimado usuario!{0}Su contraseña ha sido cambiada. Si no lo ha hecho, cambie la contraseña y contacte a soporte técnico{1}Atentamente, {2}",
  ChangingPasswordForAdmin:
    "¡Estimado usuario!{0}Su contraseña ha sido cambiada por el administrador. La nueva contraseña es {1} Atentamente,{2}",
  ChangingPasswordTitle: "Notificación de cambio de contraseña",
  CheckEmailRecoverPassword:
    "Acceda a su correo electrónico para recuperar la contraseña.",
  ChooseCertificate: "Seleccionar certificado",
  ChooseFile: "Seleccionar archivo",
  ChooseStrongPassword:
    "Piense en una contraseña segura y no la use en otros lugares.",
  CommonSettings: "Configuración general",
  CommonSignIn: "Autorización normal",
  CommonSignUp: "Registro normal",
  Company: "Empresa",
  Confirm: "Confirmar",
  ConfirmActivationTitle: "Ya estás registrado. Accede con un clic",
  ConfirmLicenseCreated_Mail:
    "¡Estimado usuario!{0}Ha comprado con éxito una licencia de producto {1}Atentamente,{2}",
  ConfirmLicenseCreated_MailTitle: "Comprar suscripción",
  ConfirmLicenseUpdated_Mail:
    "¡Estimado usuario!{0}Ha renovado su licencia con éxito {1}Atentamente,{2}",
  ConfirmLicenseUpdated_MailTitle: "Renovación de suscripción",
  ConfirmRegister: "Confirmación de registro",
  ConfirmRegister_Mail:
    "¡Estimado usuario!{0}Se ha registrado con éxito en el sistema MySeldon. Para cambiar su contraseña, siga el {1}enlace{2}.{3}Atentamente,{4}",
  Consult: "Consulta",
  ConsultText:
    'Si tiene alguna pregunta o no tenía tiempo para {0} usar el acceso de prueba, {0} haga clic en el botón "Consulta".',
  ControlMailDescription:
    "El sistema de prevención de violaciones, análisis y seguimiento de las actividades de contratación.",
  ControlRegistrationTitle:
    "Controla tu actividad de compra. Analizar gastos y optimizar participación en licitaciones para 44-ФЗ y 223-ФЗ.",
  ControlTariffDescription1:
    "Un análisis rápido y detallado de las actividades de adquisición de empresas ahorrará tiempo, recursos y permitirá tomar decisiones ponderadas.",
  ControlTariffDescription2:
    "El sistema minimizará los riesgos, evitará posibles violaciones y multas por procedimientos dentro del marco de las leyes federales 44-FZ y 223-FZ.",
  ControlTariffTitle1: "Analizar y monitorear las actividades de licitación",
  CreatePassword: "Creación de contraseña",
  CurrentPassword: "Antigua contraseña",
  CurrentSubsriptions: "Suscripciones actuales",
  DataProtected: "La información está protegida según PCI DSS",
  Day: "{0} día",
  DearUser: "¡Estimado usuario!",
  Demo: "Acceso de prueba",
  DemoAccess: "Acceso demo",
  DemoAccessDateActivation: "Acceso demo activado de {0} a {1}",
  DemoAccessDay: "Acceso demo para {0} día",
  DemoAccessFewDays: "Acceso demo el día {0}",
  DemoAccessManyDays: "Acceso demo por {0} días",
  Department: "Subdivisión",
  DifferenceInExchangeRates:
    "En caso de pago en moneda extranjera, la cantidad retirada de su cuenta puede diferir de la cantidad indicada como el costo de esta tarifa, debido a la diferencia en los tipos de cambio durante esta transacción.",
  DigitalSignatureActivated: "La firma digital está activada",
  DigitalSignatureAlreadyBinded:
    "Esta firma digital ya está vinculada a su cuenta",
  DigitalSignatureAndCertificates: "Firma digital y certificados",
  DigitalSignatureBinded: "La firma digital se ha vinculado con éxito",
  DigitalSignatureBindedToAnotherAccount:
    "Esta firma digital no puede ser vinculada a su cuenta, ya que ya es utilizada por otro usuario",
  DigitalSignatureExpired:
    "El período de validez de certificado ha expirado. Utilice la clave digital diferente",
  DigitalSignatureIsNotConfidential:
    "La firma digital es emitida por la autoridad de certificación que no está incluida en la zona de confianza",
  DigitalSignatureSettings: "Configuración de firma digital",
  DigitalSignatureWithdrawn:
    "Esta firma digital está en el registro de certificados revocados. Utilice la clave digital diferente",
  Edit: "Editar",
  Email: "E-mail",
  EmailAlreadyExist:
    "No se puede cambiar el email al valor especificado, ya que ya existe en el sistema",
  EmailOrPhone: "Correo electrónico o número de teléfono",
  EmailSignature: "Equipo de {0}",
  EndLicense: "hasta el",
  EnterCode: "Ingrese el código de SMS",
  EnterCodeConfirmPhone:
    "Introduzca el código de SMS para confirmar el nuevo número de teléfono",
  EnterEmailOrPhoneNumber:
    "Introduzca su dirección de correo electrónico o número de teléfono",
  EnterMailOrPhone:
    "Introduzca su dirección de correo electrónico o número de teléfono",
  EnterYourEmail:
    "Introduzca su correo electrónico para que podamos enviarle el enlace de autorización y otras instrucciones.",
  ExceededRegisterPromo:
    "Se excedió el número permitido de entradas para este código promocional",
  ExtendedTestAccess: "Acceso de prueba extendido",
  ExtendedTestAccessText:
    "Su acceso de prueba al sistema mySeldon ha sido extendido.",
  FewDays: "{0} días",
  Finances: "Finanzas",
  FirstName: "Nombre",
  ForgotPassword: "¿Olvidó su contraseña?",
  ForgotPasswordError:
    "La cuenta con la dirección de correo electrónico o el número de teléfono que proporcionó no existe. Completar {0} registro {1}",
  FreeAccessDayProduct: "Acceso gratuito en {0} día {1}a {2}",
  FreeAccessFewDays: "Acceso gratuito para {0} días",
  FreeAccessFewDaysProduct: "Acceso gratuito en {0} día {1}a {2}",
  FreeAccessManyDays: "Acceso gratuito para {0} días",
  FreeAccessManyDaysProduct: "Acceso gratuito a {0} días {1}a {2}",
  FreeAccessOneDay: "Acceso gratuito para {0} día",
  FreeAccessProduct: "Acceso al sistema {0}",
  FreeAccessProductActivate: "Acceso gratuito a {0} activado",
  FutureLicense: "Licencia pendiente",
  FutureSubscribeOne: "Tiene {0} más suscripción pendiente",
  FutureSubscribesFew: "Tiene {0} suscripciones pendientes",
  FutureSubscribesMany: "Tiene {0} suscripciones pendientes",
  Gender: "Sexo",
  GenderFemale: "Femenino",
  GenderMale: "Masculino",
  GetAccessToInfo:
    "Obtenga acceso a la información sobre empresas, licitaciones, eventos. Monitoree la actividad del mercado.",
  GetDemoAccess: "Obtener acceso demo",
  GetProfit: "Obtener beneficios",
  GetProfitDescription:
    "Gana licitaciones conociendo sus posibilidades de antemano. El servicio evaluará su experiencia de participación en licitaciones, oportunidades financieras, casos judiciales y otros 25 parámetros, analizará y recomendará las mejores adquisiciones.",
  GoToSystem: "Ir al sistema",
  HavePromo: "¿Tiene un código de promoción?",
  InactivePromo: "Código promocional inactivo",
  IncorrectEmailEntered: "email incorrecto",
  IncorrectPassword: "Contraseña incorrecta",
  IncorrectPasswordEntered: "contraseña incorrecta:",
  IncorrectPhoneEntered: "número de teléfono incorrecto",
  InvalidPromo: "Código promocional inválido",
  IsSendSms: "SMS no vino?",
  LastName: "Apellido",
  LicenseAgreement: "Acuerdo de licencia",
  LicenseCountFew: "{0} licencias",
  LicenseCountMany: "{0} licencias",
  LicenseCountOne: "{0} licencia",
  LinkHasExpired:
    "El enlace ha expirado. Solicite la recuperación de contraseña de nuevo.",
  Login: "Nombre de usuario",
  LoginPageHeader: "Iniciar sesión",
  LoginToAccess: "Nombre de usuario para acceder al sistema:",
  LogOff: "Cerrar sesión",
  ManageRisks: "Controlar riesgos",
  ManageRisksDescription:
    "Verifique clientes y competidores, identifique las adquisiciones que se adaptan al proveedor específico. Con Seldon.Pro sabrá con quién es seguro trabajar.",
  ManyDays: "{0} días",
  MinPasswordLength:
    "La longitud mínima de la contraseña es de {0} caracteres.",
  NewPassword: "Nueva contraseña",
  NewsMailDescription: "Agregador de noticias de medios rusos y extranjeros.",
  NewsRegistrationTitle:
    "Noticias actuales de todo el mundo, dossier sobre empresas, regiones, medios de comunicación.",
  NoActiveLicense: "No hay licencias activas",
  NotFound: "Nada encontrado",
  NoTransactions: "Hasta el momento no tiene transacciones.",
  NotRobot: "Por favor, confirme que no es un robot.",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN o NIF",
  OnDay: "El día {0}",
  OnFewDays: "El día {0}",
  OnlinePayment: "Pago en línea",
  OnManyDays: "Por {0} días",
  OpenAccess: "Acceso abierto",
  OrderDigitalSignature: "Pedir la firma digital",
  OrderDigitalSignatureSettings: "Ordenar el ajuste de firma digital",
  Password: "Contraseña",
  PasswordChanged: "Su contraseña ha sido cambiada",
  PasswordHasAPartOfLoginName:
    "la contraseña no debe contener el nombre de la cuenta o parte del nombre completo de usuario con la longitud de más de dos caracteres adyacentes",
  PasswordMail: "{0} — su contraseña para {1}.",
  PasswordMustBeAtLeast8Symbols:
    "La contraseña debe tener al menos de 8 caracteres",
  PasswordMustContainsLettersAndDigits:
    "La contraseña debe contener mayúsculas romanas (desde A a Z) y números (desde 0 a 9), o minúsculas romanas (desde a a z) y números (desde 0 a 9), o mayúsculas romanas (desde A a Z) y minúsculas romanas (desde a a z)",
  PasswordNotUpToDate:
    "La contraseña ha caducado. Por favor regístrese de nuevo.",
  PasswordRequirementsError:
    "La contraseña no cumple los requisitos de seguridad:",
  PasswordsMismatch: "La nueva contraseña y su confirmación no coinciden.",
  PaymentButton: "Proceder con el pago",
  PaymentMethod: "Método de pago",
  PaymentText_Mail: "Su pago por el servicio {0} fue exitoso.",
  PersonalInfo: "Información personal",
  Phone: "Número de teléfono",
  PhoneNumberAlreadyExist:
    "No se puede cambiar el número de teléfono al valor especificado, ya que ya existe en el sistema",
  PhoneOrEmailBusy: "Ya existe el usuario con dicho número de teléfono o email",
  PlatformsWithContracts: "Los sitios con los que existen acuerdos celebrados",
  PleaseFillRequirementFields:
    "No todos los campos obligatorios son rellenados. Rellene los campos {0}",
  Position: "Posición",
  PriceMailDescription:
    "Servicio de selección de contratos para justificar el precio máximo inicial.",
  PriceRegistrationTitle:
    "Prepare la justificación del NMCC de la manera más rápida y sencilla posible. Analice el mercado del comercio electrónico, averigüe los precios y la actividad de los competidores.",
  PriceTariffDescription1:
    "Elija contratos para justificar su precio máximo inicial en segundos. Descargue la justificación del precio con un solo botón y inclúyala en la documentación correspondiente de adquisición. Puede estar seguro de que su oferta de precio está de acuerdo con la legislación.",
  PriceTariffDescription2:
    "Analice los precios de bienes y servicios para hacer su negocio más competitivo. Estudie los contratos ejecutados de los competidores, monitoree la dinámica de precios, observe la estacionalidad de la oferta y demanda.",
  PriceTariffDescription3:
    "Utilice las ratios para justificar el cualquier precio de contrato y para interesar a los proveedores. Puede especificar la ratio de aumento, deflactor u otras condiciones de adquisición. Con Seldon.Price sus adquisiciones siempre tendrán demanda.",
  PriceTariffTitle1: "Justificar el precio inicial máximo del contrato",
  PriceTariffTitle2: "Analizar el mercado",
  PriceTariffTitle3: "Atraer nuevos proveedores",
  PrivacyPolicy: "Política de privacidad",
  PrivacyPolicyAndOffer:
    'Al hacer clic en el botón "Proceder con el pago", usted acepta los términos y condiciones de {0}Política de privacidad{1} y {2}Oferta{3}.',
  PrivatePolicyRegister:
    "Al enviar sus datos personales en el formulario, usted acepta su procesamiento de acuerdo con la {0} Política de privacidad {1}",
  ProductCountFew: "para {0} productos",
  ProductCountMany: "para {0} productos",
  ProductCountOne: "para {0} producto",
  Prolong: "Prolongar",
  ProMailDescription:
    "servicio de inteligencia artificial para el trabajo con licitaciones.",
  PromoAnotherProduct: "Código promocional especificado para otro producto.",
  Promocode: "Código promocional",
  PromoExpired: "Código de promoción vencido",
  PublicOffer:
    "La oferta pública (en adelante - la Oferta), conforme al p. 2 del art. 437 del Código Civil de la Federación de Rusia, es una oferta oficial a celebrar el acuerdo con cualquier persona jurídica, persona natural o emprendedor individual (en adelante – el Usuario) bajo las condiciones previstas en la Oferta. El acuerdo se celebra mediante la aceptación de la Oferta. Por la aceptación de la Oferta se entiende el pago del canon de licencia. En este caso, el acuerdo se considera celebrado por escrito y es equivalente al acuerdo firmado por las dos partes.",
  PurchasingRegions: "Regiones de su actividad de adquisición",
  RecoveryPassword: "Recuperación de contraseña",
  RecoveryPassword_Mail:
    "¡Estimado usuario!{0}Para cambiar su contraseña, siga el {1}enlace{2}.{3}Atentamente,{4}",
  RecoveryPassword2_Mail:
    "Ha utilizado el formulario de recuperación de contraseña. Haga clic en el {0}enlace{1} para seguir.  Si no lo ha hecho, cambie la contraseña y contacte a soporte técnico",
  Refill: "Recargar",
  Register: "Registro",
  RegisterToStart: "Regístrese para empezar",
  RegistrationСompleted: "El registro está completo",
  RememberMe: "Recordar",
  RemovePromo: "Eliminar código de promoción",
  RenewSubscription: "Renovar suscripción",
  RepeatNewPassword: "Confirme su nueva contraseña",
  RequiredField: "campo requerido",
  Resend: "Reenviar",
  Restore: "Recuperar",
  Save: "Guardar",
  SavePasswordSuccess: "Su contraseña ha sido guardada con éxito",
  SavePolicy:
    'Al hacer clic en el botón "Guardar", usted acepta los términos y condiciones de {0}Política de privacidad{1}.',
  SaveUnavailable: "No es posible salvar:",
  Search: "Búsqueda",
  SearchInBasis: "Encuentra información sobre si mismo en Seldon.Basis",
  Security: "Seguridad",
  SelectedRegions: "Regiones seleccionadas",
  SelectRegion: "Seleccione región",
  Send: "Enviar",
  SendAgain: "Enviar de nuevo",
  SendCode: "Código enviado",
  SendPasswordOrCreateNew:
    'La contraseña ha sido enviada a su número de teléfono. Haga clic en el botón "Cambiar contraseña" para configurar su propia contraseña.',
  SendSmsWithPassword:
    "Enviaremos un SMS con una contraseña al sistema a este número.",
  SendToNumber: "Lo enviamos a tu numero",
  Settings: "Configuración",
  Show: "Mostrar",
  ShowVideo: "Ver video",
  SignIn: "Iniciar sesión",
  SignInSystem: "Inicie sesión",
  SignUpWithDS: "Registro a través de firma digital",
  SincerelyTeam: "Atentamente,{0} equipo de Seldon",
  SingleLoginSeldon:
    "Acceso a todos los productos de Seldon con una sola cuenta",
  SliderBasisInfo:
    "Utilice el servicio para una evaluación integral de sus socios y competidores.. Descubra cuáles son fiables en el cumplimiento de sus obligaciones en la esfera financiera, contractual y judicial.",
  SliderNewsInfo:
    "Tenga en cuenta los desarrollos en la industria, en Rusia y en el mundo. Personalice su propio feed de noticias y lea solo aquellas que le interesen.",
  SliderPriceInfo:
    "Elija contratos para justificar su precio máximo inicial en segundos. Analice los precios de bienes y servicios para hacer su negocio más competitivo.",
  SliderProInfo:
    "El servicio de inteligencia artificial para ganar licitaciones. Da recomendaciones personales para todos sobre la base del perfil de actividades y oportunidades; analiza clientes, competidores; evalúa las posibilidades de ganar.",
  Snap: "Vincular",
  SnapDigitalSignature: "Vincular la firma digital",
  StartLicense: "desde el",
  StartUsing: "Empezar a usar",
  Subscribe: "Suscríbase ahora",
  SuccessRegister_Mail: "Se ha registrado con éxito en el sistema {0}.",
  SupportMailLink:
    'Si tiene alguna pregunta, haga clic en {0}"Más información"{1}, y nuestro especialista le ayudará.',
  SystemLanguage: "Idioma del sistema",
  Text403:
    "No puedes ver esta página. Póngase en contacto con el soporte si cree que se ha producido un error.",
  Text404:
    "Es posible que haya cometido un error al escribir el enlace. {0} Intente comenzar desde {1} página principal {2}",
  Text500:
    "Lo sentimos, pero el servicio no está disponible todavía. Vuelva a intentarlo o vaya a la página principal {0} {1}.",
  TillDate: "hasta {0}",
  TimeLeft: "Quedan {0} segundos",
  Title403: "Acceso denegado",
  Title404: "Página no encontrada",
  Title500: "Ha ocurrido un error.",
  ToSaveTime: "Ahorrar tiempo",
  ToSaveTimeDescription:
    "Toda la información está en un solo lugar y actualizada en tiempo real. No necesita buscar nuevas adquisiciones, verificar la transparencia de procedimientos, analizar clientes. Ahora las ofertas que se adaptan a usted ya están a la mano.",
  Total: "Total:{0}",
  Transactions: "Transacciones",
  TryOtherProducts: "Prueba nuestros otros productos.",
  UploadNewPhoto: "Subir otra foto",
  UserNotFound: "Usuario no encontrado",
  WeNotFind: "Nada encontrado, intente {0}buscar en {1}",
  WeSentCodeConfirm:
    "Le enviamos el código al número de teléfono, confirme la recepción:",
  WrongOldPassword: "Contraseña incorrecta.",
  WrongPassword: "Contraseña actual incorrecta, inténtelo de nuevo.",
  YouCanFindInfoInBasis:
    "Siempre puede encontrar {0}información en Seldon Basis{1}",
  YourLogin: "Su nombre de usuario"
};
export default dictionary;
