// Library
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "classnames";
import styled from "styled-components";

// Image
import slide1 from "@images/landing/main-slider-1.png";
import slide2 from "@images/landing/main-slider-2.png";
import slide3 from "@images/landing/main-slider-3.png";

// UI
import ImgWrap from "@ui/ImgWrap";

const Wrapper = styled.div`
  .swiper-slide-active {
    transition: 1s;
  }
`;

const SliderMain = ({ className }) => {
  const classnames = classes([className]);
  return (
    <Wrapper className={classnames}>
      <Swiper
        autoplay={{
          delay: 5000
        }}
        spaceBetween={50}
        slidesPerView={1}
        fadeEffect={{
          crossFade: true
        }}
        effect="fade"
        loop={true}
      >
        <SwiperSlide>
          <ImgWrap classImg="img-fluid" src={slide1} />
        </SwiperSlide>
        <SwiperSlide>
          <ImgWrap classImg="img-fluid" src={slide2} />
        </SwiperSlide>
        <SwiperSlide>
          <ImgWrap classImg="img-fluid" src={slide3} />
        </SwiperSlide>
      </Swiper>
    </Wrapper>
  );
};

export default SliderMain;
