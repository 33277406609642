// Library
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import styled from "styled-components";

// Image
import paymentIcon1 from "@images/landing/payment-icon-1.png";
import paymentIcon2 from "@images/landing/payment-icon-2.png";
import paymentIcon3 from "@images/landing/payment-icon-3.png";

// Validation
import ValidationSchema from "./validation-schema";

// UI
import Input from "@ui/Input";
import Button from "@ui/Button";
import RadioPaymentMethod from "@ui/RadioPaymentMethod";

// Component
import PaymentCaptcha from "../../container/payment-recaptcha-container";

// Tool
import gridSize from "@tools/grid-size";

const Wrapper = styled.div.attrs(() => gridSize)`
  .btn-warning-active {
    box-shadow: 0px 7px 10px -7px var(--bs-warning);
    transition: 0.3s;
  }
  .block-1__price {
    font-family: "Rubik", sans-serif;
  }
  @media (max-width: ${props => props.sm}) {
    .pay-input-description {
      font-size: 12px;
      line-height: 15.6px;
    }
    .pay-btn-description {
      font-size: 12px;
      line-height: 15.6px;
    }
    p {
      font-size: 14px;
    }
    .block-1__price {
      font-size: 24px;
      div {
        font-size: 36px;
        line-height: 52px;
      }
    }
    .btn-warning {
      width: 100%;
    }
  }
  @media (min-width: ${props => props.sm}) and (max-width: ${props =>
      props.xl}) {
    .pay-input-description {
      font-size: 16px;
      line-height: 20.8px;
    }
    .pay-btn-description {
      font-size: 14px;
      line-height: 18.2px;
    }
    .block-1__price {
      font-size: 18px;
      line-height: 21.33px;
      div {
        font-size: 48px;
        line-height: 56.88px;
      }
    }
    input[name="email"] {
      width: 280px;
    }
  }
  @media (min-width: ${props => props.xl}) {
    .pay-input-description {
      font-size: 14px;
      line-height: 18.2px;
    }
    .pay-btn-description {
      font-size: 14px;
      line-height: 18.2px;
    }
    .block-1__price {
      font-size: 36px;
    }
    input {
      width: 362px;
    }
  }
`;

const initialValues = {
  id: 0,
  auth: false,
  rubPrice: 0,
  method: 1,
  duration: 0,
  email: "",
  productId: 2,
  desc: "Колготки в сетку и вертолет"
};

const enumDate = new Map([
  [1, " на день"],
  [2, " на неделю"],
  [3, " на месяц"]
]);

const enumDesc = new Map([
  [2, "Полный доступ ко всем возможностям Торговой площадки РСПП"],
  [3, "Полный доступ ко всем возможностям Ситуационного центра РСПП"]
]);

const PaymentForm = ({ tariff, submit }) => {
  const [data, setData] = useState(initialValues);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        ...tariff
      }}
      validationSchema={!tariff.auth ? ValidationSchema : null}
      onSubmit={(values, actions) => submit(values, actions)}
      render={({ values, errors, setFieldValue, isSubmitting }) => (
        <Form>
          <Wrapper>
            <p className="mb-1">
              {/* {`${values.desc} `} */}
              {enumDesc.get(values.productId)}
              <b className="text-black">{enumDate.get(values.duration)}</b>
            </p>
            {/* Email */}
            {!values.auth ? (
              <div className="py-md-2 d-md-flex align-items-center">
                <div>
                  <Field
                    name="email"
                    render={({
                      field,
                      form: { isSubmitting, errors, touched }
                    }) => (
                      <Input
                        {...field}
                        classNameInput="border-0 my-1 my-md-0 bg-light fs-16"
                        value={values.email}
                        size="lg"
                        error={errors[field.name]}
                        touched={touched[field.name]}
                        disabled={isSubmitting}
                        placeholder="E-mail"
                      />
                    )}
                  />
                </div>
                <p className="ml-md-3 pay-input-description">
                  На этот e-mail пришлем данные{" "}
                  <br className="d-none d-sm-block" />
                  для входа в личный кабинет
                </p>
              </div>
            ) : null}
            <hr className="mt-4 mt-md-1" />
            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center py-1">
              <div className="block-1__price fw-bold d-flex flex-row flex-md-column flex-lg-row mb-3 mb-md-0">
                {`Итог:`}
                <div className="ml-2 ml-md-0 ml-lg-2">
                  {(+values.rubPrice).toLocaleString("ru-RU")} ₽
                </div>
              </div>
              <div className="d-flex">
                <RadioPaymentMethod
                  className="col-4 pr-1 pr-lg-2"
                  active={values.method === 1}
                  onClick={() => {
                    setFieldValue("method", 1);
                  }}
                  name="method"
                  value={1}
                  image={paymentIcon1}
                />
                <RadioPaymentMethod
                  className="col-4 pr-1 pr-lg-2"
                  active={values.method === 2}
                  onClick={() => {
                    setFieldValue("method", 2);
                  }}
                  name="method"
                  value={2}
                  image={paymentIcon2}
                />
                <RadioPaymentMethod
                  className="col-4"
                  active={values.method === 3}
                  onClick={() => {
                    setFieldValue("method", 3);
                  }}
                  name="method"
                  value={3}
                  image={paymentIcon3}
                />
              </div>
            </div>
            <hr />
            <div className="pt-2 d-flex flex-column flex-md-row align-items-center">
              <Button
                type="submit"
                className="btn btn-lg btn-warning text-white text-nowrap"
                style={{ minWidth: "259px" }}
                disabled={isSubmitting} ////Перейти к оплате
              >
                Перейти к оплате
              </Button>
              <p className="pay-btn-description ml-md-3 mt-2 mt-md-0">
                Нажимая «Перейти к оплате», вы подтверждаете, что ознакомились и
                принимаете условия {` `}
                <a className="link-primary" href={`/docs/offer.docx`}>
                  Оферты
                </a>
                {` и `}
                <a className="link-primary" href={`/docs/policy.docx`}>
                  Политики конфиденциальности
                </a>
              </p>
              <div>
                {errors.api && (
                  <small className="text-danger">{errors.api}</small>
                )}
              </div>
            </div>
            <div className="mt-3">
              <PaymentCaptcha />
            </div>
          </Wrapper>
        </Form>
      )}
    />
  );
};

export default React.memo(PaymentForm);
