// Library
import React from "react";
import { useMemo } from "react";
import { Formik, Form, Field } from "formik";

// UI
import Input from "@ui/Input";
import Button from "@ui/Button";
import { from } from "rxjs";

const TestKassa = ({
  confirmPayment,
  cancelPayment,
  returnToMagazine,
  location
}) => {
  const guid = useMemo(() => {
    let params = new URLSearchParams(location.search.toLowerCase());
    let param = params.get("guid");
    console.log(param);
    return param;
  }, [location]);

  return (
    <>
      <h1>Тестовый стенд Яндекс.кассы</h1>
      <Button
        className="w-100 btn btn-lg btn-success text-white px-0"
        onClick={() => confirmPayment(guid)}
      >
        Подтвердить платеж
      </Button>
      <Button
        className="w-100 btn btn-lg btn-danger text-white px-0"
        onClick={() => cancelPayment(guid)}
      >
        Отменить платеж
      </Button>
      <Button
        className="w-100 btn btn-lg btn-dark text-white px-0"
        // type="submit"
        onClick={() => returnToMagazine(guid)}
      >
        Назад в магазин
      </Button>
    </>
  );
};

export default TestKassa;
