import * as authStorage from "./auth-storage";

const REQUESTING_SHARED_CREDENTIALS = "SOD_REQUESTING_SHARED_CREDENTIALS";
const CREDENTIALS_SHARING = "SOD_CREDENTIALS_SHARING";
const CREDENTIALS_FLUSH = "SOD_CREDENTIALS_FLUSH";

//https://medium.com/@marciomariani/sharing-sessionstorage-between-tabs-5b6f42c6348c
export const subscribeOnAuth = () => {
  window.addEventListener("storage", event => {
    const credentials = authStorage.getAuthToken(true);
    if (event.key === REQUESTING_SHARED_CREDENTIALS && credentials) {
      raiseEvent(CREDENTIALS_SHARING, credentials);
    }
    if (event.key === CREDENTIALS_SHARING && !credentials) {
      authStorage.putAuthToken(event.newValue);
    }
    if (event.key === CREDENTIALS_FLUSH && credentials) {
      authStorage.removeAuthToken();
    }
  });
};

export const raiseAuthRemove = () => raiseEvent(CREDENTIALS_FLUSH);
export const raiseAuthRequest = () => raiseEvent(REQUESTING_SHARED_CREDENTIALS);
export const raiseAuthToken = token => raiseEvent(CREDENTIALS_SHARING, token);

const raiseEvent = (eventName, value) => {
  localStorage.setItem(eventName, value ? value : Date.now().toString());
  localStorage.removeItem(eventName);
};
