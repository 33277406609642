const dictionary = {
  " ActiveLicenses": "تراخيص",
  " Apply": "قبول",
  " GetAccess": "إتاحة الوصول",
  " IncorrectPassword":
    "كلمة المرور المقدمة غير صحيحة. الرجاء إدخال كلمة المرور الصحيحة.",
  AccessDay: "1 يوم",
  AccessHalfYear: "على {0}",
  AccessMonth: "شهور",
  AccessThreeMonth: "شهور",
  AccessWeek: "على {0}",
  AccessYear: "{0}",
  AccountWithTheSameDSAlreadyExist: "{0} قيد",
  AccountWithTheSameEmailAlreadyExist: "{0} قيد",
  Activate: "تفعيل",
  ActivationTextBasis1: "الإنتماء",
  ActivationTextBasis2: "تفاصيل التسجيل",
  ActivationTextBasis3: "الالتزامات، {0}",
  ActivationTextBasis4: "الميزانية العمومية",
  ActivationTextControl1: "ابقى على اطلاع",
  ActivationTextControl2: "شركات",
  ActivationTextControl3: "{1} {2} عقود",
  ActivationTextNews1: "المعلومات",
  ActivationTextNews2:
    "بإمكانك الحصول على معلومات عن الكيانات القانونية ورجال الأعمال من مصادر رسمية",
  ActivationTextNews3: "اليوم",
  ActivationTextPrice1: "القيمة",
  ActivationTextPrice2: "أدخل البريد الإلكتروني الذي استخدمته عند التسجيل",
  ActivationTextPrice3: "سعر",
  ActivationTextPro1: "ابقى على اطلاع",
  ActivationTextPro3: "وضع مالي",
  ActivationTextPro4: "الشركات ",
  ActivationTitleBasis1: "الإنتماء",
  ActivationTitleBasis2: "خلاصة من EGRUL بـPDF.",
  ActivationTitleBasis3: "المزودين",
  ActivationTitleBasis4: "تحليل سريع",
  ActivationTitleControl1: "{0} مقابل {1}",
  ActivationTitleControl2: "تحليل",
  ActivationTitleNews1: "الشركات ",
  ActivationTitleNews3: "تحليل",
  ActivationTitlePro1: "تحليل",
  ActivationTitlePro2: "التوقع",
  ActivationTitlePro3:
    "مؤشر الموثوقية، هو تقييم لموثوقية الشركة التجارية النشطة.",
  ActivationTitlePro4: "إمكانيات",
  ActiveFrom: "منذ {0}",
  ActiveLicense: "{0} رخصة",
  ActiveLicenseCountFew: "تراخيص",
  ActiveLicenseCountMany: "التراخيص",
  ActiveLicenseCountOne: "{0} رخصة",
  ActualTill: "صالح حتى {0}",
  AddRemoveRegion: "إضافة",
  AdminPasswordReset: "كلمة المرور الجديدة",
  AlreadyRegistered: "هل يوجد لديك تسجيل؟ {0}قم بالدخول إلى النظام{1}.",
  AtLeastThanEightSymbols: "أقل من {0}",
  AuthorizeWithDS: "تفويض",
  Balance: "رصيد",
  BasisRegistrationTitle: "يمكن التحقق من %1$s %2$s",
  BasisTariffDescription1: "إمكانيات",
  BasisTariffDescription2: "الالتزامات، {0}",
  BasisTariffDescription3: "شركات",
  BasisTariffDescription4: "النشاطات",
  BasisTariffTitle1: "بحث",
  BasisTariffTitle2: "المخاطر",
  BasisTariffTitle3: "{0} حدث",
  BasisTariffTitle4: "بحث",
  Bonuses: "إحصل على منح",
  BuySubscription: "شراء إشتراك",
  CanSendIn: "إرسال",
  ChangeData: "تغيير",
  ChangePassword: "تغيير كلمة المرور",
  ChangingEmail_SendToNewEmail: "مع فائق الإحترام، {0} فريق {1}",
  ChangingEmail_SendToNewEmailTitle: "تأكيد",
  ChangingEmail_SendToOldEmail: "مع فائق الإحترام، {0} فريق {1}",
  ChangingPassword: "مع فائق الإحترام، {0} فريق {1}",
  ChangingPasswordForAdmin: "كلمة المرور الجديدة",
  CheckEmailRecoverPassword: "إسترداد",
  ChooseFile: "إختيار الملف",
  ChooseStrongPassword: "أدخل البريد الإلكتروني الذي استخدمته عند التسجيل",
  CommonSettings: "إعدادات",
  CommonSignIn: "تفويض",
  CommonSignUp: "تسجيل",
  Company: "شركة",
  Confirm: "تأكيد",
  ConfirmActivationTitle: "هل يوجد لديك تسجيل؟ {0}قم بالدخول إلى النظام{1}.",
  ConfirmLicenseCreated_Mail: "مستخدم",
  ConfirmLicenseUpdated_Mail: "مستخدم",
  ConfirmRegister: "تأكيد تسجيل المستخدم الجديد",
  ConfirmRegister_Mail: "مستخدم",
  Consult: "أرسل طلب إذا كنت تحتاج إلى استشارة. سنتصل بك في أقرب وقت ممكن.",
  ControlMailDescription: "{0} مخالفة",
  ControlTariffDescription1: "{} منظمة/منظمات",
  ControlTariffDescription2: "المخاطر",
  ControlTariffTitle1: "النشاطات",
  CurrentPassword: "كلمة المرور القديمة",
  CurrentSubsriptions: "حالي",
  DataProtected: "البيانات محمية حسب المعايير العالمية PCI DSS",
  Day: "1 يوم",
  DearUser: "مستخدم",
  Demo: "الوصول التجريبي",
  DemoAccess: "الوصول التجريبي",
  DemoAccessDay: "الوصول التجريبي",
  DemoAccessFewDays: "الوصول التجريبي",
  DemoAccessManyDays: "الوصول التجريبي",
  Department: "أقسام البنك المركزي في الاتحاد الروسي",
  DifferenceInExchangeRates: "المجموع",
  DigitalSignatureAndCertificates: "و",
  DigitalSignatureExpired: "مدة صلاحية",
  DigitalSignatureWithdrawn: "أدخل البريد الإلكتروني الذي استخدمته عند التسجيل",
  Edit: "تغيير",
  Email: "بريد إلكتروني",
  EmailAlreadyExist: "على {0}",
  EmailOrPhone: "بريد إلكتروني",
  EmailSignature: "فريق Seldon.Basis",
  EndLicense: "إلى",
  EnterCode: "أدخل",
  EnterCodeConfirmPhone: "رسائل",
  EnterEmailOrPhoneNumber: "تم تغيير عنوان البريد الاكتروني.",
  EnterMailOrPhone: "تم تغيير عنوان البريد الاكتروني.",
  EnterYourEmail: "أدخل بريدك الإلكتروني",
  ExceededRegisterPromo: "رقم",
  FewDays: "يومان",
  Finances: "التمويل",
  FirstName: "اسم",
  ForgotPassword: "هل نسيت كلمة المرور؟",
  ForgotPasswordError: "قيود",
  FreeAccessDayProduct: "1 يوم",
  FreeAccessFewDays: "يومان",
  FreeAccessFewDaysProduct: "يومان",
  FreeAccessManyDays: "أيام ",
  FreeAccessManyDaysProduct: "أيام ",
  FreeAccessOneDay: "1 يوم",
  FreeAccessProduct: "وصول مجاني للنظام {0}",
  FutureLicense: "{0} رخصة",
  FutureSubscribeOne: "الإشتراك",
  FutureSubscribesFew: "المزيد",
  FutureSubscribesMany: "المزيد",
  Gender: ":جنس",
  GenderFemale: "مؤنث",
  GenderMale: "مذكر",
  GetAccessToInfo: "معلومات",
  GetDemoAccess: "إتاحة الوصول التجريبي",
  GetProfit: "الربح",
  GetProfitDescription: "إمكانيات",
  HavePromo: "الرمز الترويجي",
  IncorrectEmailEntered: "غير صحيح {0}",
  IncorrectPassword:
    "كلمة المرور المقدمة غير صحيحة. الرجاء إدخال كلمة المرور الصحيحة.",
  IncorrectPasswordEntered: "غير صحيح {0}",
  IncorrectPhoneEntered: "غير صحيح {0}",
  LastName: "اسم العائلة",
  LicenseAgreement: "اتفاقية الترخيص",
  LicenseCountFew: "تراخيص",
  LicenseCountMany: "التراخيص",
  LicenseCountOne: "{0} رخصة",
  LinkHasExpired: "إعادة تعيين كلمة مرور",
  Login: "اسم التسجيل",
  LoginPageHeader: "تسجيل الدخول",
  LoginToAccess: "اسم التسجيل",
  LogOff: "خروج",
  ManageRisksDescription: "العملاء",
  ManyDays: "أيام ",
  NewPassword: "كلمة المرور الجديدة",
  NewsMailDescription: "اليوم",
  NewsRegistrationTitle: "الشركات ",
  NoActiveLicense: "التراخيص",
  NotFound: "لم يتم إيجاد أي شيء",
  NoTransactions: "لا",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN",
  OnDay: "وصول كامل لمدة 31 يوم",
  OnFewDays: "يومان",
  OnlinePayment: "على الانترنت",
  OnManyDays: "أيام ",
  Password: "كلمة المرور",
  PasswordChanged: "كلمة المرور",
  PasswordHasAPartOfLoginName: "كلمة المرور",
  PasswordMail: "كلمة المرور",
  PasswordMustBeAtLeast8Symbols: "كلمة المرور",
  PasswordMustContainsLettersAndDigits: "كلمة المرور",
  PasswordNotUpToDate: "كلمة المرور",
  PasswordRequirementsError: "كلمة المرور",
  PasswordsMismatch: "كلمة المرور الجديدة",
  PaymentButton: "الذهاب للدفع",
  PaymentMethod: "طريقة الدفع:",
  PaymentText_Mail: "دفعة",
  PersonalInfo: "المعلومات الشخصية",
  Phone: "رقم الهاتف",
  PhoneNumberAlreadyExist: "على {0}",
  PhoneOrEmailBusy: "مستخدم",
  PlatformsWithContracts: "منذ {0}",
  PleaseFillRequirementFields: "كل",
  Position: "منصب",
  PriceMailDescription: "{1} {2} عقود",
  PriceTariffDescription1: "متوافق مع",
  PriceTariffDescription2: "عقود",
  PriceTariffDescription3: "عامل مضاعف",
  PriceTariffTitle2: "الموردين",
  PriceTariffTitle3: "الموردين",
  PrivacyPolicy: "السياسة",
  PrivacyPolicyAndOffer: "الذهاب للدفع",
  PrivatePolicyRegister: "البيانات الشخصية",
  ProductCountFew: "على {0}",
  ProductCountMany: "على {0}",
  ProductCountOne: "منتجات",
  Prolong: "تمديد الإشتراك",
  ProMailDescription: "منذ {0}",
  PromoExpired: "مدة صلاحية",
  PublicOffer:
    "هذا العرض العام (من الآن فصاعدا العرض) وفقا للبند 2 من المادة 437 من القانون المدني للاتحاد الروسي يعتبر عرضا رسميا لعقد اتفاق رخصة ثانوية مع أي شخصية اعتبارية، أو شخص عادي، أو صاحب مشروع فردي (من الآن فصاعدا المستخدم) وفقا للشروط المنصوص عليها في هذا العرض. هذا الاتفاق يعقد فقط بالانضمام إليه كاملا بقبول العرض من قبل من المستخدم. قبول العرض يعتبر دفع رسوم الترخيص من قبل المستخدم. ويعتبر هذا الاتفاق قد عقد كتابة، ومساويا لعقد موقع من الطرفين.",
  PurchasingRegions: "الأقاليم",
  RecoveryPassword: "إعادة تعيين كلمة مرور",
  RecoveryPassword_Mail: "مستخدم",
  RecoveryPassword2_Mail: "كلمة المرور",
  Refill: "شحن رصيد حسابك",
  Register: "تسجيل",
  RegisterToStart: "سجل الآن!",
  RegistrationСompleted: "تسجيل",
  RememberMe: "تذكر",
  RenewSubscription: "تمديد الإشتراك",
  RepeatNewPassword: "تأكيد كلمة المرور الجديدة",
  Resend: "إرسال",
  Restore: "إسترداد",
  Save: "حفظ",
  SavePasswordSuccess: "كلمة المرور",
  SavePolicy: "حفظ",
  SaveUnavailable: "حفظ...",
  Search: "بحث",
  SearchInBasis: "معلومات شخصية:",
  SelectedRegions: "الأقاليم",
  SelectRegion: "منطقة",
  Send: "إرسال",
  SendAgain: "إرسال",
  SendCode: "رمز",
  SendPasswordOrCreateNew: "رقم الهاتف",
  SendSmsWithPassword: "سيتم إرسال رسالة نصية إلى هذا الرقم متضمة كلمة المرور",
  SendToNumber: "رقم",
  Settings: "إعدادات",
  Show: "المعرض",
  SignIn: "دخول",
  SignInSystem: "هل يوجد لديك تسجيل؟ {0}قم بالدخول إلى النظام{1}.",
  SignUpWithDS: "تسجيل",
  SincerelyTeam: "مع فائق الإحترام، {0} فريق {1}",
  SliderBasisInfo: "الالتزامات، {0}",
  SliderNewsInfo: "ابقى على اطلاع",
  SliderPriceInfo: "عقود",
  SliderProInfo: "منذ {0}",
  StartLicense: "منذ {0}",
  StartUsing: "البدء في استخدام البرنامج",
  Subscribe: "فشل عملية شراء الإشتراك. حدث خطأ. \\n\\n%s",
  SupportMailLink: "مزيدا",
  SystemLanguage: "لغة النظام:",
  Text403: "حدث خطأ",
  Text404: "الشركات",
  Text500: "اذهب إلى الصفحة الرئيسية",
  TillDate: "إلى",
  Title404: "لم يتم إيجاد الصفحة",
  Title500: "حدث خطأ",
  ToSaveTimeDescription: "شفافية الإجراءات",
  Total: "المجموع: {0}",
  TryOtherProducts: "الشركات",
  UploadNewPhoto: "إضافة الصورة الأخرى",
  UserNotFound: "مستخدم",
  WeNotFind: "الشركات",
  WeSentCodeConfirm: "رقم الهاتف",
  WrongOldPassword: "كلمة المرور",
  WrongPassword: "كلمة المرور الحالية",
  YouCanFindInfoInBasis: "بحث",
  YourLogin: "اسم التسجيل"
};
export default dictionary;
