// Library
import React, { Component } from "react";
import localization from "localization";

// Component
import LangList from "../LangList";

class LangListWrapper extends Component {
  getLocation = lang =>
    this.props.match.url.replace(
      `/${this.props.match.params.lang}/`,
      `/${lang}/`
    );

  changeLang = lang => {
    let newLang = lang;

    localization.setLanguage(newLang, lang => {
      this.props.updateLang(lang);
      this.props.history.push(this.getLocation(lang));
    });
  };

  render() {
    return (
      <LangList currentLang={this.props.lang} onChange={this.changeLang} />
    );
  }
}

export default LangListWrapper;
