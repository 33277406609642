import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers";
import thunk from "redux-thunk";

const composeEnhancers = composeWithDevTools({});

const storeFactory = () =>
  createStore(reducers, composeEnhancers(applyMiddleware(...[thunk])));

export default storeFactory;
