// Library
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { history } from "react-router-dom";

// Image
import logo from "@images/landing/logo.svg";
import paymentBottomIcon from "@images/landing/payment-bottom-icons.png";

// UI
import ImgWrap from "@ui/ImgWrap";

// Component
import PaymentFrom from "../PaymentForm";
import paymentDescription from "./paymentDescription";

// Tool
import gridSize from "@tools/grid-size";

const Wrapper = styled.div.attrs(() => gridSize)`
  font-family: "Roboto", sans-serif;
  .row {
    height: 100vh;
    .block-1,
    .block-2 {
      @media (min-width: ${props => props.lg}) {
        padding-top: 128px !important;
      }
    }
    .block-1 {
      input::placeholder {
        color: var(--bs-secondary);
      }
      &__container {
        max-width: 901px;
      }
    }

    .block-2 {
      background: linear-gradient(0deg, #fafafa, #fafafa);
      &__container {
        @media (min-width: ${props => props.xl}) {
          max-width: 475px;
        }
        line-height: 20.8px;
      }
    }

    @media (max-width: ${props => props.sm}) {
      .logo {
        max-width: 127px;
      }
      h2 {
        font-size: 36px;
      }
    }
    @media (max-width: ${props => props.md}) {
      .logo {
        max-width: 108px;
      }
    }
    @media (min-width: ${props => props.xl}) {
      .logo {
        max-width: 154px;
      }
    }
  }
`;

const Payment = ({
  // productId = 0,
  getTariff,
  checkAuth,
  createPayment,
  checkPayment,
  setSuccessModalShow,
  setErrorModalShow,
  recaptcha = "",
  match: {
    params: { id, productId }
  },
  history,
  location
}) => {
  const [tariff, setTariff] = useState({});
  const [payResult, setPayResult] = useState(null);
  const textDescription = useMemo(() => paymentDescription.get(+productId), [
    productId
  ]);

  useEffect(() => {
    let params = new URLSearchParams(location.search.toLowerCase());
    let guid = params.get("guid");

    window.scrollTo(0, 0);

    if (guid) {
      checkPayment(guid)
        .then(res => {
          if (res)
            setSuccessModalShow({
              show: true,
              title: "Успешно!",
              text: "Теперь вы можете пользоваться всеми возможностями сервиса."
            });

          if (!res)
            setErrorModalShow({
              show: true,
              title: "Операция не прошла",
              text:
                "Произошла ошибка. Попробуйте повторить оплату через некоторое время."
            });

          setPayResult(!!res);
        })
        .catch(() => {
          setErrorModalShow({
            show: true,
            title: "Операция не прошла",
            text:
              "Произошла ошибка. Попробуйте повторить оплату через некоторое время."
          });
          setPayResult(false);
        });
    }

    Promise.all([getTariff(id), checkAuth()]).then(([res1, res2]) => {
      setTariff({ ...res1, auth: res2 });
    });
  }, []);

  return (
    <Wrapper className="container-fluid">
      <div className="row">
        {/* Left Block */}
        <div className="col-xl-8 block-1 d-md-flex justify-content-end pr-lg-5">
          <div className="block-1__container d-flex flex-column px-md-3 px-lg-0 pt-2 pt-md-4 pt-lg-0 mr-md-0">
            <div className="d-flex flex-column flex-md-row align-items-md-center mb-1">
              <h2 className="text-black order-2 order-md-1 mt-1 mt-md-0 mb-0">
                <b>Онлайн-оплата</b>
              </h2>
              <a
                className="ml-md-3 order-1 order-md-2"
                href="https://rspp-centre.com"
              >
                <ImgWrap classImg="logo" src={logo} alt="" />
              </a>
            </div>

            {payResult === null ? (
              <PaymentFrom
                tariff={tariff}
                submit={(values, actions) =>
                  createPayment(
                    { ...values, recaptcha },
                    actions,
                    history,
                    location.pathname
                  ).then(res => {
                    if (res && res.success) {
                      actions.resetForm();
                    }
                  })
                }
              />
            ) : (
              <div>{payResult ? "Платеж получен!" : "Платеж отменен!"}</div>
            )}

            <p className="fs-12 text-secondary mt-auto mt-lg-0 pt-4 pt-md-5 pt-lg-3 ">
              Публичная оферта (далее Оферта) согласно п. 2 ст. 437 Гражданского
              кодекса Российской Федерации является официальным предложением
              заключить с любым юридическим, физическим лицом, индивидуальным
              предпринимателем (далее Пользователь) договор на условиях,
              предусмотренных Офертой. Договор заключается не иначе как путем
              присоединения к нему в целом путем акцепта. Акцептом Оферты
              является уплата Пользователем лицензионного вознаграждения.
              Договор при этом считается заключенным в письменной форме и
              является равносильным договору, подписанному двумя сторонами.
            </p>

            <div className="mt-3 d-md-flex align-items-center mb-4 mb-lf-0">
              <ImgWrap src={paymentBottomIcon} />
              <p className="fs-13 mt-2 mt-md-0 ml-md-2 text-secondary">
                Данные защищены по международному стандарту PCI DSS
              </p>
            </div>
          </div>
        </div>
        {/* Right Block */}
        <div className="col-xl-4 block-2 pl-sm-2 d-none d-xl-block">
          <div className="block-2__container pl-4">
            <h4 className="text-black">
              <b>После оплаты вы сможете:</b>
            </h4>
            <div className="pt-4">{textDescription}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default React.memo(Payment);
