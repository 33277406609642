// Landing
import React from "react";
import styled from "styled-components";

// Image
import bgMain from "@images/landing/bg-slider-main.jpg";
import icon1 from "@images/landing/slider-main-icon-1.svg";
import icon2 from "@images/landing/slider-main-icon-2.svg";
import icon3 from "@images/landing/slider-main-icon-3.svg";
import logo from "@images/landing/logo-dark.svg";

// UI
import ImgWrap from "@ui/ImgWrap";

// Component
import SliderMain from "./SliderMain";

const Wrapper = styled.div`
  background: url(${bgMain});
  background-size: cover;
`;

const LandingSliderMain = ({ toTestAccess }) => (
  <Wrapper className="py-4 py-sm-5 position-relative">
    <div className="container">
      <ImgWrap className="d-sm-none" src={logo} alt="" style={{ width: 239 }} />
      {/* Main block */}
      <div className="row mt-3 mt-sm-0 position-relative" style={{ zIndex: 1 }}>
        <div className="col-sm-6 col-xxl-7 d-flex flex-column justify-content-center text-white">
          <div className="mb-2">
            <span>Бизнесу</span>
            <span className="mx-1">|</span>
            <span>Государству</span>
          </div>
          <h2 className="text-white">
            <b>Аналитика. Прогнозы</b>
          </h2>
          <p
            className="mt-2 h5"
            style={{ lineHeight: "1.875rem", maxWidth: "748px" }}
          >
            Формируем «картину дня» по ключевым направлениям: ситуация с
            COVID-19, новостной фон, меры поддержки, деятельность компаний,
            государственные контракты, демографическая и социальная статистика.
            Все это — в разрезе по интересующим вас субъектам РФ.
          </p>
          <SliderMain className="d-sm-none mt-3" />
          <div>
            <div
              className="mt-4 d-flex d-sm-inline-block justify-content-center btn btn-lg btn-warning text-white"
              onClick={() => toTestAccess()}
            >
              Попробовать бесплатно
            </div>
          </div>
          <div className="row mt-4 mt-sm-5">
            <div className="col-sm-4 d-flex flex-row flex-sm-column">
              <ImgWrap
                className="d-flex align-items-center mr-2 mr-sm-0"
                src={icon1}
              />
              <div>
                <h5 className="text-white mt-sm-2 pt-sm-2">
                  <b>Актуальные данные</b>
                </h5>
                <p>
                  Экономика, здравоохранение, демография, социальная статистика
                </p>
              </div>
            </div>
            <div className="col-sm-4 d-flex flex-row flex-sm-column mt-3 mt-sm-0">
              <ImgWrap
                className="d-flex align-items-center mr-2 mr-sm-0"
                src={icon2}
              />
              <div>
                <h5 className="text-white mt-sm-2 pt-sm-2">
                  <b>Прогнозы ситуации</b>
                </h5>
                <p>
                  Получайте аналитику и прогнозы, чтобы быть наготове ко всем
                  изменениям
                </p>
              </div>
            </div>
            <div className="col-sm-4 d-flex flex-row flex-sm-column mt-3 mt-sm-0">
              <ImgWrap
                className="d-flex align-items-center mr-2 mr-sm-0"
                src={icon3}
              />
              <div>
                <h5 className="text-white mt-sm-2 pt-sm-2">
                  <b>Видеоэкраны, дашборды</b>
                </h5>
                <p>
                  А также звуковое сопровождение для быстрой и удобной работы
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="position-absolute d-none d-sm-flex align-items-center justify-content-end r-0 t-0 b-0"
      style={{ zIndex: 0, maxWidth: "974px" }}
    >
      <SliderMain className="col-sm-5 col-md-6 col-xl-9 col-xxl-11" />
    </div>
  </Wrapper>
);

export default LandingSliderMain;
