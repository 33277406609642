import * as types from "./action-types";
import axios from "axios";
import config from "config";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";

export function updateLang(lang) {
  return {
    type: types.UPDATE_LANG,
    lang
  };
}

export function loading(loading) {
  return {
    type: types.LOADING_SPINER,
    loading: loading
  };
}

export function setSuccessModalShow(successModalShow) {
  return {
    type: types.SUCCESS_MODAL_PROCESS,
    successModalShow
  };
}

export function setErrorModalShow(errorModalShow) {
  return {
    type: types.ERROR_MODAL_PROCESS,
    errorModalShow
  };
}

export const checkAuth = (lang, process = f => f) => async dispatch => {
  let action = bindActionCreators(
    {
      loading
    },
    dispatch
  );

  try {
    //showSpiner();
    action.loading(true);
    let isAuth = await axios.post(
      `${config.sulApi}/${lang}/account/CheckAuth`,
      null,
      {
        headers: {
          "Content-Type": "application/json"
        },
        withCredentials: true
      }
    );
    //hideSpiner();
    if (isAuth && isAuth.data === "True") {
      window.location.href = decodeURI(
        `${config.sulApi}/${lang}/user/Options?`
      );
    }
  } catch (e) {
    console.log(e);
  } finally {
    action.loading(false);
    process();
  }
};
