// Library
import React from "react";
import styled from "styled-components";

// Image
import mainBg from "@images/authorization/bg.jpg";
import { ReactComponent as Logo } from "@images/authorization/logo.svg";

import LangListContainer from "../../processes/global/containers/lang-list-container";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-image: url(${mainBg});
  background-size: cover;
  overflow: hidden;

  @media (min-width: 321px) {
    width: 100%;
    height: 100vh;
  }

  @media (max-width: 500px) {
    overflow: auto;
  }

  font-family: "Roboto", sans-serif;

  .description {
    max-width: 632px;
    line-height: 18.75px;
  }

  .form {
    width: 360px;
    margin: 0 0 auto;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(10, 10, 10, 0.08);
    box-shadow: 0 36px 90px #000000, 0px 36px 90px #000000;
    backdrop-filter: blur(12px);
    border-radius: 10px;

    @media (max-width: 320px) {
      width: 288px;
    }
  }

  .auth-logo {
    margin: 15vh 0 58px;
  }

  h1 {
    @media (max-width: 576px) {
      font-size: 1.125rem !important;
    }
  }
`;

const AuthorizationWrapper = ({ children }) => {
  return (
    <Wrapper>
      <Logo className="auth-logo" />

      <div className="form p-2 p-sm-3">{children}</div>

      <LangListContainer />
    </Wrapper>
  );
};

export default React.memo(AuthorizationWrapper);
