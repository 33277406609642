// Library
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { notify } from "../../../helpers/notifications";

// Component
import TestKassa from "../views/TestKassa";

// Action
import * as globalAction from "../../../processes-domain/global/global-actions";
import * as yookassaAction from "../../../processes-domain/yookassa/actions";

const partRoute = process.env.PART_ROUTE;

const mapStateToProps = store => {
  return {
    lang: store.globalState.lang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLang: bindActionCreators(globalAction, dispatch).updateLang,
    confirmPayment: async guid => {
      try {
        var res = await bindActionCreators(yookassaAction, dispatch).confirm({
          guid,
          isCanceled: false
        });

        notify.show({
          title: "Платеж успешно подтвержден. Нажмите Назад в магазин",
          type: "success"
        });

        //this.returnToMagazine(guid);
      } catch (e) {
        notify.show({
          title: "Платеж не удалось подтвердить.",
          message: "Ошибка - см. текст ошибки в Сети",
          type: "error"
        });
      }
    },
    cancelPayment: async guid => {
      try {
        var res = await bindActionCreators(yookassaAction, dispatch).confirm({
          guid,
          isCanceled: true
        });

        notify.show({
          title: "Платеж успешно отменен. Нажмите Назад в магазин",
          type: "success"
        });
        //if (res.success) console.log("URA2");
        //else console.log("WTF2");
      } catch (e) {
        notify.show({
          title: "Платеж не удалось отменить.",
          message: "Ошибка - см. текст ошибки в Сети",
          type: "error"
        });
      }
    },
    returnToMagazine: guid => (window.location.href = `/?guid=${guid}`) //Переход на страницу проверки результата http://localhost:3000/payment?guid=98b27e07-888e-4325-b5b7-e488b0262807
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TestKassa)
);
