// Library
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

// Component
import Header from "../LandindHeader";
import SliderMain from "../LandingSliderMain";
import SliderInfo from "../LandingSliderInfo";
import TestAccess from "../LandingTestAccess";
import InfoBlock from "../LandingInfoBlock";
import SliderWhy from "../LandingSliderWhy";
import SubscribeAnalytics from "../LandingSubscribeAnalytics";
import SubscribeMarketplace from "../LandingSubscribeMarketplace";
import Footer from "../LandingFooter";
import FormTestAccess from "../LandingTestAccessForm";

// Swiper
import SwiperCore, { Autoplay, EffectFade, Controller } from "swiper";

SwiperCore.use([Autoplay, EffectFade, Controller]);

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
`;

const Landing = ({
  setSuccessModalShow,
  setErrorModalShow,
  getAccessDemo,
  getLandingTariffs
}) => {
  const testAccessDarkRef = useRef(null);
  const testAccessLightRef = useRef(null);
  const subscribeAnalyticsRef = useRef(null);
  const subscribeMarketplaceRef = useRef(null);
  const [tariffs, setTariffs] = useState([]);

  useEffect(() => {
    getLandingTariffs().then(res => {
      if (res) setTariffs(res);
    });
  }, []);

  return (
    <>
      <Wrapper>
        <Header
          toTestAccess={() => testAccessDarkRef.current.scrollIntoView()}
          toSubscribeAnalytics={() =>
            subscribeAnalyticsRef.current.scrollIntoView()
          }
          toSubscribeMarketplace={() =>
            subscribeMarketplaceRef.current.scrollIntoView()
          }
        />
        <SliderMain
          toTestAccess={() => testAccessDarkRef.current.scrollIntoView()}
        />
        <SliderInfo />
        <TestAccess
          anchor="analytics"
          inputRef={testAccessDarkRef}
          theme="dark"
        >
          <FormTestAccess
            getAccessDemo={getAccessDemo}
            setSuccessModalShow={setSuccessModalShow}
            setErrorModalShow={setErrorModalShow}
          />
        </TestAccess>
        <SubscribeAnalytics
          inputRef={subscribeAnalyticsRef}
          tariffs={tariffs.find(item => item.productId === 3)}
        />
        <InfoBlock
          toTestAccess={() => testAccessLightRef.current.scrollIntoView()}
        />
        <SliderWhy />
        <TestAccess anchor="trade" inputRef={testAccessLightRef} theme="light">
          <FormTestAccess
            getAccessDemo={getAccessDemo}
            setSuccessModalShow={setSuccessModalShow}
            setErrorModalShow={setErrorModalShow}
          />
        </TestAccess>
        <SubscribeMarketplace
          inputRef={subscribeMarketplaceRef}
          tariffs={tariffs.find(item => item.productId === 2)}
        />
        <Footer
          toSubscribeAnalytics={() =>
            subscribeAnalyticsRef.current.scrollIntoView()
          }
          toSubscribeMarketplace={() =>
            subscribeMarketplaceRef.current.scrollIntoView()
          }
        />
      </Wrapper>
    </>
  );
};

export default Landing;
