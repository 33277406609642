import { combineReducers } from "redux";

// Reducers
import registerReducer from "../processes-domain/register/register-reducer";
//import accountReducer from './account-reducer'
import globalReducer from "../processes-domain/global/global-reducer";

// Combine Reducers
var reducers = combineReducers({
  registerState: registerReducer,
  //accountState:accountReducer,
  globalState: globalReducer
});

export default reducers;
