// Library
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  .slider-container {
    max-width: 900px;
    margin: auto;
    position: relative;
    &__top {
      max-width: 80%;
      position: relative;
      z-index: 2;
      margin: auto;
    }
    &__bottom {
      max-width: 900px;
      display: block;
      position: relative;
      top: -10px;
      z-index: 3;
    }
    &__laptop {
      position: relative;
      z-index: 2;
    }
  }
  .slide {
    background-size: cover !important;
    position: absolute;
    width: 96%;
    height: 90%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: -1;
    transition: 0.5s;
    &.slide-1 {
      left: -180%;
      opacity: 0.7;
      z-index: -1;
      filter: blur(3px);
      @media (max-width: 576px) {
        opacity: 0;
      }
    }
    &.slide-2 {
      opacity: 1;
      z-index: 1;
    }
    &.slide-3 {
      right: -180%;
      opacity: 0.7;
      z-index: -1;
      filter: blur(3px);
      @media (max-width: 576px) {
        opacity: 0;
      }
    }
  }
  .dots {
    .dot {
      background: var(--bs-secondary);
      opacity: 0.5;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      &_active {
        background: var(--main-text-body);
      }
    }
  }
  .swiper-container {
    .swiper-slide {
      &__block {
        position: relative;
        top: 0;
        transition: 0.3s;
        line-height: 20.8px;
        &.active {
          top: -3px;
        }
      }
    }
  }
  &.light {
    .slider-container__top {
      filter: drop-shadow(0px 8px 8px #b3b3b3);
      @media (max-width: 576px) {
        filter: drop-shadow(0px 2px 4px #b3b3b3);
      }
    }
    .slider-container__bottom {
      filter: drop-shadow(0px 8px 8px #b3b3b3);
      @media (max-width: 576px) {
        filter: drop-shadow(0px 2px 4px #b3b3b3);
      }
    }
    .decor {
      &__left {
        position: absolute;
        z-index: 3;
        &_1 {
          top: 10%;
          left: -13%;
          @media (max-width: 576px) {
            width: 10%;
          }
        }
        &_2 {
          top: 0;
          bottom: 0;
          margin: auto;
          left: -20%;
          z-index: -1;
          @media (max-width: 576px) {
            width: 23%;
          }
        }
        &_3 {
          bottom: 10%;
          left: -15%;
          @media (max-width: 576px) {
            width: 15%;
          }
        }
        &_4 {
          bottom: 25%;
          left: -18%;
          @media (max-width: 576px) {
            width: 5%;
          }
        }
      }
      &__right {
        position: absolute;
        &_1 {
          top: 0;
          right: -20%;
          @media (max-width: 576px) {
            width: 12%;
          }
        }
        &_2 {
          top: 0;
          bottom: 0;
          margin: auto;
          right: -17%;
          @media (max-width: 576px) {
            width: 20%;
          }
        }
        &_3 {
          bottom: 10%;
          right: -20%;
          @media (max-width: 576px) {
            width: 10%;
          }
        }
        &_4 {
          bottom: 6%;
          right: -20%;
          @media (max-width: 576px) {
            width: 5%;
          }
        }
        &_5 {
          top: 0;
          right: -23%;
          @media (max-width: 576px) {
            width: 5%;
          }
        }
      }
    }
  }
  &.dark {
    .decor {
      &__left {
        position: absolute;
        z-index: 3;
        &_1 {
          bottom: 0;
          left: -30%;
          @media (max-width: 576px) {
            width: 45%;
          }
        }
        &_2 {
          top: 0;
          left: -25%;
          @media (max-width: 576px) {
            width: 30%;
          }
        }
        &_3 {
          top: 13%;
          left: -9%;
          @media (max-width: 576px) {
            display: none;
          }
        }
        &_4 {
          top: 0;
          bottom: 0;
          left: -20%;
          margin: auto;
          @media (max-width: 576px) {
            display: none;
          }
        }
      }
      &__right {
        position: absolute;
        &_1 {
          top: 10%;
          right: -28%;
          z-index: -1;
          @media (max-width: 576px) {
            width: 50%;
          }
        }
        &_2 {
          bottom: 10%;
          right: -15%;
          @media (max-width: 576px) {
            width: 13%;
          }
        }
        &_3 {
          bottom: 25%;
          right: -20%;
          @media (max-width: 576px) {
            width: 7%;
          }
        }
      }
    }
  }
`;

export default Wrapper;
