// Library
import React from "react";
import classnames from "classnames";
import styled from "styled-components";

const WrapperCheckbox = styled.div`
  .form-switch {
    input:checked {
      background-color: var(--main-link-hover);
    }
  }
`;

const Checkbox = ({ className, label, ...props }) => {
  const classes = classnames([className, "form-check"]);

  return (
    <WrapperCheckbox>
      <div className={classes}>
        {label && <span className="form-check-label">{label}</span>}
        <input className="form-check-input" type="checkbox" {...props} />
      </div>
    </WrapperCheckbox>
  );
};

export default Checkbox;
