import config from "config";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import * as spinnerAction from "../processes-domain/spinner/actions";
import * as authAction from "../processes-domain/auth/actions";
import axios from "axios";
import assert from "../../helpers/assert";
import * as authStorage from "./auth-storage";
import * as authBrocker from "./auth-event-brocker";
import sleep from "@helpers/sleep";

//https://medium.com/@stasonmars/%D0%BF%D0%BE%D0%BB%D0%BD%D0%BE%D0%B5-%D0%BF%D0%BE%D0%BD%D0%B8%D0%BC%D0%B0%D0%BD%D0%B8%D0%B5-%D1%81%D0%B8%D0%BD%D1%85%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B8-%D0%B0%D1%81%D0%B8%D0%BD%D1%85%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-javascript-%D1%81-async-await-ba5f47f4436

export const post = async (
  url,
  payload,
  dispatch,
  getState,
  withAuth = true,
  withLoading = true,
  withRelogin = true,
  withCredentials = true,
  contentType = "application/json",
  responseType = ""
) => {
  let config = { method: "post", url, data: payload };
  let res = await invoke(
    config,
    dispatch,
    getState,
    withCredentials,
    withLoading,
    withAuth,
    withRelogin,
    contentType,
    responseType
  );
  return res;
};

export const get = async (
  url,
  params,
  dispatch,
  getState,
  withAuth = true,
  withLoading = true,
  withRelogin = true,
  withCredentials = true,
  contentType = "application/json",
  responseType = ""
) => {
  let config = { method: "get", url, params };
  let res = await invoke(
    config,
    dispatch,
    getState,
    withCredentials,
    withLoading,
    withAuth,
    withRelogin,
    contentType,
    responseType
  );
  return res;
};

const invoke = async (
  iniConfig,
  dispatch,
  getState,
  withCredentials,
  withLoading,
  withAuth,
  withRelogin,
  contentType,
  responseType
) => {
  assert(dispatch, "Не определен redux-диспетчер");
  assert(getState, "Не определено redux-хранилище");
  assert(iniConfig, "Не определен config для запроса");
  assert(invoke, "Не определен делегат для запроса");

  let config = { ...iniConfig };

  let action = bindActionCreators(
    {
      existsAuth: authAction.existsAuth,
      relogin: authAction.relogin,
      loading: spinnerAction.loading,
      forbidden: authAction.forbidden
    },
    dispatch
  );

  try {
    if (withLoading) action.loading(true);

    let headers = {
      "Content-Type": contentType
    };
    // if (withAuth) {
    //   let token = authStorage.getAuthToken(); //getState().loginState.token ||
    //   if (!token) {
    //     await sleep(200); //Ожидаем получение токена из другой вкладки
    //     token = authStorage.getAuthToken();
    //   }

    //   headers.Authorization = `Bearer ${token}`;
    // }

    config.headers = headers;
    config.withCredentials = withCredentials;

    if (responseType) config.responseType = responseType;

    let res = await axios(config).catch(ex => {
      if (
        ex.response &&
        (ex.response.status === 400 || ex.response.status === 401)
      ) {
        if (withAuth) {
          authStorage.removeAuthToken();
          authBrocker.raiseAuthRemove();
          action.existsAuth(false);
        }
        if (withRelogin) action.relogin(true);
      }

      if (ex.response && ex.response.status === 403) action.forbidden(true);

      return { status: ex.response ? ex.response.status : 500, data: null };
    });

    /*
        if (res.status === 200) {
            if (withAuth)
                action.existsAuth(true);
            if (withRelogin)
                action.relogin(false);
        }
        */

    let { status, data } = res;
    return { status, data, success: status === 200 || status === 204 };
  } catch (ex) {
    console.log(ex);
    /*if (ex.message === 'Request failed with status code 400') {
            if (withAuth)
                action.existsAuth(false);
            if (withRelogin)
                action.relogin(true);
            return { status: 400, data: null, success: false };
        }*/
    throw ex;
    //return { status: 400, data: null, success: false }
  } finally {
    if (withLoading) action.loading(false);
  }
};
