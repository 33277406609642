// Library
import config from "config";
import {
  get,
  post
} from "../../infrastructure/processes-domain/simple/actions";

export const confirm = (
  data = {
    guid: "2fc985ce-e754-43ae-8c1e-d845b7ef0a38",
    isCanceled: false
  }
) => post(`${config.api}/api/payment/confirm`, data);

// export function setSuccessModalShow(successModalShow) {
//   return {
//     type: types.SUCCESS_PROCESS,
//     successModalShow
//   };
// }

// export function setErrorModalShow(errorModalShow) {
//   return {
//     type: types.ERROR_PROCESS,
//     errorModalShow
//   };
// }
