import * as types from "./action-types";
import * as promoTypes from "../promo/action-types";
import promoReducer from "../promo/promo-reducer";
import * as initialTypes from "../initial/action-types";
import initialReducer from "../initial/initial-reducer";

const initialState = {
  phonePrefix: {
    value: 7,
    index: 171
  },
  recaptcha: "",
  userModel: null,
  errorConfirm: "",

  initial: undefined,
  promo: undefined
};

const registerReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_PHONE_PREFIX:
      return { ...state, phonePrefix: action.phonePrefix };
    case types.ADD_RECAPTCHA:
      return { ...state, recaptcha: action.recaptcha };
    case types.ADD_USER_MODEL:
      return { ...state, userModel: action.userModel };
    case types.ADD_ERROR_CONFIRM:
      return { ...state, errorConfirm: action.errorConfirm };
    case types.ADD_ERROR_REGISTER:
      return { ...state, errorRegister: action.errorRegister };
    //Initial
    case initialTypes.ADD_INITIAL_PRODUCT:
    case initialTypes.ADD_PARTNER_ID:
      return { ...state, initial: initialReducer(state.initial, action) };
    //Promo
    case promoTypes.DELETE_PROMOCODE:
    case promoTypes.ADD_PROMOCODE_ERROR:
    case promoTypes.DELETE_PROMOCODE_ERROR:
    case promoTypes.ADD_PROMOCODE:
    case promoTypes.ADD_PROMOCODE_RESPONSE:
      return { ...state, promo: promoReducer(state.promo, action) };
    default:
      return state;
  }
};

export default registerReducer;
