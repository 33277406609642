import Notifications from "../libs/notifications/notifications.esm";

// const defaultOptions = {
//   version,
//   type: "info",
//   once: true,
//   position: ["right", "bottom"],
//   duration: 5000,
//   maxItems: null,
//   progress: true,
//   progressOptions: {
//     position: "top",
//     color: "#737373",
//     easing: "linear"
//   },
//   animation: true,
//   animationOptions: {
//     duration: 1000,
//     animateIn: "fadeIn",
//     animateOut: "fadeOut"
//   },
//   addNotify: "auto",
//   styles: {
//     width: 300,
//     textAlign: "center",
//     boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.10)",
//     margin: 15,
//     padding: [15, 10],
//     borderRadius: 3,
//     zIndex: 999,
//     typeStyle: {
//       success: "#dff0d8",
//       warning: "#fcf8e3",
//       error: "#f2dede",
//       info: "#d9edf7"
//     }
//   },
//   className: {
//     main: "notify",
//     wrap: "notify-wrapper",
//     progress: "notify__progress"
//   },
//   templates: [
//     {
//       name: "title",
//       className: "notify__title",
//       tpl: `
//         <div class="{% class %}">
//           <b>{% title %}</b>
//         </div>
//       `
//     },
//     {
//       name: "message",
//       className: "notify__message",
//       tpl: `
//         <div class="{% class %}">
//           <span>{% message %}</span>
//         </div>
//       `
//     }
//   ]
// };

export const notify = new Notifications({ once: false });
