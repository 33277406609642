// Library
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Component
import ModalState from "./ModalState";
import * as globalAction from "../../processes-domain/global/global-actions";

const mapStateToProps = store => {
  return {
    successModalShow: store.globalState.successModalShow,
    errorModalShow: store.globalState.errorModalShow
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSuccessModalShow: bindActionCreators(globalAction, dispatch)
      .setSuccessModalShow,
    setErrorModalShow: bindActionCreators(globalAction, dispatch)
      .setErrorModalShow
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalState)
);
