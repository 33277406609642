// import store from 'store'
//import { bindActionCreators } from 'redux';

// let state = store.getState();

// const localization = require('../localization/'+state.globalState.lang).default;

// export default localization;
import cookie from "react-cookies";
import assert from "../assert";

class Localization {
  constructor() {
    //if (!Localization.instance) {
    //    //this.lang = 'ru';
    //    this.possibleLangs = ['ru', 'en', 'fr', 'es', 'ch'];
    //    Localization.instance = this;
    //}

    //return Localization.instance;

    this.possibleLangs = [
      "en",
      "ru",
      //"ar",
      //"bg",
      "de",

      "es",
      "fr",
      //"ja",
      //"kz",
      "pt"
      //"tr",
      //"tz",
      //"uk",
      //"zh"
    ]; //, 'es', 'ch'
  }

  /*
    get currentLang() {
        return this.lang;
    }*/

  get possibleLangList() {
    return this.possibleLangs;
  }

  setLanguage(lang, callback) {
    if (this.possibleLangs.indexOf(lang) !== -1) {
      //this.lang = lang;
      let expires = new Date();
      expires.setDate(expires.getDate() + 365);
      let cookieParams = {
        path: "/",
        expires,
        domain: ".myseldon.com"
      };
      cookie.save("Culture", lang.toLowerCase(), cookieParams);
      callback(lang);
      return true;
    } else return false;
  }

  getTranslations = lang => {
    assert(lang, "Не определен язык");
    let resLang = lang;
    if (resLang === "ar" || resLang === "tr") {
      resLang = "en"; //"ar-EG";
    }

    if (resLang === "uk" || resLang === "bg") resLang = "ru";

    return require("../localization/sul_account." + resLang.toLowerCase())
      .default;
  };

  selectedLang = () => {
    let curLang = cookie.load("Culture");
    if (!curLang) {
      curLang = window.navigator
        ? window.navigator.language ||
          window.navigator.systemLanguage ||
          window.navigator.userLanguage
        : "ru";
      curLang = curLang.substr(0, 2).toLowerCase();
    }

    return curLang;
  };
}

const localization = new Localization();

export default localization;
