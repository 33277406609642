const dictionary = {
  " ActiveLicenses": "Abonnements actifs",
  " Apply": "Appliquer",
  " GetAccess": "Obtenir un accès",
  " IncorrectPassword": "Code de vérification incorrect",
  " LoginCredentials": "Informations d'identification de connexion :",
  " MinLengthFew": "Le champ {0} doit contenir au moins {1} caractères",
  AccessDateActivation: "Accès démo activé de {0} à {1}",
  AccessDay: "Accès à toutes les fonctionnalités de {0} pendant 1 jour",
  AccessHalfYear: "Accès à toutes les fonctionnalités {0} pour six mois",
  AccessMonth: "Accès à toutes les fonctionnalités de {0} pendant 1 mois",
  AccessThreeMonth: "Accès à toutes les fonctionnalités {0} pendant trois mois",
  AccessWeek: "Accès à toutes les fonctionnalités de {0} pendant 1 semaine",
  AccessYear: "Accès à toutes les fonctionnalités de {0} pendant 1 année",
  AccountAlreadyContainsThisVIPCode:
    "le compte contient déjà ce code promo VIP",
  AccountWithTheSameDSAlreadyExist:
    "un compte avec cette signature électronique existe déjà",
  AccountWithTheSameEmailAlreadyExist: "un compte avec cet e-mail existe déjà",
  Activate: "Activer",
  ActivationConfirmation: "Confirmations d'activation",
  ActivationResult: "Résultat d'activation",
  ActivationTextBasis1:
    'Etudiez les liens entre les entreprises, vérifiez leur affiliation sur le schéma interactif "L\'arbre des connexions".',
  ActivationTextBasis2:
    "Consultez les données d’enregistrement des entreprises et des dirigeants, vérifiez l’adresse et les numéros de téléphone de masse.",
  ActivationTextBasis3:
    "Nous parlons de contrats gouvernementaux conclus par des entreprises et des entrepreneurs. Nous avons des informations sur la manière dont ils remplissent consciencieusement leurs obligations contractuelles.",
  ActivationTextBasis4:
    "Nous publions le bilan et calculons les indicateurs des entreprises: liquidité, solvabilité, stabilité financière, rentabilité, actif net.",
  ActivationTextControl1:
    "Soyez conscient de la transparence et de l'intégrité des activités de passation de marchés au titre des articles 44-З et 223-ФЗ.",
  ActivationTextControl2:
    "Évaluation complète des activités d’achat de l’organisation. Taux d'utilisation du budget et efficacité des achats, indicateurs de risque.",
  ActivationTextControl3:
    "Notification en temps opportun de tous les aspects de l’appel d’offres: exécution des achats planifiés, report de position, impossibilité de conclure un contrat, etc.",
  ActivationTextNews1:
    "Informations réelles sur les événements dans l'industrie, dans le pays et dans le monde. Soyez le premier à en apprendre davantage sur les événements clés.",
  ActivationTextNews2:
    "Configurez votre propre flux et obtenez des informations clés sur votre profil, des informations actuelles sur les partenaires et les concurrents.",
  ActivationTextNews3:
    "Voir ce qui est caché. La tonalité de l'information, le dossier sur l'entreprise et la personne, l'influence des médias sur leur image. L'analyste le plus détaillé disponible 24 heures sur 24 et 7 jours sur 7.",
  ActivationTextPrice1:
    "Prenez en compte la variabilité des prix et indiquez un prix concurrentiel afin que vos achats aient toujours des offres.",
  ActivationTextPrice2:
    "Utilisez des coefficients pour justifier tout prix contractuel, compte tenu des différentes conditions d'achat.",
  ActivationTextPrice3:
    "Votre prix, étayé par une justification, sera conforme aux exigences de la loi et trouvera rapidement son fournisseur.",
  ActivationTextPro1:
    "Suivez le prix et la demande de biens et services, restez informé des nouveaux achats à l'aide de widgets.",
  ActivationTextPro2:
    "Ne participez qu'aux enchères où les chances de gagner sont aussi élevées que possible. Sur la base de l'historique des offres, de l'évaluation des participants potentiels et d'autres facteurs, des prédictions personnelles de victoire sont créées.",
  ActivationTextPro3:
    "L'intelligence artificielle évaluera la situation financière du client, l'historique des achats, les cas d'arbitrage, afin que vous sachiez avec qui vous allez travailler.",
  ActivationTextPro4:
    "Le système sélectionne personnellement les offres spécialisées sur la base des données de l'entreprise afin de ne pas perdre de temps sur une recherche indépendante.",
  ActivationTitleBasis1: "Communication et affiliation",
  ActivationTitleBasis2: "Certificat de constitution",
  ActivationTitleBasis3: "Contrats et fournisseurs peu scrupuleux",
  ActivationTitleBasis4: "Bilans et analyse express",
  ActivationTitleControl1: "Surveillez les violations",
  ActivationTitleControl2: "Analyse des achats",
  ActivationTitleControl3: "Surveillance d'événements",
  ActivationTitleNews1: "Nouvelles du monde entier",
  ActivationTitleNews2: "Bande personnelle",
  ActivationTitleNews3: "Analytique et intelligence artificielle",
  ActivationTitlePrice1: "Gamme de prix",
  ActivationTitlePrice2: "Ajuster le prix",
  ActivationTitlePrice3: "Confirmer le prix",
  ActivationTitlePro1: "Analyse des enchères",
  ActivationTitlePro2: "Prévisions gagne",
  ActivationTitlePro3: "Évaluation de la fiabilité",
  ActivationTitlePro4: "Top achats",
  ActiveFrom: "actif depuis le",
  ActiveLicense: "Abonnement actif",
  ActiveLicenseCountFew: "{0} abonnements actifs",
  ActiveLicenseCountMany: "{0} abonnements actifs",
  ActiveLicenseCountOne: "{0} abonnement actif",
  ActualTill: "Valable jusqu'au",
  AddRemoveRegion: "Ajouter/supprimer la région",
  AdminPasswordReset:
    "Cher utilisateur,{0} Votre mot de passe a été réinitialisé par l'administrateur. Pour définir un nouveau mot de passe, accédez au {1} lien {2} !{3} Cordialement,{4}",
  AfterPayment: "Après paiement, vous serez en mesure de :",
  AlreadyRegistered: "Déjà enregistré ?",
  AtLeastThanEightSymbols: "Au moins 8 caractères.",
  AuthorizeWithDS: "Autorisation via la signature électronique",
  Balance: "Solde sur le compte",
  BasisMailDescription:
    "Analyse de service et évaluation d'entreprises russes et étrangères.",
  BasisRegistrationTitle:
    "Vous pouvez vérifier vos partenaires, trouver des clients, connaître leurs forces et leurs faiblesses.",
  BasisTariffDescription1:
    "24 millions de sociétés et d'entrepreneurs russes seront à votre portée de main. Leurs capacités et besoins, leurs points forts et faibles. Recherche pratique par nom, détails, contacts.",
  BasisTariffDescription2:
    "Étudiez les dossiers sur les organisations. Découvrez si votre partenaire potentiel exécute consciencieusement ses engagements financiers, contractuels et juridiques.",
  BasisTariffDescription3:
    "Recevez des notifications sur les événements dans la vie des sociétés. Seldon.Basis avertira et montrera ce qui ne va pas avec votre partenaire, concurrent. Utilisez les statistiques et les analyses pour obtenir une vue complète de l'organisation.",
  BasisTariffDescription4:
    "Étudiez les activités des sociétés des pays de la CEI. Vérifiez-les pour une participation équitable aux achats d'offres, analysez la situation financière. Développez votre entreprise — travaillez avec des partenaires étrangers.",
  BasisTariffTitle1: "Rechercher de nouveaux clients",
  BasisTariffTitle2: "Minimiser les risques",
  BasisTariffTitle3: "Être au courant des événements",
  BasisTariffTitle4: "Rechercher des partenaires de la CEI",
  Bonuses: "Bonus",
  BuySubscription: "Acheter un abonnement",
  CanSendIn: "Peut être envoyé via {0}",
  ChangeData: "Modifier les données",
  ChangePassword: "Modifier le mot de passe",
  ChangesSaved: "Les modifications ont été sauvegardées",
  ChangingEmail_SendToNewEmail:
    "Cher utilisateur,{0} Pour confirmer le changement d'adresse e-mail, suivez le {1} lien {2} !{3} Cordialement,{4}",
  ChangingEmail_SendToNewEmailTitle:
    "Confirmation du changement d'adresse e-mail",
  ChangingEmail_SendToOldEmail:
    "Cher utilisateur,{0}Vous avez fait un changement d'adresse e-mail, une nouvelle valeur : {1}. Si ce n'est pas votre cas, modifiez le mot de passe et contactez le support technique {2}Cordialement,{3}",
  ChangingEmail_SendToOldEmailTitle:
    "Notification de changement d'adresse e-mail",
  ChangingPassword:
    "Cher utilisateur,{0}Votre mot de passe a été modifié. Si ce n'est pas votre cas, modifiez le mot de passe et contactez le support technique{1} Cordialement, {2}",
  ChangingPasswordForAdmin:
    "Cher utilisateur,{0}Votre mot de passe a été modifié par l'administrateur. Votre nouveau mot de passe est {1} Cordialement, {2}",
  ChangingPasswordTitle: "Notification de changement de mot de passe",
  CheckEmailRecoverPassword:
    "Vérifiez votre e-mail pour récupérer le mot de passe.",
  ChooseCertificate: "Choisissez un certificat",
  ChooseFile: "Choisir un fichier",
  ChooseStrongPassword:
    "Pensez à un mot de passe fort et ne l'utilisez pas pour d'autres comptes.",
  CommonSettings: "Configurations générales",
  CommonSignIn: "Autorisation ordinaire",
  CommonSignUp: "Enregistrement ordinaire",
  Company: "Société",
  Confirm: "Confirmer",
  ConfirmActivationTitle: "Vous êtes déjà inscrit Accédez en un clic",
  ConfirmLicenseCreated_Mail:
    "Cher utilisateur,{0}Vous avez acheté avec succès un abonnement pour le produit {1}Cordialement,{2}",
  ConfirmLicenseCreated_MailTitle: "Achat d'abonnement",
  ConfirmLicenseUpdated_Mail:
    "Cher utilisateur,{0}Vous avez renouvelé avec succès votre abonnement pour le produit {1}Cordialement,{2}",
  ConfirmLicenseUpdated_MailTitle: "Renouvellement d'abonnement",
  ConfirmRegister: "Confirmation d'inscription",
  ConfirmRegister_Mail:
    "Cher utilisateur,{0}Vous vous êtes enregistré avec succès au service MySeldon. Pour modifier votre mot de passe, suivez le {1}lien{2} !{3} Cordialement, {4}",
  Consult: "Consultation",
  ConsultText:
    "Si vous avez des questions ou vous n'avez pas le temps {0} d'utiliser l'accès de test, {0} cliquez sur le bouton \"Consultation\".",
  ControlMailDescription:
    "Système de prévention des infractions, d’analyse et de suivi des activités d’achat.",
  ControlRegistrationTitle:
    "Contrôlez votre activité d'achat. Analysez les dépenses et optimisez la participation aux appels d’offres pour 44--З et 223-ФЗ.",
  ControlTariffDescription1:
    "Analyse rapide et détaillée des activités d'achat des organisations vous permettant de gagner du temps, des ressources et de prendre des décisions éclairées.",
  ControlTariffDescription2:
    "Le système minimisera vos risques, effectuera des mesures préventives : il préviendra les violations et les amendes en ce qui concerne les procédures dans le cadre des Lois fédérales 44-FZ et 223-FZ.",
  ControlTariffTitle1: "Analyser et surveiller les activités d'appel d'offres",
  CreatePassword: "Création d'un mot de passe",
  CurrentPassword: "Ancien mot de passe",
  CurrentSubsriptions: "Souscriptions actuelles",
  DataProtected:
    "Les données sont protégées selon le standard international PCI DSS",
  Day: "{0} jour",
  DearUser: "Cher utilisateur,",
  Demo: "Démo",
  DemoAccess: "Accès démo",
  DemoAccessDateActivation: "Accès démo activé de {0} à {1}",
  DemoAccessDay: "Accès démo pour {0} jour",
  DemoAccessFewDays: "Accès démo le {0} jour",
  DemoAccessManyDays: "Accès démo pour {0} jours",
  Department: "Subdivision",
  DifferenceInExchangeRates:
    "En cas de paiement en monnaie étrangère, le montant prélevé sur votre compte peut différer du montant indiqué comme coût du présent tarif. Cela est dû à la différence des taux de change lors de l'échange de devises par la banque au moment du paiement.",
  DigitalSignatureActivated: "La signature électronique est activée",
  DigitalSignatureAlreadyBinded:
    "Cette signature électronique est déjà liée à Votre compte",
  DigitalSignatureAndCertificates: "Signature électronique et certificats",
  DigitalSignatureBinded: "La signature électronique est rattachée avec succès",
  DigitalSignatureBindedToAnotherAccount:
    "Cette signature électronique ne peut pas être rattachée à Votre compte, car il est déjà utilisé par un autre utilisateur",
  DigitalSignatureExpired:
    "La durée de validité du certificat a expiré, utilisez une autre clé de la signature électronique",
  DigitalSignatureIsNotConfidential:
    "La signature électronique a été délivrée par le Centre de certification non inclus à la zone de confiance",
  DigitalSignatureSettings: "Configuration de la signature électronique",
  DigitalSignatureWithdrawn:
    "La signature électronique est dans le Registre des certificats révoqués, utilisez une autre clé de la signature électronique",
  Edit: "Modifier",
  Email: "E-mail",
  EmailAlreadyExist:
    "Vous ne pouvez pas modifier la valeur de l'adresse e-mail à celle spécifiée, car une telle valeur existe déjà dans le système",
  EmailOrPhone: "Adresse e-mail ou numéro de téléphone",
  EmailSignature: "Équipe de {0}",
  EndLicense: "au",
  EnterCode: "Entrez le code de SMS",
  EnterCodeConfirmPhone:
    "Entrez le code du message pour confirmer le nouveau numéro de téléphone",
  EnterEmailOrPhoneNumber: "Entrez votre adresse e-mail ou numéro de téléphone",
  EnterMailOrPhone: "Entrez votre adresse e-mail ou numéro de téléphone",
  EnterYourEmail:
    "Spécifiez un e-mail – nous vous enverrons un lien vers celui-ci et d'autres instructions.",
  ExceededRegisterPromo:
    "Le nombre d'inscriptions pour ce code promo a été dépassé",
  ExtendedTestAccess: "L'accès de test a été prolongé",
  ExtendedTestAccessText: "L'accès de test au système mySeldon a été prolongé.",
  FewDays: "{0} jours",
  Finances: "Finances",
  FirstName: "Prénom",
  ForgotPassword: "Mot de passe oublié ?",
  ForgotPasswordError:
    "Le compte avec l'adresse électronique ou le numéro de téléphone que vous avez fourni n'existe pas. Veuillez passer par {0} inscription {1}",
  FreeAccessDayProduct: "Accès gratuit le {0} jour {1}à {2}",
  FreeAccessFewDays: "Accès gratuit pour {0} jours",
  FreeAccessFewDaysProduct: "Accès gratuit le {0} jour {1}à {2}",
  FreeAccessManyDays: "Accès gratuit pour {0} jours",
  FreeAccessManyDaysProduct: "Accès gratuit à {0} jours {1}à {2}",
  FreeAccessOneDay: "Accès gratuit pour {0} jour",
  FreeAccessProduct: "Accès au système {0}",
  FreeAccessProductActivate: "Accès gratuit à {0} activé",
  FutureLicense: "Abonnement actif",
  FutureSubscribeOne: "Vous avez {0} futur abonnement",
  FutureSubscribesFew: "Vous avez {0} futurs abonnements",
  FutureSubscribesMany: "Vous avez {0} futurs abonnements",
  Gender: "Sexe",
  GenderFemale: "Femme",
  GenderMale: "Homme",
  GetAccessToInfo:
    "Accédez à l'information sur les sociétés, les appels d'offres, les événements. Veillez à votre environnement.",
  GetDemoAccess: "Obtenir un accès démo",
  GetProfit: "Réaliser des bénéfices",
  GetProfitDescription:
    "Gagnez dans les appels d'offres, en sachant à l'avance vos chances. Le service évaluera votre expérience de participation aux appels d'offres, les opportunités financières, les affaires judiciaires et 25 paramètres différents, analysera et recommandera les meilleurs achats.",
  GoToSystem: "Aller au système",
  HavePromo: "Avez-vous un code de promotion ?",
  InactivePromo: "Code promo inactif",
  IncorrectEmailEntered: "vous avez entré un e-mail incorrect",
  IncorrectPassword: "Mot de passe invalide",
  IncorrectPasswordEntered: "vous avez entré un mot de passe incorrect :",
  IncorrectPhoneEntered: "vous avez entré un numéro de téléphone incorrect",
  InvalidPromo: "Code promo incorrect",
  IsSendSms: "SMS n'est pas venu?",
  LastName: "Nom",
  LicenseAgreement: "Accord de licence",
  LicenseCountFew: "{0} abonnements",
  LicenseCountMany: "{0} abonnements",
  LicenseCountOne: "{0} abonnement",
  LinkHasExpired:
    "Le lien de l'e-mail est obsolète. Veuillez demander à nouveau la récupération du mot de passe.",
  Login: "Nom d’utilisateur",
  LoginPageHeader: "Connexion",
  LoginToAccess: "Nom d'utilisateur pour accéder au système :",
  LogOff: "Se déconnecter",
  ManageRisks: "Gérer les risques",
  ManageRisksDescription:
    "Vérifiez les clients et les concurrents, identifiez les achats adaptés pour un fournisseur spécifique. Avec Seldon.Pro, vous saurez avec qui on peut travailler en toute sécurité.",
  ManyDays: "{0} jours",
  MinPasswordLength:
    "La longueur minimale du mot de passe est de {0} caractères.",
  NewPassword: "Nouveau mot de passe",
  NewsMailDescription:
    "Agrégateur de nouvelles des médias russes et étrangers.",
  NewsRegistrationTitle:
    "Actualités du monde entier, dossier sur les entreprises, les régions, les professionnels des médias.",
  NoActiveLicense: "Aucun abonnement actif",
  NotFound: "Aucun résultat trouvé",
  NoTransactions: "Jusqu'à présent, vous n'avez aucune transaction.",
  NotRobot: "S'il vous plaît confirmer que vous n'êtes pas un robot.",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN ou NIF",
  OnDay: "Le {0} jour",
  OnFewDays: "Le {0} jour",
  OnlinePayment: "Paiement en ligne",
  OnManyDays: "Pour {0} jours",
  OpenAccess: "Accès libre",
  OrderDigitalSignature: "Commander la signature électronique",
  OrderDigitalSignatureSettings:
    "Commander la configuration de la signature électronique",
  Password: "Mot de passe",
  PasswordChanged: "Le mot de passe a été modifié",
  PasswordHasAPartOfLoginName:
    "le mot de passe ne doit pas contenir le nom de compte ou une partie du nom complet d'utilisateur dont la longueur est supérieure à deux caractères consécutifs",
  PasswordMail: "{0} — votre mot de passe pour {1}.",
  PasswordMustBeAtLeast8Symbols:
    "Le mot de passe doit contenir au moins 8 caractères",
  PasswordMustContainsLettersAndDigits:
    "Le mot de passe doit contenir des lettres majuscules latines (de A à Z) et des chiffres (de 0 à 9) ou des lettres minuscules latines (de a à z) et des chiffres (de 0 à 9) ou des lettres majuscules latines (de A à Z) et des lettres minuscules latines (de a à z)",
  PasswordNotUpToDate:
    "Le mot de passe n'est pas à jour, veuillez vous réinscrire",
  PasswordRequirementsError:
    "Le mot de passe ne répond pas aux exigences de sécurité :",
  PasswordsMismatch:
    'Les valeurs des champs "Nouveau mot de passe" et "Retapez" ne correspondent pas, veuillez réessayer.',
  PaymentButton: "Passer au paiement",
  PaymentMethod: "Mode de paiement",
  PaymentText_Mail: "Votre paiement du service {0} a été effectué avec succès.",
  PersonalInfo: "Informations personnelles",
  Phone: "Numéro de téléphone",
  PhoneNumberAlreadyExist:
    "Vous ne pouvez pas modifier la valeur du numéro de téléphone à celle spécifiée, car une telle valeur existe déjà dans le système",
  PhoneOrEmailBusy:
    "Un utilisateur avec ce numéro de téléphone ou cette adresse e-mail existe déjà",
  PlatformsWithContracts:
    "Plateformes avec lesquelles des accords sont conclus",
  PleaseFillRequirementFields:
    "Tous les champs requis ne sont pas remplis. Veuillez remplir les champs {0}",
  Position: "Fonction",
  PriceMailDescription:
    "Service de sélection de contrats justifiant le prix maximum initial.",
  PriceRegistrationTitle:
    "Préparez la justification du NMCC le plus rapidement et le plus facilement possible. Analyser le marché du commerce électronique, connaître les prix et l'activité des concurrents.",
  PriceTariffDescription1:
    "Choisissez des contrats pour argumenter le prix initial en secondes. Exportez l'argumentation complétée en un seul clic et incluez-la dans la documentation d'achat. Assurez-vous que votre proposition de prix est conforme à la loi.",
  PriceTariffDescription2:
    "Analysez les prix des produits et services pour rendre votre entreprise plus efficace. Étudiez les contrats exécutés des concurrents, consultez la dynamique des prix, observez la saisonnalité de l'offre et de la demande.",
  PriceTariffDescription3:
    "Utilisez les coefficients pour rendre le prix argumenté et attirer les fournisseurs. Vous pouvez spécifier un coefficient multiplicateur, un déflateur, prendre en compte d'autres conditions d'achat. Grâce à Seldon.Price, vous aurez toujours les demandes dans vos achats.",
  PriceTariffTitle1: "Argumenter le prix initial maximum du contrat",
  PriceTariffTitle2: "Analyser le marché",
  PriceTariffTitle3: "Attirer de nouveaux fournisseurs",
  PrivacyPolicy: "Politique de confidentialité",
  PrivacyPolicyAndOffer:
    'En appuyant sur le bouton "Passer au paiement", vous confirmez que vous avez pris connaissance et accepté les termes de la {0}Politique de confidentialité{1} et {2}Offres{3}.',
  PrivatePolicyRegister:
    "En soumettant vos données personnelles dans le formulaire, vous acceptez leur traitement conformément aux {0} règles de confidentialité {1}.",
  ProductCountFew: "pour {0} produits",
  ProductCountMany: "pour {0} produits",
  ProductCountOne: "pour {0} produit",
  Prolong: "Prolonger",
  ProMailDescription:
    "service avec intelligence artificielle pour travailler avec des appels d'offres.",
  PromoAnotherProduct: "Code promotionnel spécifié pour un autre produit",
  Promocode: "Code promo",
  PromoExpired: "Code promo expiré",
  PublicOffer:
    "La présente Offre publique (ci-après – l’Offre) conformément au point 2 de l’art. 437 du Code civil de la Fédération de Russie est une offre officielle de conclure avec toute personne morale ou physique, tout entrepreneur individuel (ci-après – l’Utilisateur) le Contrat aux termes prévus par l'Offre. Le présent Contrat ne peut pas être conclu autrement que par son acceptation dans l’ensemble. L’acceptation de l’Offre est le paiement de la redevance de licence par l’Utilisateur. Pour autant, le Contrat est réputé conclu par écrit et équivalent au Contrat signé par les deux parties.",
  PurchasingRegions: "Régions de votre activité d'achat",
  RecoveryPassword: "Récupération du mot de passe",
  RecoveryPassword_Mail:
    "Cher utilisateur,{0} Pour modifier votre mot de passe, suivez le {1}lien{2} ! {3}Cordialement, {4}",
  RecoveryPassword2_Mail:
    "Vous avez utilisé le formulaire de récupération du mot de passe, accédez au {0}lien{1} pour récupérer votre mot de passe. Si cela n'a pas été fait par vous, changez le mot de passe et contactez le support technique",
  Refill: "Approvisionner",
  Register: "Enregistrement",
  RegisterToStart: "Inscrivez-vous pour commencer le travail",
  RegistrationСompleted: "Inscription terminée",
  RememberMe: "Se souvenir de moi",
  RemovePromo: "Supprimer le code promo",
  RenewSubscription: "Prolonger l'abonnement",
  RepeatNewPassword: "Confirmez votre nouveau mot de passe",
  RequiredField: "champ obligatoire",
  Resend: "Renvoyer",
  Restore: "Réinitialiser",
  Save: "Enregistrer",
  SavePasswordSuccess: "Le mot de passe a été enregistré avec succès",
  SavePolicy:
    'En appuyant sur le bouton "Sauvegarder", vous confirmez que vous avez pris connaissance et accepté les termes de la {0}Politique de confidentialité{1}',
  SaveUnavailable: "Enregistrement impossible :",
  Search: "Recherche",
  SearchInBasis: "Trouvez des informations sur vous-mêmes dans Seldon.Basis",
  Security: "Sécurité",
  SelectedRegions: "Régions sélectionnées",
  SelectRegion: "Choisissez une région",
  Send: "Envoyer",
  SendAgain: "Envoyer à nouveau",
  SendCode: "Code envoyé",
  SendPasswordOrCreateNew:
    'Votre mot de passe a été envoyé en tant que code sur votre téléphone. Cliquez sur le bouton "Modifier votre mot de passe" pour définir votre propre mot de passe.',
  SendSmsWithPassword:
    "Nous enverrons un SMS avec un mot de passe au système à ce numéro.",
  SendToNumber: "Nous l'avons envoyé à votre numéro",
  Settings: "Configurations",
  Show: "Afficher",
  ShowVideo: "Regarder la vidéo",
  SignIn: "Se connecter",
  SignInSystem: "Connectez-vous",
  SignUpWithDS: "Enregistrement via la signature électronique",
  SincerelyTeam: "Respectueusement,{0} Équipe de Seldon",
  SingleLoginSeldon: "Accès unique à tous les services de Seldon",
  SliderBasisInfo:
    "Utilisez le service pour une évaluation complète des partenaires et des concurrents. Découvrez s'ils accomplissent consciencieusement leurs obligations : financières, contractuelles, légales.",
  SliderNewsInfo:
    "Soyez au courant des événements de l'industrie, en Russie et dans le monde. Personnalisez vos propres bandes et ne lisez que les actualités qui vous intéressent.",
  SliderPriceInfo:
    "Choisissez des contrats pour argumenter le prix initial maximum. Analysez les prix des produits et services pour rendre votre entreprise plus compétitive.",
  SliderProInfo:
    "Le service avec intelligence artificielle pour gagner une enchère. Il donne des recommandations personnelles pour tout le monde — sur le profil des activités et des opportunités, analyse les clients, les concurrents, évalue les chances de gagner.",
  Snap: "Rattacher",
  SnapDigitalSignature: "Rattacher la signature électronique",
  StartLicense: "du",
  StartUsing: "Commencer à utiliser",
  Subscribe: "Enregistrer un abonnement",
  SuccessRegister_Mail:
    "Vous vous êtes enregistré avec succès dans le système {0}.",
  SupportMailLink:
    'Si vous avez des questions, cliquez sur {0}"En savoir plus"{1} et le spécialiste vous aidera.',
  SystemLanguage: "Langue du système",
  Text403:
    "Vous ne pouvez pas voir cette page. Contactez le support si vous pensez qu'une erreur s'est produite.",
  Text404:
    "Vous avez peut-être commis une erreur en tapant le lien. {0} Essayez de démarrer à partir de {1} page principale {2}.",
  Text500:
    "Désolé, le service n'est pas encore disponible. Réessayez ou accédez à la {0} page principale {1}.",
  TillDate: "jusqu'à {0}",
  TimeLeft: "{0} secondes restantes",
  Title403: "Accès refusé",
  Title404: "Page non trouvée",
  Title500: "Une erreur est survenue.",
  ToSaveTime: "Gagner du temps",
  ToSaveTimeDescription:
    "Toutes les informations sont en un seul endroit et mises à jour en temps réel. Vous n'avez pas besoin de chercher de nouveaux achats, de vérifier la transparence des procédures, d'analyser les clients. Les offres qui vous conviendront sont maintenant à portée de main.",
  Total: "Total :{0}",
  Transactions: "Transactions",
  TryOtherProducts: "Essayez nos autres produits",
  UploadNewPhoto: "Télécharger une nouvelle photo",
  UserNotFound: "Utilisateur introuvable",
  WeNotFind: "Nous n'avons rien trouvé, essayez de {0}trouver dans {1}",
  WeSentCodeConfirm:
    "Nous vous avons envoyé un code d'accès au numéro de téléphone, confirmez la réception :",
  WrongOldPassword: "Mot de passe incorrect.",
  WrongPassword:
    "Vous avez entré un mot de passe actuel incorrect, veuillez réessayer",
  YouCanFindInfoInBasis:
    "Vous pouvez toujours trouver des {0}informations dans Seldon Basis{1}",
  YourLogin: "Nom d’utilisateur :"
};
export default dictionary;
