const dictionary = {
  " ActiveLicenses": "Active subscriptions",
  " Apply": "Activate promo code",
  " GetAccess": "Get access",
  " IncorrectPassword": "Incorrect Security Code",
  " LoginCredentials": "Authentification details:",
  " MinLengthFew": "{0} must contain at least {1} characters",
  AccessDateActivation: "Trial access valid from {0} through {1}",
  AccessDay: "Full 24-hours access to {0}",
  AccessHalfYear: "Full access to {0} for 6 months",
  AccessMonth: "Full 30-day access to {0} ",
  AccessThreeMonth: "Full access to {0} for 3 months",
  AccessWeek: "Full 7-day access to {0}",
  AccessYear: "Full 365-day access to {0}",
  AccountAlreadyContainsThisVIPCode:
    "This account already uses this VIP promo code",
  AccountWithTheSameDSAlreadyExist:
    "This digital signature is already linked to an existing account",
  AccountWithTheSameEmailAlreadyExist:
    "account with this E-mail already exists",
  Activate: "Activate",
  ActivationConfirmation: "Confirm activation",
  ActivationResult: "Free Access",
  ActivationTextBasis1:
    "Use the service to study connections between companies and check their affiliation using the Connections Tree.",
  ActivationTextBasis2:
    "View registration details of companies and their executives, check whether their address or phone number is associated with other entities.",
  ActivationTextBasis3:
    "Information about government contracts concluded by companies and proprietors as well as information on their reliability.",
  ActivationTextBasis4:
    "Access to the balance sheets and financials of companies, i.e. liquidity, financial solvency, financial stability, profitability and net assets.",
  ActivationTextControl1:
    "See whether comapny's procuring activity is transparent and compliant with the 44-FZ and 223-FZ laws.",
  ActivationTextControl2:
    "Comprehensive reports on company's activity, i.e. budget spending rate, purchasing efficiency, risk evaluation, etc.",
  ActivationTextControl3:
    "Get duly notified about all tender procedure aspects, i.e. scheduled procurement dates, expired lots, impossibility of contract conclusion, etc.",
  ActivationTextNews1: "Relevant information",
  ActivationTextNews2:
    "Customize the newsfeed to get relevant notifications about your partners and competitors.",
  ActivationTextNews3:
    "A comprehensive real-time analysis of news, companies and persons 24/7.",
  ActivationTextPrice1:
    "Use price range information to set a competitive price and ensure bids in your procurements.",
  ActivationTextPrice2:
    "Use various coefficients in order to justify any contract price.",
  ActivationTextPrice3:
    "The contract's favorable terms and justified price, compliant with the legislation, will increase your chances of finding a supplier.",
  ActivationTextPro1:
    "Monitor the prices and demand of products and services; use widgets to follow the latest tenders.",
  ActivationTextPro2:
    "AI-powered system offers personalized recommendations based on tender history, potential participants and other factors for successful participation in tenders.",
  ActivationTextPro3:
    "Evaluation of the customer's financial situation and arbitration proceedings background by AI-powered algorithms for you to work only with reliable companies.",
  ActivationTextPro4:
    "The system uses your company details to recommend suitable tenders.",
  ActivationTitleBasis1: "Connections & Affiliation",
  ActivationTitleBasis2: "EGRUL Company Profile",
  ActivationTitleBasis3: "Contracts & Blacklisted Suppliers",
  ActivationTitleBasis4: "Balances & Express Analysis",
  ActivationTitleControl1: "Violation Reports",
  ActivationTitleControl2: "Tender Activity Analysis",
  ActivationTitleControl3: "Activity Monitoring",
  ActivationTitleNews1: "Global Coverage",
  ActivationTitleNews2: "Your Personal Newsfeed",
  ActivationTitleNews3: "Analytics & AI",
  ActivationTitlePrice1: "Price Range",
  ActivationTitlePrice2: "Price Adjustment",
  ActivationTitlePrice3: "Price Justification",
  ActivationTitlePro1: "Analysis of Tenders",
  ActivationTitlePro2: "Winner Predictions",
  ActivationTitlePro3: "Due Diligence",
  ActivationTitlePro4: "Recommendations",
  ActiveFrom: "active since",
  ActiveLicense: "Active subscription",
  ActiveLicenseCountFew: "{0} active subscriptions",
  ActiveLicenseCountMany: "{0} active subscriptions",
  ActiveLicenseCountOne: "{0} active subscription",
  ActualTill: "Valid until",
  AddRemoveRegion: "Add/remove region",
  AdminPasswordReset:
    "Dear User,{0} Your password has been successfully reset. Please click {1} here {2} to set a new password.{3} Best regards,{4}",
  AfterPayment: "Buy the subscription and unlock the following features:",
  AlreadyRegistered: "Already signed up?",
  AtLeastThanEightSymbols: "At least 8 characters.",
  AuthorizeWithDS: "Log in using digital signature",
  Balance: "Account balance",
  BasisMailDescription: "Due diligence and partner search platform.",
  BasisRegistrationTitle:
    "Check your business partners and find potential customers.",
  BasisTariffDescription1:
    "Get access to the database of 24 million entities in Russia. Check how legally, contractually and financially compliant a company is. Mitigate risks by knowing strengths and weaknesses of your partner. Discover and monitor market competitors. Conducting due diligence has never been easier!",
  BasisTariffDescription2:
    "See how legally, financially and contractually compliant companies are by studying their profile in Seldon.Basis.",
  BasisTariffDescription3:
    "Monitor company activity. Study strong points and spot risks in your partner's profile. See the big picture and improve your decision-making with Seldon.Basis enhanced due diligence.",
  BasisTariffDescription4:
    "Check how legally and contractually compliant CIS-based companies are. Power your decision-making by conducting enhanced due diligence in the major CIS countries.",
  BasisTariffTitle1: "Find new clients",
  BasisTariffTitle2: "Mitigate risks",
  BasisTariffTitle3: "Stay Informed",
  BasisTariffTitle4: "Find partners in CIS countries",
  Bonuses: "Bonus points",
  BuySubscription: "Buy subscription",
  CanSendIn: "You can request a new code in {0}",
  ChangeData: "Edit user details",
  ChangePassword: "Change password",
  ChangesSaved: "Changes saved",
  ChangingEmail_SendToNewEmail:
    "Dear User,{0}Please click {1}here{2} to confirm your new e-mail.{3} Best regards,{4}",
  ChangingEmail_SendToNewEmailTitle: "Confirm the new e-mail address",
  ChangingEmail_SendToOldEmail:
    "Dear User,{0}You have changed your Email to {1} If you did not make this request, please change your password and contact the Support Team {2}Best regards,{3}",
  ChangingEmail_SendToOldEmailTitle: "Email change alert",
  ChangingPassword:
    "Dear User,{0}Your password has been changed. If you did not change your password, please contact the Support Team{1}Best regards, {2}",
  ChangingPasswordForAdmin:
    "Dear User,{0}Here is your new password {1} Best regards,{2}",
  ChangingPasswordTitle: "Password change alert",
  CheckEmailRecoverPassword:
    "A link to reset your password has been sent to your email address.",
  ChooseCertificate: "Choose certificate",
  ChooseFile: "Select file",
  ChooseStrongPassword:
    "Create a strong password. We highly recommend not to use it elsewhere.",
  CommonSettings: "General settings",
  CommonSignIn: "Log in",
  CommonSignUp: "Sign up",
  Company: "Company",
  Confirm: "Confirm registration",
  ConfirmActivationTitle:
    "You already have an account. Get trial access in one click!",
  ConfirmLicenseCreated_Mail:
    "Dear User,{0}You have successfully purchased a {product} subscription {1}Best regards,{2}",
  ConfirmLicenseCreated_MailTitle: "Buy subscription",
  ConfirmLicenseUpdated_Mail:
    "Dear User,{0}You have successfully renewed your {product} subscription {1}Best regards,{2}",
  ConfirmLicenseUpdated_MailTitle: "Subscription renewal",
  ConfirmRegister: "Confirmation of registration",
  ConfirmRegister_Mail:
    "Dear User,{0}You have successfully created your MySeldon account. Click {1}here{2} to change your password.{3}Best regards,{4}",
  Consult: "Ask a question",
  ConsultText:
    'If you have any questions or you were not able {0} to use the trial access, press "Ask a question"',
  ControlMailDescription: "Procurement analysis and monitoring system.",
  ControlRegistrationTitle:
    "Easy way to manage your procuring activity and ensure effective participation in 44-FZ/223-FZ procurements.",
  ControlTariffDescription1:
    "A quick and comprehensive analysis of procuring activity of companies will help you save time & resources and make carefully weighed decisions.",
  ControlTariffDescription2:
    "Use Seldon.Control to mitigate risks and prevent violations and penalties for procedures under 44-FZ and 223-FZ.",
  ControlTariffTitle1: "Follow and analyze the auction-related activities",
  CreatePassword: "Create password",
  CurrentPassword: "Current password",
  CurrentSubsriptions: "Current subscriptions",
  DataProtected: "Information is protected according to PCI DSS",
  Day: "{0} day",
  DearUser: "Dear User,",
  Demo: "Trial access",
  DemoAccess: "Trial access",
  DemoAccessDateActivation: "Trial access valid from {0} through {1}",
  DemoAccessDay: "Trial access for {0} day",
  DemoAccessFewDays: "Trial access for {0} days",
  DemoAccessManyDays: "Trial access for {0} days",
  Department: "Department",
  DifferenceInExchangeRates:
    "When paying in foreign currency, the amount withdrawn from your account may differ from the specified tariff. This is caused by the difference in currency exchange rates applied during currency exchange by the bank when making a payment.",
  DigitalSignatureActivated: "Digital signature has been activated",
  DigitalSignatureAlreadyBinded:
    "This digital signature is already linked to your account",
  DigitalSignatureAndCertificates: "Digital signatures and certificates",
  DigitalSignatureBinded: "Digital signature has been added",
  DigitalSignatureBindedToAnotherAccount:
    "This digital signature cannot be linked to your account as it is already used by another user",
  DigitalSignatureExpired:
    "This certificate has expired. Please use another digital signature",
  DigitalSignatureIsNotConfidential:
    "Digital signature was issued by an untrusted Certification Authority",
  DigitalSignatureSettings: "Digital Signature",
  DigitalSignatureWithdrawn:
    "This digital signature is included in the Canceled Certificates Registry. Please use another digital signature",
  Edit: "Edit",
  Email: "E-mail",
  EmailAlreadyExist: "You cannot use this E-mail as it is already in use",
  EmailOrPhone: "Email or phone number",
  EmailSignature: "{0} Team",
  EndLicense: "till",
  EnterCode: "Enter code from text message",
  EnterCodeConfirmPhone:
    "Enter the security code from the text to confirm the new phone number",
  EnterEmailOrPhoneNumber: "Enter your email or phone number",
  EnterMailOrPhone: "Enter your email address or phone number",
  EnterYourEmail:
    "Please enter the Email to receive the authorization link and instructions.",
  ExceededRegisterPromo:
    "The number of accounts created using this promo code has been exceeded",
  ExtendedTestAccess: "Trial access successfully renewed",
  ExtendedTestAccessText: "Your mySeldon trial access has been extended.",
  FewDays: "{0} days",
  Finances: "Finance",
  FirstName: "First name",
  ForgotPassword: "Forgot your password?",
  ForgotPasswordError:
    "No account found with that email address/phone number. Please {0}sign up{1}",
  FreeAccessDayProduct: "Free {0}-day access {1}to {2}",
  FreeAccessFewDays: "Free access for {0} days",
  FreeAccessFewDaysProduct: "Free {0}-day access {1}to {2}",
  FreeAccessManyDays: "Free access for {0} days",
  FreeAccessManyDaysProduct: "Free {0}-day access {1}to {2}",
  FreeAccessOneDay: "Free access for {0} day",
  FreeAccessProduct: "Access to {0}",
  FreeAccessProductActivate: "Free {0} access activated",
  FutureLicense: "Pending subscription",
  FutureSubscribeOne: "You have {0} more pending subscription",
  FutureSubscribesFew: "You have {0} more pending subscriptions",
  FutureSubscribesMany: "You have {0} more pending subscriptions",
  Gender: "Gender",
  GenderFemale: "Female",
  GenderMale: "Male",
  GetAccessToInfo:
    "Access information about companies, contracts, events. Monitor the market activity.",
  GetDemoAccess: "Get trial access",
  GetProfit: "Make profits",
  GetProfitDescription:
    "Know your chances of winning a tender! Analysis of public procurement experience, financials, legal proceedings and 25 more factors which makes Seldon.Pro a state-of-the-art tender recommendation platform.",
  GoToSystem: "Go to system",
  HavePromo: "I have a promo code",
  InactivePromo: "This promo code has expired",
  IncorrectEmailEntered: "Invalid e-mail address",
  IncorrectPassword: "Incorrect password",
  IncorrectPasswordEntered: "Invalid password:",
  IncorrectPhoneEntered: "Invalid phone number",
  InvalidPromo: "Invalid promo code",
  IsSendSms: "Did not receive text message?",
  LastName: "Last name",
  LicenseAgreement: "License Agreement",
  LicenseCountFew: "{0} subscriptions",
  LicenseCountMany: "{0} subscriptions",
  LicenseCountOne: "{0} subscription",
  LinkHasExpired:
    "Password recovery link has expired. Please request a new recovery link.",
  Login: "Login",
  LoginPageHeader: "Log in",
  LoginToAccess: "Your login:",
  LogOff: "Log out",
  ManageRisks: "Manage risk",
  ManageRisksDescription:
    "Mitigate risks with Seldon.Pro! Detect specific supplier-oriented procurements. Check your customers and competitors comprehensively.",
  ManyDays: "{0} days",
  MinPasswordLength: "Password must contain at least {0} characters.",
  NewPassword: "New password",
  NewsMailDescription: "Russian and foreign news aggregator.",
  NewsRegistrationTitle:
    "Latest news from all over the world; profiles of companies, regions and persons.",
  NoActiveLicense: "No active subscriptions",
  NotFound: "Nothing found",
  NoTransactions: "You have no transactions record.",
  NotRobot: "Please confirm you are not a robot",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN or TIN number",
  OnDay: "For {0} day",
  OnFewDays: "For {0} days",
  OnlinePayment: "Online payment",
  OnManyDays: "For {0} days",
  OpenAccess: "Get access",
  OrderDigitalSignature: "Request a digital signature",
  OrderDigitalSignatureSettings: "Request digital signature help",
  Password: "Password",
  PasswordChanged: "Password has been changed",
  PasswordHasAPartOfLoginName:
    "Password must not match the account name or a part of the user's full name within two or more adjacent characters",
  PasswordMail: "Your {1} password: {0}",
  PasswordMustBeAtLeast8Symbols: "Password must contain at least 8 characters",
  PasswordMustContainsLettersAndDigits:
    "Password must contain upper (A to Z) or lower (a to z) latin characters and digits (0 to 9).",
  PasswordNotUpToDate: "Password has expired. Please sign up again.",
  PasswordRequirementsError:
    "Password does not comply with the following security requirements:",
  PasswordsMismatch: "Password entries do not match. Please try again.",
  PaymentButton: "Proceed to payment",
  PaymentMethod: "Payment method:",
  PaymentText_Mail: "Your {0} payment has been processed.",
  PersonalInfo: "Personal information",
  Phone: "Phone number",
  PhoneNumberAlreadyExist: "This phone number is already in use",
  PhoneOrEmailBusy: "A user with this e-mail or phone number already exists",
  PlatformsWithContracts: "Partner platforms",
  PleaseFillRequirementFields: "All fields with * must be completed.",
  Position: "Position",
  PriceMailDescription:
    "Justification of initial maximum contract price system.",
  PriceRegistrationTitle:
    "Quick and easy justification of initial maximum contract price. Comprehensive analysis of e-procurement market, prices and competitors.",
  PriceTariffDescription1:
    "Find contracts for initial cost justification in a matter of seconds. Export cost justification in one click and include it in the procurement documentation. Make sure your costs are compliant with law.",
  PriceTariffDescription2:
    "Analyze the prices of products and services and make your business more effective. Study the fulfilled contracts of your competitors, examine the price development, monitor the seasonal fluctuations of supply and demand.",
  PriceTariffDescription3:
    "Use indices to justify your costs and attract suppliers. Use enhancement factor or deflator; consider other procurement terms. Seldon.Price will ensure bids for your procurements.",
  PriceTariffTitle1: "Justify initial maximum contract price",
  PriceTariffTitle2: "Analyze the market",
  PriceTariffTitle3: "Attract new suppliers",
  PrivacyPolicy: "Privacy Policy",
  PrivacyPolicyAndOffer:
    "By proceeding to payment you confirm that you have read and agree to the {0}Privacy Policy{1} and {2}Offer{3} terms.",
  PrivatePolicyRegister:
    "By submitting your personal information, you agree to its processing in accordance with the {0}Privacy Policy{1}",
  ProductCountFew: "to {0} products",
  ProductCountMany: "to {0} products",
  ProductCountOne: "to {0} product",
  Prolong: "Renew subscription",
  ProMailDescription: "AI-based system for participation in tenders.",
  PromoAnotherProduct: "This is a promo code to a different Seldon product.",
  Promocode: "Promo code",
  PromoExpired: "This promo code has expired.",
  PublicOffer:
    "In accordance with Clause 2 or Article 437 of the Civil Code of the Russian Federation, the Public Offer, hereinafter referred to as the Offer, is an official offer to conclude a contract with a legal entity, natural person, or a sole proprietor, hereinafter referred to as the User, based on the Offer's terms. The contract is concluded through accession. Payment of the license remuneration by the User signifies his acceptance of the Offer. The contract shall be deemed to be concluded in writing and considered equal to a contract signed by both parties.",
  PurchasingRegions: "Regions of your procuring activity",
  RecoveryPassword: "Password recovery",
  RecoveryPassword_Mail:
    "Dear User,{0}Click {1}here{2} to change your password.{3}Best regards,{4}",
  RecoveryPassword2_Mail:
    "Please click {0}here{1} to reset your password. If you did not request a password reset, please change your password and contact our Technical Support",
  Refill: "Top up",
  Register: "Sign up",
  RegisterToStart: "Sign up to get started",
  RegistrationСompleted: "Registration complete",
  RememberMe: "Remember me",
  RemovePromo: "Delete promo code",
  RenewSubscription: "Renew subscription",
  RepeatNewPassword: "Confirm new password",
  RequiredField: "required",
  Resend: "Send again",
  Restore: "Recover",
  Save: "Save",
  SavePasswordSuccess: "Password saved",
  SavePolicy:
    'By clicking "Save" you confirm that you have read and agree to the {0}Privacy Policy{1} terms.',
  SaveUnavailable: "Saving failed:",
  Search: "Search",
  SearchInBasis: "Find your own company in Seldon.Basis",
  Security: "Security",
  SelectedRegions: "Selected regions",
  SelectRegion: "Select region",
  Send: "Send",
  SendAgain: "Send again",
  SendCode: "Code has been sent",
  SendPasswordOrCreateNew:
    'We have sent a text with your password to your phone number. Click "Create Password" to set a password manually.',
  SendSmsWithPassword:
    "SMS with authorization password will be sent to this number",
  SendToNumber: "It has been sent to your phone number",
  Settings: "Settings",
  Show: "Show password",
  ShowVideo: "Watch video",
  SignIn: "Log in",
  SignInToContinue: "Sign in to continue",
  SignInSystem: "Log in",
  SignUpWithDS: "Sign up via digital signature",
  SincerelyTeam: "Best regards,{0} Seldon Team",
  SingleLoginSeldon: "Single point of access to all RSPP products",
  SliderBasisInfo:
    "Conduct comprehensive check of partners and competitors. Find out how consistent they are in fulfilling their legal, financial and contarctual obligations.",
  SliderNewsInfo:
    "Follow market updates in Russia and in the world. Customize newsfeed according to your preferences",
  SliderPriceInfo:
    "Choose contracts for initial maximum contract price justification. Conduct price analysis, thus enhancing competitiveness of your business.",
  SliderProInfo:
    "AI-powered system that offers customized recommendations for successful participation in tenders. Seldon.Pro provides expert market analysis based on user's capabilities and calculates chances of winning.",
  Snap: "Add",
  SnapDigitalSignature: "Add digital signature",
  StartLicense: "from",
  StartUsing: "Get started",
  Subscribe: "Purchase subscription",
  SuccessRegister_Mail: "You have signed up for {0}.",
  SupportMailLink:
    'Click "Learn more" if you have any questions and we will contact you.',
  SystemLanguage: "Language",
  Text403:
    "You don't have permission to view this page. Please contact our Support Team if you think an error occurred.",
  Text404:
    "The link appears to be invalid.{0} Please go to {1}Home Page{2} and try again",
  Text500:
    "Service is temporarily unavailable. Please try again or go to {0}Home Page{1}.",
  TillDate: "until {0}",
  TimeLeft: "{0} seconds left",
  Title403: "Access denied",
  Title404: "Page not found",
  Title500: "An error occurred",
  ToSaveTime: "Save time",
  ToSaveTimeDescription:
    "Seldon.Pro is your smart assistant in the market of public procurement and commercial tenders. Seldon.Pro takes its user to the whole new level in market analysis by suggesting the best procurements and enhancing search results. Check suppliers for contract compliance. Check contractors for precedure transparency. See the history behind a product or a service. ",
  Total: "Total:{0}",
  Transactions: "Transactions",
  TryOtherProducts: "Try other Seldon products:",
  UploadNewPhoto: "Upload new photo",
  UserNotFound: "User not found",
  WeNotFind: "No results found, try to {0}search in {1}",
  WeSentCodeConfirm:
    "We have sent a security code to your phone number. Please enter it here:",
  WrongOldPassword: "Incorrect password.",
  WrongPassword: "Invalid password. Please try again.",
  YouCanFindInfoInBasis:
    "You can always find {0}information in Seldon Basis{1}",
  YourLogin: "Your login:"
};
export default dictionary;
