export const putAuthToken = (token, rememberMe = false) => {
  if (rememberMe) {
    sessionStorage.removeItem("token");
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
    sessionStorage.setItem("token", token);
  }
};

export const getAuthToken = (onlySession = false) => {
  return onlySession
    ? sessionStorage.getItem("token")
    : localStorage.getItem("token") || sessionStorage.getItem("token");
};

export const removeAuthToken = () => {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
};
