// Library
import React from "react";
import { Formik, Form, Field } from "formik";
import localization from "localization";
import styled from "styled-components";

// Validation
import LoginSchema from "../../validationSchemas/login-schema";

// UI
import Input from "@ui/Input";
import Button from "@ui/Button";
import Checkbox from "@ui/Checkbox";

// Style
import "./login.scss";

const { PART_ROUTE } = process.env;

const Wrapper = styled.div`
  color: #fff;

  input::placeholder {
    color: #c4c4c4;
  }

  @media (max-width: 576px) {
    h5 {
      font-size: 1rem;
    }
  }
`;

const Login = ({ sendForm, lang, loginUc }) => {
  const t = localization.getTranslations(lang);

  return (
    <Wrapper className="pt-2 pt-sm-1 pb-2">
      <h5 className="text-center pb-1">{t.SignInToContinue}</h5>

      <Formik
        initialValues={{ email: "", pass: "", rememberMe: false }}
        validationSchema={LoginSchema}
        onSubmit={sendForm}
        render={({ errors }) => (
          <Form>
            <div className="pt-1">
              {errors.api && (
                <small className="text-danger">{errors.api}</small>
              )}

              <Field
                name="email"
                render={({
                  field,
                  form: { isSubmitting, errors, touched }
                }) => (
                  <Input
                    {...field}
                    className="mb-1"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    disabled={isSubmitting}
                    placeholder="E-mail"
                  />
                )}
              />

              <Field
                name="pass"
                render={({
                  field,
                  form: { isSubmitting, errors, touched }
                }) => (
                  <Input
                    {...field}
                    className="mb-1"
                    type="password"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    disabled={isSubmitting}
                    placeholder={t.Password}
                  />
                )}
              />

              <div className="fs-14 d-flex justify-content-between mb-1 pb-1">
                <Field
                  name="rememberMe"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      className="text-secondary"
                      label={t.RememberMe}
                      variant="secondary"
                    />
                  )}
                />

                {/* <Link
                  className="text-light"
                  to={`${PART_ROUTE}/forgotpassword`}
                >
                  Забыли пароль?
                </Link> */}
              </div>

              <Button
                className="btn btn-primary text-white w-100 mt-1"
                type="submit"
                size="lg"
              >
                {t.SignIn}
              </Button>

              {/* <a
                className="btn btn-outline-primary w-100 mt-1"
                href={`${config.amo}`}

              >
                Зарегистрироваться
              </a> */}
              <Button
                type="button"
                onClick={loginUc}
                className="btn btn-outline-light w-100 mt-1"
              >
                {t.SignInBaseID}
              </Button>
            </div>
          </Form>
        )}
      />
    </Wrapper>
  );
};

export default Login;
