import * as types from "./types";

export function existsAuth(isAuth) {
  return {
    type: types.EXISTS_AUTH,
    isAuth
  };
}

export function relogin(redirect) {
  return {
    type: types.REDIRECT_TO_LOGIN,
    redirect
  };
}

export function forbidden(forbidden = true) {
  return {
    type: types.FORBIDDEN,
    forbidden
  };
}
