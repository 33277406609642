import * as types from "./action-types";
import config from "config";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import * as initialAction from "../initial/initial-actions";
import * as promoAction from "../promo/promo-actions";
import * as globalAction from "../global/global-actions";
import axios from "axios";
import { isEmptyOrSpaces } from "../../extensions/stringExt";

export function updatePhonePrefix(phonePrefix) {
  return {
    type: types.UPDATE_PHONE_PREFIX,
    phonePrefix
  };
}

export function addRecaptcha(recaptcha) {
  return {
    type: types.ADD_RECAPTCHA,
    recaptcha
  };
}

export function redirectToConfirm(redirect) {
  return {
    type: types.REDIRECT_TO_CONFIRM,
    redirect
  };
}

export function addUserModel(userModel) {
  return {
    type: types.ADD_USER_MODEL,
    userModel
  };
}

export function addErrorConfirm(errorConfirm) {
  return {
    type: types.ADD_ERROR_CONFIRM,
    errorConfirm
  };
}

export function addErrorRegister(errorRegister) {
  return {
    type: types.ADD_ERROR_REGISTER,
    errorRegister
  };
}

export const register = (
  name,
  email,
  phone,
  lang,
  success = f => f,
  error = f => f
) => async (dispatch, getState) => {
  let action = bindActionCreators(
    {
      addErrorRegister,
      addUserModel,
      loading: globalAction.loading
    },
    dispatch
  );
  try {
    let registerState = getState().registerState;
    let model = { name, email }; //Object.assign({ recaptcha: this.props.register.recaptcha }, values);
    model.phone = "+" + registerState.phonePrefix.value + phone;
    // model.promoId =
    //   registerState.promo && registerState.promo.promocodeSuccess
    //     ? registerState.promo.promocodeSuccess.crmId
    //     : "";
    // model.partnerId = registerState.initial
    //   ? registerState.initial.partnerId
    //   : "";
    // model.initiatorProduct =
    //   !registerState.initial ||
    //   registerState.initial.initialProduct.productName === ""
    //     ? "sul"
    //     : registerState.initial.initialProduct.productName;
    model.recaptcha = registerState.recaptcha;

    action.loading(true);

    let res = await axios.post(`${config.api}/api/account/Register`, model);

    if (res.data.isValid) {
      action.addUserModel(model);

      success();
    } else {
      action.addErrorRegister(res.data.error);
      //error({ api: res.data.ErrorType });
    }
  } catch (e) {
    console.log(e);
  } finally {
    action.loading(false);
  }
};

export const sendVerificationCode = (phone, lang, process = f => f) => async (
  dispatch,
  getState
) => {
  //let registerState = getState().registerState;
  let res = await axios.post(
    `${
      config.sulApi
    }/${lang}/account/SendVerificationCode?PhoneNumber=${encodeURI(phone)}`
  );
  process(res && res.data === "True");
};

export const registerConfirm = (
  lang,
  code,
  success = f => f,
  error = f => f
) => async (dispatch, getState) => {
  let register = getState().registerState;
  //let model = { ...register.userModel, code, password: code }; //Object.assign(this.props.register.userModel, values);
  let model = { ...register.userModel, code };
  //model.password = values.code;
  model.returnUrl = register.initial
    ? register.initial.initialProduct.returnUrl
    : null;

  let action = bindActionCreators(
    {
      addErrorConfirm,
      loading: globalAction.loading
    },
    dispatch
  );

  try {
    action.loading(true);
    let res = await axios.post(
      `${config.api}/api/account/RegisterConfirm`,
      model
    );

    if (res.data.isValid) {
      let modelLogin = {
        email: model.email,
        password: model.code,
        RememberMe: false
      };

      let loginRes = await axios.post(
        `${config.api}/api/account/login`,
        modelLogin,
        {
          headers: {
            "Content-Type": "application/json"
          },
          withCredentials: true
        }
      );

      if (loginRes.data && loginRes.data.isValid) {
        success(model.returnUrl);
      } else {
        action.addErrorConfirm(
          loginRes.data && loginRes.data.errorType === 0
            ? "Вы уже авторизованы"
            : "Электронная почта или пароль не верные"
        );
      }
    } else {
      action.addErrorConfirm(res.data.error);
    }
  } catch (e) {
    console.log(e);
  } finally {
    action.loading(false);
  }
};

export const initRegister = (promo, partnerId, returnUrl) => dispatch => {
  let action = bindActionCreators(
    {
      addPromocode: promoAction.addPromocode,
      addPartnerId: initialAction.addPartnerId,
      addInitialProduct: initialAction.addInitialProduct
    },
    dispatch
  );

  if (promo) {
    action.addPromocode(promo);
    let expires = new Date();
    expires.setDate(expires.getDate() + config.cookiesDays);
    let cookieParams = {
      path: "/",
      expires,
      domain: config.cookiesDomain
    };
    cookie.save("promocode", promo, cookieParams);
  } else {
    promo = cookie.load("promocode");
    if (promo) action.addPromocode(promo);
  }

  if (partnerId) {
    action.addPartnerId(partnerId); //store.dispatch(accountAction.addPromocode(promo));
    let expires = new Date();
    expires.setDate(expires.getDate() + config.cookiesDays);
    let cookieParams = {
      path: "/",
      expires,
      domain: config.cookiesDomain //'.myseldon.com'
    };
    cookie.save("partnerId", partnerId, cookieParams);
  } else {
    partnerId = cookie.load("partnerId");
    if (partnerId) action.addPartnerId(partnerId);
  }

  let curUrl = document.URL;
  //returnUrl = "";
  //let referrer = "https://basis.mysel7don.com/ru/home/tariffs";
  let referrer = document.referrer;

  console.log("returnUrl: " + returnUrl);
  console.log("referrer: " + referrer);

  if (referrer && referrer.toLowerCase().search(curUrl) < 1) {
    if (
      isEmptyOrSpaces(returnUrl) &&
      !(referrer.search("rspp-centre.com") > 0)
    ) {
      let referrerUrl = new URL(referrer);
      if (referrerUrl) {
        console.log("referrerUrl.pathname: " + referrerUrl.pathname);
        console.log("referrerUrl.search: " + referrerUrl.search);
        returnUrl = encodeURIComponent(
          referrerUrl.pathname + referrerUrl.search
        );
      }
    } else if (referrer.search("rspp-centre.com") > 0) {
      returnUrl = !returnUrl ? referrer : returnUrl;
    }
  }

  console.log("returnUrl after processing: " + returnUrl);

  if (returnUrl != null) {
    let productName = "";

    //Пока убрал продукты
    //if (returnUrl.indexOf(config.basisSuffix) !== -1) productName = "basis";
    //if (returnUrl.indexOf(config.proSuffix) !== -1) productName = "pro";
    //if (returnUrl.indexOf(config.newsSuffix) !== -1) productName = "news";
    //if (returnUrl.indexOf(config.priceSuffix) !== -1) productName = "price";
    //if (returnUrl.indexOf(config.controlSuffix) !== -1) productName = "control";

    let product = { returnUrl: returnUrl, productName: productName };
    action.addInitialProduct(product);
  }
};
