const dictionary = {
  " ActiveLicenses": "Ліцензії",
  " Apply": "Застосувати",
  " GetAccess": "Отримати доступ",
  " IncorrectPassword":
    "Пароль недійсний. Будь ласка, введіть правильний пароль.",
  AccessDay: "1 день",
  AccessHalfYear: "на {0}",
  AccessMonth: "{0} місяць",
  AccessThreeMonth: "{0} місяці",
  AccessWeek: "на {0}",
  AccessYear: "{0} рік",
  AccountWithTheSameDSAlreadyExist: "{0} запис",
  AccountWithTheSameEmailAlreadyExist: "{0} запис",
  Activate: "Активувати",
  ActivationTextBasis1: "Афільованість",
  ActivationTextBasis2:
    "Дивіться реєстраційні дані компаній і керівних осіб, перевіряйте масовість адреси і телефону.",
  ActivationTextBasis3:
    "Розповідаємо про держконтракти, укладені компаніями і підприємцями. Володіємо інформацією, наскільки сумлінно вони виконують свої контрактні зобов'язання.",
  ActivationTextBasis4: "Бухгалтерський баланс",
  ActivationTextControl1: "Будьте в курсі",
  ActivationTextControl2: "Організації",
  ActivationTextControl3: "{0}{1} контракта{2}",
  ActivationTextNews1: "Інформація",
  ActivationTextNews2: "Отримуйте",
  ActivationTextNews3: "Тональність новин за сьогодні",
  ActivationTextPrice1: "Вартість",
  ActivationTextPrice2: "Використовуйте",
  ActivationTextPrice3: "Ціна",
  ActivationTextPro1: "Будьте в курсі",
  ActivationTextPro3: "Фінансовий стан",
  ActivationTextPro4: "Компанії",
  ActivationTitleBasis1: "Зв'язки та афільованість",
  ActivationTitleBasis2: "Виписка з ЄДРЮО",
  ActivationTitleBasis3: "Контракти і недобросовісні постачальники",
  ActivationTitleBasis4: "Баланси та експрес-аналіз",
  ActivationTitleControl1: "{0} за {1}",
  ActivationTitleControl2: "Аналітика",
  ActivationTitleControl3:
    "Моніторинг розвитку проекту (цільове використання ресурсів, фінансові показники), маркетинговий супровід, проектний консалтинг",
  ActivationTitleNews1: "Компанії",
  ActivationTitleNews3: "Аналітика",
  ActivationTitlePro1: "аналіз",
  ActivationTitlePro2: "Прогноз",
  ActivationTitlePro3:
    "Індекс благонадійності — це оцінка надійності діючої комерційної компанії.",
  ActivationTitlePro4: "Можливості",
  ActiveFrom: "з {0}",
  ActiveLicense: "{0} ліцензія",
  ActiveLicenseCountFew: "Ліцензії",
  ActiveLicenseCountMany: "Ліцензій",
  ActiveLicenseCountOne: "{0} ліцензія",
  ActualTill: "Діє до {0}",
  AddRemoveRegion: "Додати",
  AdminPasswordReset: "Новий пароль",
  AlreadyRegistered: "Вже зареєстровані? {0}Увійдіть в систему{1}.",
  AtLeastThanEightSymbols: "Менше {0}",
  AuthorizeWithDS: "Авторизація",
  Balance: "Баланс",
  BasisRegistrationTitle: "Можна перевірити %1$s %2$s",
  BasisTariffDescription1: "Можливості",
  BasisTariffDescription2: "Зобов'язання, {0}",
  BasisTariffDescription3: "Організації",
  BasisTariffDescription4: "Діяльність",
  BasisTariffTitle1: "Знайти",
  BasisTariffTitle2: "Ризики",
  BasisTariffTitle3: "{0} подій",
  BasisTariffTitle4: "Знайти",
  Bonuses: "Отримуйте бонуси",
  BuySubscription: "Купите підписку",
  CanSendIn: "Відправити",
  ChangeData: "Змінити",
  ChangePassword: "Змінити пароль",
  ChangingEmail_SendToNewEmail: "З повагою, {0} команда {1}",
  ChangingEmail_SendToNewEmailTitle: "Підтвердження",
  ChangingEmail_SendToOldEmail: "З повагою, {0} команда {1}",
  ChangingPassword: "З повагою, {0} команда {1}",
  ChangingPasswordForAdmin: "Новий пароль",
  CheckEmailRecoverPassword: "Відновити",
  ChooseFile: "Вибрати файл",
  ChooseStrongPassword: "Використовуйте",
  CommonSettings: "Налаштування",
  CommonSignIn: "Авторизація",
  CommonSignUp: "Реєстрація",
  Company: "Компанія",
  Confirm: "Підтвердити",
  ConfirmActivationTitle: "Вже зареєстровані? {0}Увійдіть в систему{1}.",
  ConfirmLicenseCreated_Mail: "Користувач",
  ConfirmLicenseUpdated_Mail: "Користувач",
  ConfirmRegister: "Підтвердження реєстрації нового користувача",
  ConfirmRegister_Mail: "Користувач",
  Consult:
    "Надішліть заявку, якщо вам потрібна консультація. Ми зв'яжемося з вами найближчим часом.",
  ControlMailDescription: "{0} порушень",
  ControlRegistrationTitle: "Контролюйте",
  ControlTariffDescription1: "{} організацій",
  ControlTariffDescription2: "Ризики",
  ControlTariffTitle1: "Діяльність",
  CurrentPassword: "Старий пароль",
  CurrentSubsriptions: "Поточні",
  DataProtected: "Дані захищені за міжнародним стандартом PCI DSS",
  Day: "1 день",
  DearUser: "Користувач",
  Demo: "Демо-доступ",
  DemoAccess: "Демо-доступ",
  DemoAccessDay: "Демо-доступ",
  DemoAccessFewDays: "Демо-доступ",
  DemoAccessManyDays: "Демо-доступ",
  Department: "Підрозділ ЦБ РФ",
  DifferenceInExchangeRates: "Сума",
  DigitalSignatureAndCertificates: "і",
  DigitalSignatureExpired: "Термін дії",
  DigitalSignatureWithdrawn: "Використовуйте",
  Edit: "Змінити",
  Email: "E-mail",
  EmailAlreadyExist: "на {0}",
  EmailOrPhone: "E-mail",
  EmailSignature: "команда Seldon.Basis",
  EndLicense: "До",
  EnterCode: "Введіть",
  EnterCodeConfirmPhone: "Повідомлення",
  EnterEmailOrPhoneNumber: "Адресу електронної пошти змінено.",
  EnterMailOrPhone: "Адресу електронної пошти змінено.",
  EnterYourEmail: "Вкажіть електронну пошту",
  ExceededRegisterPromo: "Кількість",
  FewDays: "2 дня",
  Finances: "Фінанси",
  FirstName: "Ім'я",
  ForgotPassword: "Забули пароль?",
  ForgotPasswordError: "Записи",
  FreeAccessDayProduct: "1 день",
  FreeAccessFewDays: "2 дня",
  FreeAccessFewDaysProduct: "2 дня",
  FreeAccessManyDays: "{0} днів",
  FreeAccessManyDaysProduct: "{0} днів",
  FreeAccessOneDay: "1 день",
  FreeAccessProduct: "Безкоштовний доступ до системи {0}",
  FutureLicense: "{0} ліцензія",
  FutureSubscribeOne: "Підписка",
  FutureSubscribesFew: "Ще",
  FutureSubscribesMany: "Ще",
  Gender: "Стать:",
  GenderFemale: "Жіноча",
  GenderMale: "Чоловіча",
  GetAccessToInfo: "інформації",
  GetDemoAccess: "Отримати демо-доступ",
  GetProfit: "Прибуток",
  GetProfitDescription: "Можливості",
  HavePromo: "У мене є промо-код",
  IncorrectEmailEntered: "Некоректний {0}",
  IncorrectPassword: "Пароль недійсний. Будь ласка, введіть правильний пароль.",
  IncorrectPasswordEntered: "Некоректний {0}",
  IncorrectPhoneEntered: "Некоректний {0}",
  LastName: "Прізвище",
  LicenseAgreement: "Ліцензійна угода",
  LicenseCountFew: "Ліцензії",
  LicenseCountMany: "Ліцензій",
  LicenseCountOne: "{0} ліцензія",
  LinkHasExpired: "Відновлення паролю",
  Login: "Логін",
  LoginPageHeader: "Вхід",
  LoginToAccess: "Логін",
  LogOff: "Вийти",
  ManageRisksDescription: "Замовників",
  ManyDays: "{0} днів",
  NewPassword: "Новий пароль",
  NewsMailDescription: "Тональність новин за сьогодні",
  NewsRegistrationTitle: "Компанії",
  NoActiveLicense: "Ліцензій",
  NotFound: "Не знайдено",
  NoTransactions: "Ні",
  OGRN: "ОДРН",
  OgrnOrInn: "ОДРН",
  OnDay: "Доступ до сервісу на 31 день",
  OnFewDays: "2 дня",
  OnlinePayment: "Онлайн",
  OnManyDays: "{0} днів",
  Password: "Пароль",
  PasswordChanged: "Пароль",
  PasswordHasAPartOfLoginName: "Пароль",
  PasswordMail: "Пароль",
  PasswordMustBeAtLeast8Symbols: "Пароль",
  PasswordMustContainsLettersAndDigits: "Пароль",
  PasswordNotUpToDate: "Пароль",
  PasswordRequirementsError: "Пароль",
  PasswordsMismatch: "Новий пароль",
  PaymentButton: "Перейти до оплати",
  PaymentMethod: "Спосіб оплати:",
  PaymentText_Mail: "Оплата",
  PersonalInfo: "Персональна інформація",
  Phone: "Телефон",
  PhoneNumberAlreadyExist: "на {0}",
  PhoneOrEmailBusy: "Користувач",
  PlatformsWithContracts: "з {0}",
  PleaseFillRequirementFields: "Всі",
  Position: "Посада",
  PriceMailDescription: "{0}{1} контракта{2}",
  PriceTariffDescription1: "Відповідає",
  PriceTariffDescription2: "Контракти",
  PriceTariffDescription3: "Підвищувальний коефіцієнт",
  PriceTariffTitle2: "Постачальників",
  PriceTariffTitle3: "Постачальників",
  PrivacyPolicy: "Політика",
  PrivacyPolicyAndOffer: "Перейти до оплати",
  PrivatePolicyRegister: "Персональні дані",
  ProductCountFew: "на {0}",
  ProductCountMany: "на {0}",
  ProductCountOne: "Продукт",
  Prolong: "Продовжити",
  ProMailDescription: "з {0}",
  PromoExpired: "Термін дії",
  PublicOffer:
    "Публічна оферта (далі Оферта) згідно п. 2 ст. 437 Цивільного кодексу Російської Федерації є офіційною пропозицією укласти з будь-якою юридичною, фізичною особою, індивідуальним підприємцем (далі Користувач) договір на умовах, передбачених Офертою. Договір укладається не інакше як шляхом приєднання до нього в цілому шляхом акцепту. Акцептом Оферти є сплата Користувачем ліцензійної винагороди. Договір при цьому вважається ув'язненим у письмовій формі і є рівносильним договору, підписаному двома",
  PurchasingRegions: "Регіони",
  RecoveryPassword: "Відновлення паролю",
  RecoveryPassword_Mail: "Користувач",
  RecoveryPassword2_Mail: "Пароль",
  Refill: "Поповнити баланс",
  Register: "Реєстрація",
  RegisterToStart: "Зареєструйтеся",
  RegistrationСompleted: "Реєстрація",
  RememberMe: "Запам'ятати",
  RenewSubscription: "Продовжити підписку",
  RepeatNewPassword: "Повторіть новий пароль",
  Resend: "Відправити",
  Restore: "Відновити",
  Save: "Зберегти",
  SavePasswordSuccess: "Пароль",
  SavePolicy: "Зберегти",
  SaveUnavailable: "Збереження...",
  Search: "Пошук",
  SelectedRegions: "Регіони",
  SelectRegion: "Регіон",
  Send: "Відправити",
  SendAgain: "Відправити",
  SendCode: "Код",
  SendPasswordOrCreateNew: "Телефон",
  SendSmsWithPassword:
    "На цей номер ми надішлемо SMS з паролем для входу в систему",
  SendToNumber: "Номер",
  Settings: "Налаштування",
  Show: "Показати",
  SignIn: "Увійти",
  SignInSystem: "Вже зареєстровані? {0}Увійдіть в систему{1}.",
  SignUpWithDS: "Реєстрація",
  SincerelyTeam: "З повагою, {0} команда {1}",
  SliderBasisInfo: "Зобов'язання, {0}",
  SliderNewsInfo: "Будьте в курсі",
  SliderPriceInfo: "Контракти",
  SliderProInfo: "з {0}",
  StartLicense: "з {0}",
  StartUsing: "Почати користуватись",
  Subscribe: "Оформити підписку не вдалося. Сталася помилка.\\n\\n%s",
  SupportMailLink: "Подробиці",
  SystemLanguage: "Мова в системі",
  Text403: "Сталася помилка",
  Text404: "Спробуйте",
  Text500: "На головну сторінку",
  TillDate: "До",
  Title404: "Сторінку не знайдено",
  Title500: "Сталася помилка",
  ToSaveTimeDescription: "Прозорість процедур",
  Total: "Разом: {0}",
  TryOtherProducts: "Спробуйте",
  UploadNewPhoto: "Завантажити інше фото",
  UserNotFound: "Користувач",
  WeNotFind: "Спробуйте",
  WeSentCodeConfirm: "Номер телефону",
  WrongOldPassword: "Пароль",
  WrongPassword: "Поточний пароль",
  YouCanFindInfoInBasis: "Знайти",
  YourLogin: "Логін"
};
export default dictionary;
