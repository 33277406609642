import * as types from "./action-types";
import axios from "axios";
import { bindActionCreators } from "redux";
import config from "config";

//Promo
export function deletePromocode() {
  return {
    type: types.DELETE_PROMOCODE
  };
}

export function addPromoError(error) {
  return {
    type: types.ADD_PROMOCODE_ERROR,
    error
  };
}

export function deletePromoError() {
  return {
    type: types.DELETE_PROMOCODE_ERROR
  };
}

export function addPromocode(promocode) {
  return {
    type: types.ADD_PROMOCODE,
    promocode
  };
}

export const applyPromo = (product, lang, promocode) => async dispatch => {
  try {
    let response = await axios.get(
      `${
        config.sulApi
      }/${lang}/Account/IsValidPromocodeWithDayCount?promocode=${encodeURI(
        promocode
      )}&initialProduct=${product}`
    );
    let promoValidate = response.data;
    let action = bindActionCreators(
      { addPromocodeResponse, addPromoError, deletePromoError },
      dispatch
    );

    if (promoValidate.Succeeded) {
      action.deletePromoError();
      action.addPromocodeResponse({
        text: promoValidate.Promocode.Text,
        crmId: promoValidate.Promocode.CrmId,
        demoDaysCount: promoValidate.DemoDaysCount
      });
    } else {
      action.addPromoError(promoValidate.Errors[0]);
    }
  } catch (e) {
    console.log(e);
  }
};

export function addPromocodeResponse(response) {
  return {
    type: types.ADD_PROMOCODE_RESPONSE,
    response
  };
}

export const getDefaultPromo = (lang, productName) => async dispatch => {
  //Запрашиваем количество дней стандартного промокода в случае продукта инициатора или отсутствия хотя бы одного успешного запроса на промокоды
  // try {
  //   let response = await axios.get(
  //     config.sulApi +
  //       `/${lang}/account/GetPromocodeDayCount?initialProduct=${productName}`
  //   ); //promocode=${encodeURI(promocode)}&
  //   let promoValidate = response.data;
  //   let action = bindActionCreators({ addPromocodeResponse }, dispatch);
  //   action.addPromocodeResponse({
  //     text: null,
  //     crmId: null,
  //     demoDaysCount: promoValidate.DemoDaysCount
  //   });
  //   console.log("wtf");
  // } catch (e) {
  //   // handle error
  //   console.log(e);
  // }
};
