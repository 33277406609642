/*
String.prototype.format = function () {
    var formatted = this;
    for( var arg in arguments ) {
        formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    }
    return formatted;
};*/

/*
class StringExt {
    format(format, ...args) {
        var formatted = format;
        for (var arg in args) {
            formatted = formatted.replace("{" + arg + "}", args[arg]);
        }
        return formatted;
    };
}*/

export const format = (formatted, ...args) => {
  for (var arg in args) {
    formatted = formatted.replace("{" + arg + "}", args[arg]);
  }
  return formatted;
};

export const isEmptyOrSpaces = str => {
  return str === null || str.match(/^ *$/) !== null;
};

export default format;
