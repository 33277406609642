import * as types from "./action-types";

const initialState = {
  promocode: null,
  promocodeError: null,
  promocodeSuccess: null
};

const promoReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_PROMOCODE:
      return { ...state, promocode: "", promocodeSuccess: null };
    case types.ADD_PROMOCODE_ERROR:
      return { ...state, promocodeError: action.error };
    case types.DELETE_PROMOCODE_ERROR:
      return { ...state, promocodeError: null };
    case types.ADD_PROMOCODE:
      return { ...state, promocode: action.promocode };
    case types.ADD_PROMOCODE_RESPONSE:
      return { ...state, promocodeSuccess: action.response };
    default:
      return state;
  }
};

export default promoReducer;
