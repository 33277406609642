// Library
import config from "config";
import {
  get,
  post
} from "../../infrastructure/processes-domain/simple/actions";

export const getAccessDemo = (data = {}) =>
  post(`${config.api}/api/Account/AccessDemo`, data);

export const getLandingTariffs = () =>
  get(`${config.api}/api/Payment/GetLandingTariffs`);
