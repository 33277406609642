// Library
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import classes from "classnames";

// UI
import ImgWrap from "@ui/ImgWrap";

const Wrapper = styled.div`
  &.blur,
  .blur {
    filter: blur(9px);
    opacity: 0.7;
  }
  p {
    font-size: 14px;
    line-height: 130%;
  }
`;

const enumDate = new Map([
  [1, "Действует 24 часа с момента предоставления доступа."],
  [2, "Действует 7 дней с момента предоставления доступа."],
  [3, "Действует 30 дней с момента предоставления доступа."]
]);

const SubscribeBlock = ({
  title,
  image,
  price,
  description,
  duration,
  link = "/",
  disable = true
}) => {
  const classnames = classes(
    "p-3 mb-3 mb-sm-0 bg-white rounded shadow text-center d-flex flex-column w-100",
    { blur: disable }
  );
  //disable ? null : link
  return (
    <Wrapper className={classnames}>
      <h4 className="text-center text-muted">
        <b>{title}</b>
      </h4>
      <hr />
      <ImgWrap src={image} alt="" />
      <div className="h2 mt-3">
        <b>{`${(+price).toLocaleString("ru-RU")} ₽`}</b>
      </div>
      <p className="mt-3">{description}</p>
      <p className="mt-1 mb-4">{enumDate.get(+duration)}</p>
      <Link
        className={`mt-auto ${disable ? "custom-disabled" : ""}`}
        to={disable ? "/" : link}
      >
        <button className={`btn btn-outline-primary w-100`} disabled={disable}>
          Перейти к оплате
        </button>
      </Link>
    </Wrapper>
  );
};

export default SubscribeBlock;
