import * as types from "./types";
import config from "config";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import * as spinnerAction from "../../infrastructure/processes-domain/spinner/actions";
import * as authAction from "../../infrastructure/processes-domain/auth/actions";
import * as axios from "../../infrastructure/auth/auth-axios";
import { isEmptyOrSpaces } from "../../extensions/stringExt";
import * as authStorage from "@inf/auth/auth-storage";
import * as authBrocker from "@inf/auth/auth-event-brocker";

export function addLoginUser(user) {
  return {
    type: types.ADD_LOGIN_USER,
    user
  };
}

/*
export function existsAuth(isAuth) {
  return {
    type: types.EXISTS_AUTH,
    isAuth
  };
}

export function relogin(redirect) {
  return {
    type: types.REDIRECT_TO_LOGIN,
    redirect
  };
}*/

export const authorize = (success = f => f, fail = f => f) => async (
  dispatch,
  getState
) => {
  //Если пользователь уже авторизован, то проверять заново не нужно
  let loginState = getState().loginState;
  if (loginState.isAuth) {
    success();
    return;
  }

  const action = bindActionCreators(
    {
      existsAuth: authAction.existsAuth,
      addLoginUser
      //updateAuthToken
    },
    dispatch
  );

  //Извлекаем token из хранилища
  //let token = authStorage.getAuthToken(); //..get; //loginState.token;
  /*if (!token) {
    token = localStorage.getItem("token");
    if (token) updateAuthToken(token);
  }*/

  try {
    let res = await axios.post(
      `${config.api}/api/account/checkAuth`,
      null,
      dispatch,
      getState
    );

    if (res.success) {
      action.existsAuth(true);

      //Получаем информацию о пользователе id, email, photo
      res = await axios.get(
        `${config.api}/api/account/authUserInfo`,
        null,
        dispatch,
        getState,
        true,
        false
      );
      if (res.success) {
        //let loginState = getState().loginState;
        action.addLoginUser(res.data);
        success();
      } else {
        console.log("Не удалось получить информацию о пользователе");
        fail();
      }
    } else {
      action.existsAuth(false);
      console.log("Пользователь не авторизован");
      fail();
    }
  } catch (e) {
    action.existsAuth(false);
    fail();
    console.log(e);
  } finally {
    //process();
  }
};

export const login = (
  login,
  password,
  rememberMe = false,
  success = f => f,
  error = f => f
) => async (dispatch, getState) => {
  const action = bindActionCreators(
    {
      addLoginUser,
      //updateAuthToken,
      existsAuth: authAction.existsAuth,
      relogin: authAction.relogin,
      loading: spinnerAction.loading
    },
    dispatch
  );

  try {
    action.loading(true);
    //Получаем acces_token для авторизации
    let model = { email: login, password: password, rememberMe: rememberMe };
    let res = await axios.post(
      `${config.api}/api/account/login`,
      model,
      dispatch,
      getState
    );

    if (res.success && res.data.isValid) {
      //let token = res.data.access_token;
      //action.updateAuthToken(token);
      //localStorage.setItem("token", token); //храним в Web Storage
      //authStorage.putAuthToken(token, rememberMe);
      action.existsAuth(true);
      action.relogin(false);

      //Получаем информацию о пользователе id, email, photo
      res = await axios.get(
        `${config.api}/api/account/authUserInfo`,
        null,
        dispatch,
        getState,
        true,
        false
      );
      if (res.success) {
        //let loginState = getState().loginState;
        action.addLoginUser(res.data);
        success();
      } else {
        error({ api: res.data.errors[0] });
      }
    } else {
      action.existsAuth(false);
      error({
        api:
          res.data && res.data.errorType === 0
            ? "Вы уже авторизованы"
            : "Электронная почта или пароль не верные"
      });
    }
  } catch (e) {
    action.existsAuth(false);
    error({ api: "Сервер не доступен. Попробуйте позже." });
    console.log(e);
  } finally {
    action.loading(false);
  }
};

export const loginUc = (success = f => f, error = f => f) => async (
  dispatch,
  getState
) => {
  const action = bindActionCreators(
    {
      // addLoginUser,
      //updateAuthToken,
      // existsAuth: authAction.existsAuth,
      // relogin: authAction.relogin,
      loading: spinnerAction.loading
    },
    dispatch
  );

  try {
    action.loading(true);
    //Получаем access_token для авторизации

    let res = await axios.get(
      `${config.api}/api/account/login-uc`,
      null,
      dispatch,
      getState
    );
    window.location.replace(res.data);

    if (res.success && res.data.isValid) {
      if (res.success) {
        //let loginState = getState().loginState;
        action.addLoginUser(res.data);
        success();
      } else {
        error({ api: res.data.errors[0] });
      }
    } else {
      action.existsAuth(false);
      error({
        api:
          res.data && res.data.errorType === 0
            ? "Вы уже авторизованы"
            : "Электронная почта или пароль не верные"
      });
    }
  } catch (e) {
    // action.existsAuth(false);
    error({ api: "Сервер не доступен. Попробуйте позже." });
    console.log(e);
  } finally {
    action.loading(false);
  }
};

export const logout = () => async dispatch => {
  const action = bindActionCreators(
    {
      //updateAuthToken,
      existsAuth: authAction.existsAuth,
      relogin: authAction.relogin
    },
    dispatch
  );

  //localStorage.removeItem("token");
  //action.updateAuthToken(null);
  //authStorage.removeAuthToken();
  //authBrocker.raiseAuthRemove();
  action.existsAuth(false);
  action.relogin(false);
};

export const getAuthUser = () => async (dispatch, getState) => {
  const action = bindActionCreators(
    {
      addLoginUser
    },
    dispatch
  );

  try {
    //Получаем информацию о пользователе id, email, photo
    let res = await axios.get(
      `${config.api}/api/account/authUserInfo`,
      null,
      dispatch,
      getState,
      true,
      false
    );
    if (res.success) {
      action.addLoginUser(res.data);
    } else {
      console.log(`Не определен пользователь`);
    }
  } catch (e) {
    console.log(e);
  }
};

export const sendRecoveryCode = (
  email,
  success = f => f,
  error = f => f
) => async (dispatch, getState) => {
  try {
    //Отправляем письмо с уведомлением
    let res = await axios.post(
      `${config.api}/api/account/ForgotPassword`,
      { email },
      dispatch,
      getState,
      false
    );
    if (res.success) {
      success();
    } else {
      error({ api: res.data.errors[0] });
    }
  } catch (e) {
    console.log(e);
    error({ api: "Не удалось отправить уведомление" });
  }
};

export const changePassword = (
  userId,
  code,
  newPassword,
  confirmPassword,
  success = f => f,
  error = f => f
) => async (dispatch, getState) => {
  try {
    //Изменяем пароль
    let res = await axios.post(
      `${config.api}/api/account/ChangePassword`,
      { userId, code, newPassword, confirmPassword },
      dispatch,
      getState,
      false
    );
    if (res.success) {
      success();
    } else {
      error({ api: res.data.errors[0] });
    }
  } catch (e) {
    console.log(e);
    error({ api: "Не удалось изменить пароль. Попробуйте позже." });
  }
};
