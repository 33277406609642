// Library
import React from "react";
import localization from "localization";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../assets/close.svg";

const { PART_ROUTE } = process.env;

const Wrapper = styled.div`
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
`;

const ErrorLogin = ({ lang }) => {
  const t = localization.getTranslations(lang);

  return (
    <Wrapper className="pt-2 pt-sm-1 pb-2">
      <Close />

      <p>Произошла ошибка авторизации, проверьте данные и повторите вход</p>

      <a
        className="btn btn-outline-light w-100 mt-1"
        href={`${PART_ROUTE}/${lang}/account/login`}
      >
        На страницу входа
      </a>
    </Wrapper>
  );
};

export default ErrorLogin;
