// Library
import React from "react";
import { Dropdown } from "react-bootstrap";
import localization from "localization";

// Style
import "./style.scss";

const partRoute = process.env.PART_ROUTE;

// Lang
const langs = [
  { text: "Български", value: "bg" },
  { text: "Deutsch", value: "de" },
  { text: "English", value: "en" },
  { text: "Español", value: "es" },
  { text: "Français", value: "fr" },
  { text: "Português", value: "pt" },
  { text: "Русский", value: "ru" },
  { text: "Українська", value: "uk" },
  { text: "Türkçe", value: "tr" },
  { text: "العربية", value: "ar" },
  { text: "日本語", value: "ja" },
  { text: "中文", value: "zh" }
];

const LangList = ({ currentLang, onChange }) => {
  return (
    <>
      {/* <div className="d-flex fs-13" id="culture-links">
        {langs
          .filter(l => localization.possibleLangList.some(p => p === l.value))
          .map((lang, index) => {
            return (
              <div
                key={index}
                className="mr-2"
                data-culture={lang.value}
                onClick={() => onChange(lang.value)}
              >
                <i className={`ico-language--${lang.value} mr-1`}></i>
                {lang.text}
              </div>
            );
          })}
      </div> */}

      <div className="lang-list d-flex justify-content-center fs-13">
        <div className="mr-2">©{new Date().getFullYear()}</div>

        <Dropdown drop="up">
          <Dropdown.Toggle as="div" className="lang-list__lang">
            <span>{currentLang.toUpperCase()}</span>

            <i className={`ico-language--${currentLang} ml-1`}></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="lang-list__menu">
            {langs
              .filter(l =>
                localization.possibleLangList.some(p => p === l.value)
              )
              .map(({ value }) => (
                <Dropdown.Item
                  as="button"
                  key={value}
                  onClick={() => onChange(value)}
                >
                  <i className={`ico-language--${value} mr-1`}></i>

                  {value.toUpperCase()}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default LangList;
