// Library
import React from "react";
import classnames from "classnames";

const ImgWrap = ({
  src,
  alt,
  className,
  classImg,
  style,
  styleImg,
  onClick
}) => (
  <div className={classnames([className])} onClick={onClick} style={style}>
    <img src={src} className={classImg} style={styleImg} alt={alt} />
  </div>
);

export default ImgWrap;
