import * as types from "./action-types";

export function addInitialProduct(initialProduct) {
  return {
    type: types.ADD_INITIAL_PRODUCT,
    initialProduct
  };
}

export function addPartnerId(partnerId) {
  return {
    type: types.ADD_PARTNER_ID,
    partnerId
  };
}
