// Library
import * as Yup from "yup";
import format from "extensions/stringExt";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Необходимо заполнить поле "Почта"')
    .matches(
      /([A-Za-z0-9_.-]+)@([a-z0-9.-]+\.[a-z]+)/,
      "Поле Почта заполнено некорректно"
    )
});

export default ValidationSchema;
