// Library
import React, { forwardRef, useState } from "react";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { ReactComponent as Eye } from "@images/eye.svg";

// UI
import Button from "../Button";
import s from "./Input.module.scss";

registerLocale("ru", ru);
setDefaultLocale("ru");
//https://stackoverflow.com/questions/53052012/set-timezone-react-datepicker
//https://momentjs.com/timezone/docs/
moment.tz.setDefault("Europe/Moscow");

const Input = forwardRef(
  (
    {
      className,
      classNameInput,
      error,
      touched,
      placeholder,
      label,
      size = "md",
      type = "text",
      value,
      onClick,
      ...props
    },
    ref
  ) => {
    let inputSize = "";
    let btnSize = "";

    switch (size) {
      case "sm":
        inputSize = "form-control-sm";
        btnSize = "btn-sm";
        break;

      case "md":
        btnSize = "btn-sm";
        break;

      case "lg":
        inputSize = "form-control-lg";
        btnSize = "btn-lg";
        break;

      default:
        break;
    }

    const [visiblePassword, setVisiblePassword] = useState(false);
    const classes = classnames([className, "d-flex", "flex-column"]);
    const classesInput = classnames([classNameInput]);

    return (
      <div className={classes}>
        {label && <label className="form-label mb-1">{label}</label>}

        <div className="d-flex">
          <div className="d-flex flex-column w-100">
            {type === "date" ? (
              <DatePicker
                ref={ref}
                {...props}
                className="form-control"
                selected={value}
                locale="ru"
                dateFormat="P"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                placeholderText={placeholder}
              />
            ) : (
              <div
                className={`${s["input-wrapper"]} ${
                  type === "password" ? s["input-wrapper_password"] : ""
                }`}
              >
                <input
                  ref={ref}
                  type={visiblePassword ? "text" : type}
                  className={`form-control ${classesInput} ${inputSize} ${
                    error && touched ? "is-invalid" : ""
                  }`}
                  {...props}
                  placeholder={placeholder}
                  value={value}
                />

                {type === "password" && (
                  <Eye
                    className={s["btn-eye"]}
                    onMouseUp={() => setVisiblePassword(false)}
                    onMouseDown={() => setVisiblePassword(true)}
                    onMouseLeave={() => setVisiblePassword(false)}
                  />
                )}
              </div>
            )}

            {error && touched && (
              <div className="invalid-feedback">{error}</div>
            )}
          </div>

          {typeof onClick === "function" && (
            <button
              className={`btn btn-info ml-1 ${btnSize}`}
              onClick={e => {
                e.preventDefault();
                onClick();
              }}
            ></button>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
