// Landing
import React from "react";
import { Link } from "react-router-dom";

// Image
import logo from "@images/landing/logo.svg";
import sposorIconDip from "@images/landing/sponsor-dip.svg";
import sposorIconRSPP from "@images/landing/sponsor-RSPP.svg";
import sposorIconSeldon from "@images/landing/sponsor-seldon.svg";
import sposorIconForargo from "@images/landing/sponsors-forargo.svg";

// UI
import ImgWrap from "@ui/ImgWrap";

const LandingFooter = ({ toSubscribeAnalytics, toSubscribeMarketplace }) => (
  <div className="py-4 py-sm-4" style={{ background: "#D8E0E7" }}>
    <div className="container">
      <div className="row">
        <div className="col-sm-3">
          <img src={logo} alt="" style={{ width: 269 }} />
        </div>
        <div className="col-sm-2 pl-3 pl-sm-0 my-3 my-sm-0 d-flex flex-column align-items-sm-end">
          <a
            href="https://rspp-centre.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Статистика
          </a>

          <a
            href="https://analytics.rspp-centre.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аналитика
          </a>

          <a
            href="https://trade.rspp-centre.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Площадка
          </a>
        </div>
        <div className="col-sm-3 pl-3 pl-sm-5">
          <div>Тех. поддержка</div>
          <a href="mailto:helpdesk@rspp-centre.com">helpdesk@rspp-centre.com</a>
          <div>8 (800) 201 72 55</div>
        </div>
        <div className="col-sm-4 px-3 px-sm-0 mt-3 mt-sm-0">
          <div>При поддержке</div>
          <div className="mt-2 d-flex justify-content-between">
            <a
              href="https://www.phosagro.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgWrap src={sposorIconForargo} />
            </a>
            <a
              href="https://company.myseldon.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgWrap src={sposorIconSeldon} />
            </a>
            <a
              href="https://www.dipacademy.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgWrap src={sposorIconDip} />
            </a>
            <a
              href="https://rspp-centre.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgWrap src={sposorIconRSPP} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LandingFooter;
