const dictionary = {
  " ActiveLicenses": "Lisanslar",
  " Apply": "Uygula",
  " GetAccess": "Erişimi elde et",
  " IncorrectPassword": "Yanlış şifre girdiniz. Lütfen doğru şifre girin.",
  AccessHalfYear: "için {0}",
  AccessMonth: "{0} ay",
  AccessThreeMonth: "{0} ay",
  AccessWeek: "için {0}",
  AccessYear: "{0} yıl",
  AccountWithTheSameDSAlreadyExist: "{0} kayıt",
  AccountWithTheSameEmailAlreadyExist: "{0} kayıt",
  Activate: "Etkinleştir",
  ActivationTextBasis1: "Bağlı kişiler",
  ActivationTextBasis2: "Kayıt verileri",
  ActivationTextBasis3: "Sorumluluklar, {0}",
  ActivationTextBasis4: "Bilanço",
  ActivationTextControl1: "Haberdar olun",
  ActivationTextControl2: "Organizasyon",
  ActivationTextControl3: "{0}{1} kontratlar{2}",
  ActivationTextNews1: "Bilgi",
  ActivationTextNews2:
    "Gerçek ve tüzel kişiler hakkında resmi kaynaklardan derlenen bilgilerden faydalanabilirsiniz",
  ActivationTextNews3: "Bugün",
  ActivationTextPrice1: "Değer",
  ActivationTextPrice2: "Kayıt sırasında belirttiğiniz e-posta kullanın",
  ActivationTextPrice3: "Fiyat",
  ActivationTextPro1: "Haberdar olun",
  ActivationTextPro3: "Mali durum",
  ActivationTextPro4: "Şirketler ({0})",
  ActivationTitleBasis1: "Bağlı kişiler",
  ActivationTitleBasis2: "PDF formatında EGRÜLden alıntı",
  ActivationTitleBasis3: "Tedarikçiler",
  ActivationTitleBasis4: "Hızlı analizi ",
  ActivationTitleControl1: "{0} {1} için",
  ActivationTitleControl2: "analiz",
  ActivationTitleNews1: "Şirketler ({0})",
  ActivationTitleNews3: "analiz",
  ActivationTitlePro1: "analiz",
  ActivationTitlePro2: "Tahmin",
  ActivationTitlePro3:
    "Güvenirlik indeksi, faaliyette bulunan ticari firmanın güvenli olup olmadığını değerlendirilmektedir.",
  ActivationTitlePro4: "Imkanlar ",
  ActiveFrom: "ile {0}",
  ActiveLicense: "{0} lisans",
  ActiveLicenseCountFew: "Lisanslar",
  ActiveLicenseCountMany: "Lisanslar",
  ActiveLicenseCountOne: "{0} lisans",
  ActualTill: "Geçerli {0} kadar",
  AddRemoveRegion: "Ekle",
  AdminPasswordReset: "Yeni şifre",
  AlreadyRegistered: "Üye misiniz? {0}Sisteme giriş yapınız{1}.",
  AtLeastThanEightSymbols: "{0} altında",
  AuthorizeWithDS: "Üye girişi",
  Balance: "Bilanço",
  BasisRegistrationTitle: "%1$s %2$s kontrol edebilirsiniz",
  BasisTariffDescription1: "Imkanlar ",
  BasisTariffDescription2: "Sorumluluklar, {0}",
  BasisTariffDescription3: "Organizasyon",
  BasisTariffDescription4: "Faaliyet",
  BasisTariffTitle1: "Bulmak",
  BasisTariffTitle2: "Riskler",
  BasisTariffTitle3: "{0} etkinlik",
  BasisTariffTitle4: "Bulmak",
  Bonuses: "Bonus kazanın",
  BuySubscription: "Aboneliği satın al",
  CanSendIn: "Göndermek",
  ChangeData: "Değiştirmek",
  ChangePassword: "Şifre değiştirmek",
  ChangingEmail_SendToNewEmail: "Saygılarımıza, {0} ekibi {1}",
  ChangingEmail_SendToNewEmailTitle: "Onay  ",
  ChangingEmail_SendToOldEmail: "Saygılarımıza, {0} ekibi {1}",
  ChangingPassword: "Saygılarımıza, {0} ekibi {1}",
  ChangingPasswordForAdmin: "Yeni şifre",
  CheckEmailRecoverPassword: "Geri almak",
  ChooseFile: "Dosya seçmek",
  ChooseStrongPassword: "Kayıt sırasında belirttiğiniz e-posta kullanın",
  CommonSettings: "Ayarlar",
  CommonSignIn: "Üye girişi",
  CommonSignUp: "Kayıt  ",
  Company: "Şirket",
  Confirm: "Onaylamak",
  ConfirmActivationTitle: "Üye misiniz? {0}Sisteme giriş yapınız{1}.",
  ConfirmLicenseCreated_Mail: "Kullanıcı  ",
  ConfirmLicenseUpdated_Mail: "Kullanıcı  ",
  ConfirmRegister: "Yeni kullanıcının kaydın onaylanması",
  ConfirmRegister_Mail: "Kullanıcı  ",
  Consult:
    "Danışmanlığa ihtiyacınız varsa başvuruda bulunun. Sizinle en yakın zamanda irtibata geçeriz.",
  ControlMailDescription: "{0} ihmal",
  ControlTariffDescription2: "Riskler",
  ControlTariffTitle1: "Faaliyet",
  CurrentPassword: "Eski şifre",
  CurrentSubsriptions: "Cari",
  DataProtected: "Veriler uluslararası PCI DSS standardına göre korunmaktadır",
  DearUser: "Kullanıcı  ",
  Demo: "Demo erişimi",
  DemoAccess: "Demo erişimi",
  DemoAccessDay: "Demo erişimi",
  DemoAccessFewDays: "Demo erişimi",
  DemoAccessManyDays: "Demo erişimi",
  Department: "Rusya Federasyonun",
  DifferenceInExchangeRates: "Tutar",
  DigitalSignatureAndCertificates: "ve",
  DigitalSignatureWithdrawn: "Kayıt sırasında belirttiğiniz e-posta kullanın",
  Edit: "Değiştirmek",
  Email: "Elektronik posta",
  EmailAlreadyExist: "için {0}",
  EmailOrPhone: "Elektronik posta",
  EmailSignature: "Seldon.Basis ekibi",
  EndLicense: "'a kadar",
  EnterCode: "Giriniz",
  EnterCodeConfirmPhone: "Mesajlarım",
  EnterEmailOrPhoneNumber: "Posta adresi değiştirilmiştir",
  EnterMailOrPhone: "Posta adresi değiştirilmiştir",
  EnterYourEmail: "E-posta adresinizi girin",
  ExceededRegisterPromo: "Miktar",
  Finances: "Finans",
  FirstName: "İsim",
  ForgotPassword: "Şifre mi unuttunuz? ",
  ForgotPasswordError: "Kayıtlar",
  FreeAccessManyDays: "{0} gündür",
  FreeAccessManyDaysProduct: "{0} gündür",
  FreeAccessProduct: "Sisteme ücretsiz ulaşım {0}",
  FutureLicense: "{0} lisans",
  FutureSubscribeOne: "Abonelik",
  FutureSubscribesFew: "Daha",
  FutureSubscribesMany: "Daha",
  GenderFemale: "Kadın",
  GenderMale: "Erkek",
  GetAccessToInfo: "bilgi  ",
  GetDemoAccess: "Demo erişimi elde etmek",
  GetProfit: "Kar",
  GetProfitDescription: "Imkanlar ",
  HavePromo: "Bende bir promosyon kodu var",
  IncorrectEmailEntered: "Hatalı {0}",
  IncorrectPassword: "Yanlış şifre girdiniz. Lütfen doğru şifre girin.",
  IncorrectPasswordEntered: "Hatalı {0}",
  IncorrectPhoneEntered: "Hatalı {0}",
  LastName: "Soyadı",
  LicenseAgreement: "Lisans anlaşması",
  LicenseCountFew: "Lisanslar",
  LicenseCountMany: "Lisanslar",
  LicenseCountOne: "{0} lisans",
  LinkHasExpired: "Şifre kurtarma",
  Login: "Kullanıcı adı",
  LoginPageHeader: "Sisteme giriş",
  LoginToAccess: "Kullanıcı adı",
  LogOff: "Çıkış yap",
  ManageRisksDescription: "Tedarikçi",
  ManyDays: "{0} gündür",
  NewPassword: "Yeni şifre",
  NewsMailDescription: "Bugün",
  NewsRegistrationTitle: "Şirketler ({0})",
  NoActiveLicense: "Lisanslar",
  NotFound: "İsteğiniz üzerine hiçbir şey bulunamadı",
  NoTransactions: "Hayır",
  OGRN: "OGRN",
  OgrnOrInn: "OGRN",
  OnDay: "Servise 31 günlük ulaşım",
  OnlinePayment: "Çevrimiçi",
  OnManyDays: "{0} gündür",
  Password: "Şifre",
  PasswordChanged: "Şifre",
  PasswordHasAPartOfLoginName: "Şifre",
  PasswordMail: "Şifre",
  PasswordMustBeAtLeast8Symbols: "Şifre",
  PasswordMustContainsLettersAndDigits: "Şifre",
  PasswordNotUpToDate: "Şifre",
  PasswordRequirementsError: "Şifre",
  PasswordsMismatch: "Yeni şifre",
  PaymentButton: "Ödemeye geç",
  PaymentMethod: "Ödeme şeklide:",
  PaymentText_Mail: "Ödeme",
  PersonalInfo: "Kişisel bilgi",
  Phone: "Telefon",
  PhoneNumberAlreadyExist: "için {0}",
  PhoneOrEmailBusy: "Kullanıcı  ",
  PlatformsWithContracts: "ile {0}",
  PleaseFillRequirementFields: "Tüm",
  Position: "Pozisyon",
  PriceMailDescription: "{0}{1} kontratlar{2}",
  PriceTariffDescription1: "Uyar",
  PriceTariffDescription2: "Kontratlar",
  PriceTariffDescription3: "Artırma kat sayısı",
  PriceTariffTitle2: "Tedarikçi",
  PriceTariffTitle3: "Tedarikçi",
  PrivacyPolicy: "Politika",
  PrivacyPolicyAndOffer: "Ödemeye geç",
  PrivatePolicyRegister: "Özel bilgiler",
  ProductCountFew: "için {0}",
  ProductCountMany: "için {0}",
  ProductCountOne: "Ürün",
  Prolong: "Uzat",
  ProMailDescription: "ile {0}",
  PublicOffer:
    "Açık teklif (bundan sonra Teklif) Rusya Federasyonunun Medeni Kanununun 437. maddesinin 2. bendine göre her hangi bir tüzel kişi, gerçek kişi, özel girişimci (bundan sonra Kullanıcı) ile  işbu teklif ile öngörülen şartlarda sözleşmeyi yapmak için bir tekliftir. İşbu Sözleşme tam olarak kabul edilerek yapılmaktadır. Kullanıcı tarafından lisans ücreti ödendikten sonra teklif kabul edilmiş sayılacak.  Sözleşme yazılı olarak yapılmış gibi ve taraflarca imzalanmış gibi sayılacaktır.",
  PurchasingRegions: "Bölgeler",
  RecoveryPassword: "Şifre kurtarma",
  RecoveryPassword_Mail: "Kullanıcı  ",
  RecoveryPassword2_Mail: "Şifre",
  Refill: "Para yükle",
  Register: "Kayıt  ",
  RegisterToStart: "Üye olun",
  RegistrationСompleted: "Kayıt  ",
  RememberMe: "Kaydetmek",
  RenewSubscription: "Aboneliği uzat",
  RepeatNewPassword: "Yeni şifreyi tekrar giriniz",
  Resend: "Göndermek",
  Restore: "Geri almak",
  Save: "Kaydetmek",
  SavePasswordSuccess: "Şifre",
  SavePolicy: "Kaydetmek",
  SaveUnavailable: "Kaydetme...",
  Search: "Arama",
  SelectedRegions: "Bölgeler",
  SelectRegion: "Bölge",
  Send: "Göndermek",
  SendAgain: "Göndermek",
  SendCode: "Kod",
  SendPasswordOrCreateNew: "Telefon",
  SendSmsWithPassword: "Yetki şifresi SMS ile bu numaraya gönderilecek",
  SendToNumber: "Numara",
  Settings: "Ayarlar",
  Show: "Göstermek",
  SignIn: "Giriş yap",
  SignInSystem: "Üye misiniz? {0}Sisteme giriş yapınız{1}.",
  SignUpWithDS: "Kayıt  ",
  SincerelyTeam: "Saygılarımıza, {0} ekibi {1}",
  SliderBasisInfo: "Sorumluluklar, {0}",
  SliderNewsInfo: "Haberdar olun",
  SliderPriceInfo: "Kontratlar",
  SliderProInfo: "ile {0}",
  StartLicense: "ile {0}",
  StartUsing: "Kullanmaya başlamak",
  Subscribe: "Abonelik satın alınamadı. Hata oluştu.\\n\\n%s",
  SupportMailLink: "Daha detaylı",
  SystemLanguage: "Sistemde dil",
  Text403: "Hata oluştu.",
  Text404: "Deneyiniz",
  Text500: "Ana sayfaya",
  TillDate: "'a kadar",
  Title404: "Sayfa bulunamamıştır",
  Title500: "Hata oluştu.",
  ToSaveTimeDescription: "Prosedürlerin saydamlığı",
  Total: "Toplam: {0}",
  TryOtherProducts: "Deneyiniz",
  UploadNewPhoto: "Başka bir fotoğraf yüklemek ",
  UserNotFound: "Kullanıcı  ",
  WeNotFind: "Deneyiniz",
  WeSentCodeConfirm: "Telefon numarası",
  WrongOldPassword: "Şifre",
  WrongPassword: "Güncel şifre",
  YouCanFindInfoInBasis: "Bulmak",
  YourLogin: "Kullanıcı adı"
};
export default dictionary;
