import React from "react";

const paymentDescription = new Map([
  [
    2,
    <>
      <h6>
        <b>Заключать сделки онлайн</b>
      </h6>
      <p className="mb-4">
        Просто загрузите документы, сервис сам заполнит и отправит необходимые
        формы. Финансовые операции осуществляются через номинальный счет и
        площадку с эскроу-агентом при поддержке банка НКБ.
      </p>
      <h6>
        <b>Договариваться с бизнес-партнерами</b>
      </h6>
      <p className="mb-4">
        Общайтесь и договаривайтесь с продавцами и покупателями напрямую, в том
        числе в форме групповых конференций. Упрощенная коммуникация сэкономит
        время и позволит принимать быстрые и взвешенные решения.
      </p>
      <h6>
        <b>Создавать собственные объявления</b>
      </h6>
      <p className="mb-4">
        Оформляйте лоты максимально быстро, с использованием классификаторов.
        Продавайте по выгодным ценами без рекламных задержек.
      </p>
    </>
  ],
  [
    3,
    <>
      <h6>
        <b>Изучать полную и актуальную информацию</b>
      </h6>
      <p className="mb-4">
        Анализируйте экономические и прочие показатели как по РФ в целом, так и
        по каждому отдельно взятому региону.
      </p>
      <h6>
        <b>Моделировать ситуации, прогнозировать развитие событий</b>
      </h6>
      <p className="mb-4">
        В том числе связанные с коронавирусной инфекцией и социальные, такие как
        уровень бедности, индекс потребительских цен, доходы и расходов
        домохозяйств и т.д.
      </p>
      <h6>
        <b>Получать новостную информацию в видео- и аудиоформатах</b>
      </h6>
      <p className="mb-4">
        Мультимедийный контент помогает оперативно и наглядно получать
        представление о новостях, событиях в экономической, социальной сферах и
        здравоохранении.
      </p>
    </>
  ]
]);

export default paymentDescription;
