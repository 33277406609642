// Library
import React, { useEffect } from "react";
import Recaptcha from "react-recaptcha";

// Config
import config from "config";

const PaymentCaptcha = ({ addRecaptcha }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Recaptcha
      sitekey={config.recaptchaClientKey}
      render="explicit"
      hl="ru"
      verifyCallback={addRecaptcha}
      onloadCallback={() => {
        console.log("done loading!");
      }}
    />
  );
};

export default PaymentCaptcha;
