// Library
import React from "react";
import { Link } from "react-router-dom";

// UI
import Buttom from "@ui/Button";
import ImgWrap from "@ui/ImgWrap";

// Image
import logo from "@images/landing/logo.svg";

const LandingHeader = ({
  toTestAccess,
  toSubscribeAnalytics,
  toSubscribeMarketplace
}) => (
  <div className="container d-none d-md-block">
    <div className="row d-flex align-items-center" style={{ height: 100 }}>
      <div className="col-2">
        <a
          href="https://rspp-centre.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ImgWrap src={logo} alt="" style={{ width: 154 }} />
        </a>
      </div>
      <div className="col-7 d-flex">
        <div
          className="mx-4 text-dark cursor-pointer"
          onClick={() => toSubscribeAnalytics()}
        >
          Аналитика
        </div>
        <div
          className="mx-4 text-dark cursor-pointer"
          onClick={() => toSubscribeMarketplace()}
        >
          Торговая площадка
        </div>
      </div>
      <div className="col-3 d-flex justify-content-end">
        <Buttom
          className="btn btn-outline-warning btn-warning-landing"
          onClick={() => toTestAccess()}
        >
          Попробовать бесплатно
        </Buttom>
      </div>
    </div>
  </div>
);

export default LandingHeader;
