// Library
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Component
import Payment from "../views/Payment";

// Action
import * as paymentAction from "../../../processes-domain/payment/actions";
import * as accountAction from "../../../processes-domain/account/actions";
import * as globalAction from "../../../processes-domain/global/global-actions";

const { PART_ROUTE } = process.env;

const mapStateToProps = store => {
  return { recaptcha: store.registerState.recaptcha };
};

const mapDispatchToProps = dispatch => {
  return {
    getTariff: bindActionCreators(paymentAction, dispatch).getTariff,
    checkAuth: bindActionCreators(accountAction, dispatch).checkAuth,
    checkPayment: bindActionCreators(paymentAction, dispatch).checkPayment,
    setSuccessModalShow: bindActionCreators(globalAction, dispatch)
      .setSuccessModalShow,
    setErrorModalShow: bindActionCreators(globalAction, dispatch)
      .setErrorModalShow,
    createPayment: async (values, actions, history, pathname, recaptcha) => {
      const model = {
        email: values.email ? values.email : null,
        tariffId: values.id,
        method: values.method,
        returnUrl: window.location.origin + pathname,
        recaptcha: values.recaptcha
      };

      let res = await bindActionCreators(paymentAction, dispatch).createPayment(
        model
      );

      //Success;
      if (!res.success) {
        actions.setErrors({
          api: res.errors[0]
        });
        actions.setSubmitting(false);
        return res;
      }

      if (res.success) document.location.href = res.confirmationUrl;
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payment)
);
