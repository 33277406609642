// Landing
import React from "react";
import styled from "styled-components";

// Images
import bgLight from "@images/landing/bg-test-access-light.jpg";
import bgDark from "@images/landing/bg-test-access-dark.jpg";

const Wrapper = styled.div.attrs(props => ({
  bgImage: props.theme === "light" ? bgLight : bgDark
}))`
  background: url(${props => props.bgImage}) no-repeat;
  background-size: cover;
`;

const LandingTestAccess = ({ theme = "light", inputRef, children, anchor }) => (
  <Wrapper theme={theme} className="pt-4 pt-sm-5 pb-3">
    <div className="container" ref={inputRef}>
      <h2
        className={`text-left text-sm-center ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
        id={anchor}
      >
        <b>Получите тестовый доступ</b>
      </h2>
      <p
        className={`h5 mt-2 text-left text-sm-center ${
          theme === "dark" ? "text-white" : ""
        }`}
      >
        Оставьте заявку, мы свяжемся с вами и предоставим бесплатный{" "}
        <br className="d-none d-md-block" />
        пробный доступ на 72 часа.
      </p>

      <div className="row justify-content-md-center">
        <div className="col-sm-9">{children}</div>
      </div>

      <p className={`text-center ${theme === "dark" ? "text-secondary" : ""}`}>
        Указывая свои персональные данные, вы соглашаетесь на их обработку в
        соответствии с{" "}
        <a
          className={`${
            theme === "dark" ? "link-secondary" : "link-primary"
          } text-decoration-underline`}
          href={`/docs/policy.docx`}
        >
          Политикой конфиденциальности.
        </a>
      </p>
    </div>
  </Wrapper>
);

export default LandingTestAccess;
