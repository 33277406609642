// Landing
import React from "react";

// Component
import LandingSliderLaptop from "../LandingSliderLaptop";

// Image
import mainBg from "@images/landing/pattern.jpg";

const LandingSliderWhy = () => (
  <div
    className="py-5 bg-light"
    style={{ background: `url(${mainBg})`, backgroundSize: "cover" }}
  >
    <h2 className="px-2 px-sm-0 text-sm-center text-black">
      <b>Для чего торговая площадка</b>
    </h2>
    <p className="px-2 px-sm-0 h5 text-sm-center">
      Торговая площадка позволяет находить деловых партнеров и заключать сделки
      в удобной для вас форме.
    </p>
    <div className="mt-4">
      <LandingSliderLaptop theme="light" />
    </div>
  </div>
);

export default LandingSliderWhy;
