// Library
import config from "config";
import {
  get,
  post
} from "../../infrastructure/processes-domain/simple/actions";

export const getTariff = (id = 0) =>
  get(`${config.api}/api/Payment/GetTariff`, { id });

export const createPayment = payment =>
  post(`${config.api}/api/Payment/Create`, payment);

export const checkPayment = guid =>
  get(`${config.api}/api/Payment/Check`, { guid });
