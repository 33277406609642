// Library
import React from "react";
import styled from "styled-components";
import classnames from "classnames";

// UI
import ImgWrap from "@ui/ImgWrap";

// Tool
import gridSize from "@tools/grid-size";

const Wrapper = styled.div.attrs(() => gridSize)`
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      box-shadow: 0px 7px 10px -7px var(--bs-warning);
      transition: 0.3s;
    }
  }

  input[type="radio"]:focus + label {
    box-shadow: 0 0 0 0.25rem rgba(2, 88, 185, 0.25);
  }
  @media (max-width: ${props => props.sm}) {
    label {
      height: 39px;
    }
  }
  @media (min-width: ${props => props.md}) {
    label {
      height: 55px;
    }
  }
  @media (min-width: ${props => props.xl}) {
    label {
      width: 154px;
      height: 55px;
    }
  }
`;

const RadioPaymentMethod = ({
  className,
  image,
  active,
  name = "radio",
  value,
  onClick
}) => {
  const classNames = classnames([className]);
  return (
    <Wrapper className={classNames} onClick={onClick}>
      <input
        className="position-absolute"
        style={{ opacity: 0 }}
        type="radio"
        name={name}
        value={value}
        checked={active ? "checked" : ""}
        onChange={onClick}
      />
      <label
        className={`p-1 border cursor-pointer ${
          active ? "border-warning active" : "border-secondary"
        }`}
      >
        <ImgWrap classImg="img-fluid" src={image} />
      </label>
    </Wrapper>
  );
};

export default RadioPaymentMethod;
