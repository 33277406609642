const dictionary = {
  " ActiveLicenses": "Лицензиялар",
  " Apply": "Қолдану",
  " IncorrectPassword":
    "Дұрыс емес құпия сөз енгізілді. Дұрыс құпия сөзді енгізіңіз.",
  AccessHalfYear: "{0} жылға",
  AccessMonth: "{0} ай",
  AccessThreeMonth: "{0} ай",
  AccessWeek: "{0} жылға",
  AccessYear: "{0} жыл",
  AccountWithTheSameDSAlreadyExist: "№ {0} РДЛ жазбасы",
  AccountWithTheSameEmailAlreadyExist: "№ {0} РДЛ жазбасы",
  Activate: "Активтендіру",
  ActivationTextBasis1: "Үлестестік",
  ActivationTextBasis2: "Тіркеу деректері",
  ActivationTextBasis3: "Міндеттемелер, {0}",
  ActivationTextBasis4: "Бухгалтерлік теңгерім",
  ActivationTextControl2: "Ұйымдар",
  ActivationTextControl3: "{0}{1} келісім-шарт{2}",
  ActivationTextPrice1: "Құны",
  ActivationTextPro3: "Қаржылық жағдайы",
  ActivationTitleBasis1: "Үлестестік",
  ActivationTitleBasis2: "PDF-тегі БМЗТТ-ден үзінді-көшірме ",
  ActivationTitleBasis3: "Жеткізушілер.",
  ActivationTitleBasis4: "Экспресс-талдау",
  ActivationTitleControl1: "{1}-ге {0}",
  ActivationTitlePro1: "талдау",
  ActivationTitlePro2: "Болжам",
  ActivationTitlePro3:
    "Сенімділік индексі — бұл әрекет етуші коммерциялық компанияның сенімділік бағасы.",
  ActivationTitlePro4: "Мүмкіндіктер",
  ActiveFrom: "{0} бастап",
  ActiveLicense: "{0} лицензия",
  ActiveLicenseCountFew: "Лицензиялар",
  ActiveLicenseCountMany: "Лицензия",
  ActiveLicenseCountOne: "{0} лицензия",
  ActualTill: "{0} дейін жарамды",
  AdminPasswordReset: "Жаңа құпия сөз",
  AtLeastThanEightSymbols: "{0} аз",
  Balance: "Теңгерім",
  BasisTariffDescription1: "Мүмкіндіктер",
  BasisTariffDescription2: "Міндеттемелер, {0}",
  BasisTariffDescription3: "Ұйымдар",
  BasisTariffDescription4: "Қызметі",
  BasisTariffTitle2: "Қауіп-қатерлер",
  BasisTariffTitle3: "{0} оқиға",
  Bonuses: "Бонустар алыңыз",
  BuySubscription: "Жазылуды сатып алу",
  CanSendIn: "Жіберу",
  ChangeData: "Өзгерту",
  ChangePassword: "Құпия сөзді өзгерту",
  ChangingPasswordForAdmin: "Жаңа құпия сөз",
  CheckEmailRecoverPassword: "Қалпына келтіру",
  ChooseFile: "Файлды таңдау",
  CommonSettings: "Баптаулар",
  CommonSignUp: "Тіркелу",
  Company: "Компания",
  Confirm: "Растау",
  ConfirmLicenseCreated_Mail: "Пайдаланушы",
  ConfirmLicenseUpdated_Mail: "Пайдаланушы",
  ConfirmRegister_Mail: "Пайдаланушы",
  Consult:
    "Егер сізге консультация керек болса, өтінім жіберіңіз. Біз сізбен жақын арада байланысамыз. ",
  ControlMailDescription: "Бұзушылықтар жоқ",
  ControlTariffDescription2: "Қауіп-қатерлер",
  ControlTariffTitle1: "Қызметі",
  CurrentPassword: "Ескі құпия сөз",
  CurrentSubsriptions: "Ағымдағы",
  DearUser: "Пайдаланушы",
  Demo: "Демо-қолжетімдік",
  DemoAccess: "Демо-қолжетімдік",
  DemoAccessDay: "Демо-қолжетімдік",
  DemoAccessFewDays: "Демо-қолжетімдік",
  DemoAccessManyDays: "Демо-қолжетімдік",
  DigitalSignatureAndCertificates: "және",
  Edit: "Өзгерту",
  Email: "Электронды пошта",
  EmailAlreadyExist: "{0} жылға",
  EmailOrPhone: "Электронды пошта",
  EmailSignature: "Seldon.Basis командасы",
  EndLicense: "дейін",
  EnterCode: "Енгізіңіз",
  EnterYourEmail: "Электронды поштаны көрсетіңіз",
  FirstName: "Аты",
  ForgotPassword: "Құпия сөзді ұмытып қалдыңыз ба?",
  FreeAccessManyDays: "{0} күн",
  FreeAccessManyDaysProduct: "{0} күн",
  FreeAccessProduct: "Жүйеге {0} тегін қолжетімдік",
  FutureLicense: "{0} лицензия",
  FutureSubscribeOne: "Жазылу",
  GenderFemale: "Әйел",
  GenderMale: "Ер",
  GetAccessToInfo: "ақпараттар",
  GetDemoAccess: "Демо-қолжетімдік алу",
  GetProfit: "Кіріс",
  GetProfitDescription: "Мүмкіндіктер",
  HavePromo: "Менде промо-код бар",
  IncorrectEmailEntered: "Дұрыс емес {0}",
  IncorrectPassword:
    "Дұрыс емес құпия сөз енгізілді. Дұрыс құпия сөзді енгізіңіз.",
  IncorrectPasswordEntered: "Дұрыс емес {0}",
  IncorrectPhoneEntered: "Дұрыс емес {0}",
  LastName: "Тегі",
  LicenseAgreement: "Лицензиялық келісім",
  LicenseCountFew: "Лицензиялар",
  LicenseCountMany: "Лицензия",
  LicenseCountOne: "{0} лицензия",
  LinkHasExpired: "Құпия сөзді қалпына келтіру",
  LogOff: "Шығу",
  ManageRisksDescription: "Тапсырыс берушілер",
  ManyDays: "{0} күн",
  NewPassword: "Жаңа құпия сөз",
  NoActiveLicense: "Лицензия",
  NotFound: "Ештеңе табылмады",
  OGRN: "НМТН",
  OgrnOrInn: "НМТН",
  OnManyDays: "{0} күн",
  Password: "Құпия сөз",
  PasswordChanged: "Құпия сөз",
  PasswordHasAPartOfLoginName: "Құпия сөз",
  PasswordMail: "Құпия сөз",
  PasswordMustBeAtLeast8Symbols: "Құпия сөз",
  PasswordMustContainsLettersAndDigits: "Құпия сөз",
  PasswordNotUpToDate: "Құпия сөз",
  PasswordRequirementsError: "Құпия сөз",
  PasswordsMismatch: "Жаңа құпия сөз",
  PaymentText_Mail: "Төлем",
  Phone: "Телефон",
  PhoneNumberAlreadyExist: "{0} жылға",
  PhoneOrEmailBusy: "Пайдаланушы",
  PlatformsWithContracts: "{0} бастап",
  Position: "Лауазымы",
  PriceMailDescription: "{0}{1} келісім-шарт{2}",
  PriceTariffDescription1: "Сәйкес келеді",
  PriceTariffDescription2: "Келісім-шарттар",
  PriceTariffTitle2: "Жеткізушілер",
  PriceTariffTitle3: "Жеткізушілер",
  PrivatePolicyRegister: "Дербес деректер",
  ProductCountFew: "{0} жылға",
  ProductCountMany: "{0} жылға",
  ProductCountOne: "Өнім",
  Prolong: "Ұзарту",
  ProMailDescription: "{0} бастап",
  RecoveryPassword: "Құпия сөзді қалпына келтіру",
  RecoveryPassword_Mail: "Пайдаланушы",
  RecoveryPassword2_Mail: "Құпия сөз",
  Register: "Тіркелу",
  RegisterToStart: "Тіркеліңіз",
  RegistrationСompleted: "Тіркелу",
  RenewSubscription: "Жазылуды ұзарту",
  RepeatNewPassword: "Жаңа құпия сөзді қайталаңыз",
  Resend: "Жіберу",
  Restore: "Қалпына келтіру",
  SavePasswordSuccess: "Құпия сөз",
  SaveUnavailable: "Сақталып жатыр...",
  Search: "Іздеу",
  SelectRegion: "Аймақ",
  Send: "Жіберу",
  SendAgain: "Жіберу",
  SendCode: "Код",
  SendPasswordOrCreateNew: "Телефон",
  SendSmsWithPassword:
    "Біз осы нөмірге жүйеге кіруге арналған құпия сөзі бар SMS жібереміз",
  SendToNumber: "Нөмір",
  Settings: "Баптаулар",
  Show: "Көрсету",
  SignIn: "Кіру",
  SignUpWithDS: "Тіркелу",
  SliderBasisInfo: "Міндеттемелер, {0}",
  SliderPriceInfo: "Келісім-шарттар",
  SliderProInfo: "{0} бастап",
  StartLicense: "{0} бастап",
  Subscribe: "Жазылу рәсімделген жоқ. Қателік туындады.\\n\\n%s",
  SupportMailLink: "Толығырақ",
  SystemLanguage: "Жүйедегі тіл",
  Text403: "Қателік туындады",
  Text404: "Байқап көріңіз",
  Text500: "Басты бетке",
  TillDate: "дейін",
  Title404: "Бет табылған жоқ",
  Title500: "Қателік туындады",
  ToSaveTimeDescription: "Процедураның ашықтығы",
  TryOtherProducts: "Байқап көріңіз",
  UploadNewPhoto: "Басқа фото жүктеу",
  UserNotFound: "Пайдаланушы",
  WeNotFind: "Байқап көріңіз",
  WeSentCodeConfirm: "Телефон нөмірін енгізіңіз",
  WrongPassword: "Ағымдағы құпия сөз"
};
export default dictionary;
