import * as axios from "../../auth/auth-axios";

export const get = (url, params, contentType) => async (dispatch, getState) => {
  let { data, success } = await axios.get(url, params, dispatch, getState);

  if (success) {
    return data;
  } else {
    throw new Error("Не удалось получить данные");
  }
};

export const getWithContentType = (url, params, contentType) => async (
  dispatch,
  getState
) => {
  let { data, success } = await axios.get(
    url,
    params,
    dispatch,
    getState,
    undefined,
    undefined,
    undefined,
    undefined,
    contentType
  );

  if (success) {
    return data;
  } else {
    throw new Error("Не удалось получить данные");
  }
};

export const getWithResponseType = (url, params, responseType) => async (
  dispatch,
  getState
) => {
  let { data, success } = await axios.get(
    url,
    params,
    dispatch,
    getState,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    responseType
  );

  if (success) {
    return data;
  } else {
    throw new Error("Не удалось получить данные");
  }
};

export const getMultiple = requests => async (dispatch, getState) => {
  let requestProcesses = [];
  let responses = [];

  for (const request of requests) {
    let promise = axios.get(request.url, request.params, dispatch, getState);

    requestProcesses.push({ request: request, promise: promise });
  }

  for (const process of requestProcesses) {
    let { data, success } = await process.promise;

    responses.push({
      request: { ...process.request },
      response: data,
      success: success
    });

    if (!success) {
      console.error("Не удалось получить данные. url: " + process.request.url);
    }
  }

  return responses;
};

export const post = (url, params) => async (dispatch, getState) => {
  let { data, success } = await axios.post(url, params, dispatch, getState);

  if (success) {
    return data;
  } else {
    throw new Error("Не удалось получить данные");
  }
};

export const postWithContentType = (url, params, contentType) => async (
  dispatch,
  getState
) => {
  let { data, success } = await axios.post(
    url,
    params,
    dispatch,
    getState,
    undefined,
    undefined,
    undefined,
    undefined,
    contentType
  );

  if (success) {
    return data;
  } else {
    throw new Error("Не удалось получить данные");
  }
};

export const postWithResponseType = (url, params, responseType) => async (
  dispatch,
  getState
) => {
  let { data, success } = await axios.post(
    url,
    params,
    dispatch,
    getState,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    responseType
  );

  if (success) {
    return data;
  } else {
    throw new Error("Не удалось получить данные");
  }
};
