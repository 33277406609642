const dictionary = {
  " ActiveLicenses": "有效的订阅",
  " Apply": "应用",
  " GetAccess": "得到访问权限",
  " IncorrectPassword": "验证码错误",
  " LoginCredentials": "访问系统的用户识别数据：",
  " MinLengthFew": "{0}字段必须包含至少{1}个字符",
  AccessDateActivation: "激活了访问从 {0} 到 {1}",
  AccessDay: "一天访问到{0}所有能力",
  AccessHalfYear: "半年访问到{0}所有能力",
  AccessMonth: "一个月访问到{0}所有能力",
  AccessThreeMonth: "三个月访问到{0}所有能力",
  AccessWeek: "一周访问到{0}所有能力",
  AccessYear: "一年访问到{0}所有能力",
  AccountAlreadyContainsThisVIPCode: "该帐户已包含此VIP促销代码",
  AccountWithTheSameDSAlreadyExist: "已存在此数字签名的帐户",
  AccountWithTheSameEmailAlreadyExist: "已存在此电子邮件地址的帐户",
  Activate: "激活",
  ActivationConfirmation: "确认激活",
  ActivationResult: "激活的结果",
  ActivationTextBasis1:
    "了解公司之间的关系，在交互示意图上《关系树》检查公司是不是关联的。",
  ActivationTextBasis2:
    "了解公司和领导机关的登记资料，检查地址和电话号码的大量性。",
  ActivationTextBasis3:
    "我们让您了解公司和个体经营者签订的国家订购合同。我们知道他们多么认真地履行合同义务。",
  ActivationTextBasis4:
    "我们发布财务报表和计算财务指标：流动性、支付能力、财务稳定性、利润性、净资产。",
  ActivationTextControl1:
    "根据联邦法44号与联邦法223号了解采购活动的透明性和公平性。",
  ActivationTextControl2:
    "综合评估组织的采购活动。有关组织的复杂信息。 预算开发财务的节奏和采购效率，风险指标。",
  ActivationTextControl3:
    "及时通知投资活动的各个方面：进行计划采购、延迟项目、无法签订合同等等",
  ActivationTextNews1: "了解全世界的，国家的，行业的现实新闻。",
  ActivationTextNews2:
    "您设置个人信息流以后，能收到您关键的新闻：客户和竞争对手现实的信息。",
  ActivationTextNews3:
    "了解隐藏的信息。新闻的色彩，公司和个人的档案，媒体对他们面貌的影响。一周7天24个小时最仔细的分析",
  ActivationTextPrice1:
    "为了您的采购总是有申请，请考虑定价区间和提出有竞争能力的价格",
  ActivationTextPrice2: "考虑到采购的不同条件，使用指标来达到合理的合同价格。",
  ActivationTextPrice3:
    "通过理由证实并符合法律要求，您合同的价格会很快吸引供应商。",
  ActivationTextPro1: "检查商品和服务的价格和需求，通过微件了解新的采购",
  ActivationTextPro2:
    "参与最大胜利机会的拍卖。按照拍卖的历史，评估潜在的参与人等等创作个人的胜利预测。",
  ActivationTextPro3:
    "人工智能将评估订购人的财务状况、采购与仲裁案历史、以便您知道与谁一起工作。",
  ActivationTextPro4:
    "系统在公司数据的基础上自动选择适当的投标，以便你不花时间进行独立搜索。",
  ActivationTitleBasis1: "联系与关联",
  ActivationTitleBasis2: "俄罗斯联邦统一法人国家登记簿摘要",
  ActivationTitleBasis3: "合同与不诚实的供应商",
  ActivationTitleBasis4: "余额与快速分析",
  ActivationTitleControl1: "注意违规行为",
  ActivationTitleControl2: "分析采购活动",
  ActivationTitleControl3: "监控事件",
  ActivationTitleNews1: "来自世界各地的新闻",
  ActivationTitleNews2: "个人消息流",
  ActivationTitleNews3: "分析和人工智能",
  ActivationTitlePrice1: "定价区间",
  ActivationTitlePrice2: "纠正价格",
  ActivationTitlePrice3: "确定价格",
  ActivationTitlePro1: "分析拍卖",
  ActivationTitlePro2: "预测胜利",
  ActivationTitlePro3: "可靠性评价",
  ActivationTitlePro4: "最佳的采购",
  ActiveFrom: "开始日期",
  ActiveLicense: "有效的订阅",
  ActiveLicenseCountFew: "{0}个有效的订阅",
  ActiveLicenseCountMany: "{0}个有效的订阅",
  ActiveLicenseCountOne: "{0}个有效的订阅",
  ActualTill: "有效期至",
  AddRemoveRegion: "添加/删除区域",
  AdminPasswordReset:
    "尊敬的用户！{0}您的密码已被管理员重置。要设置新密码，请转到{1}链接{2}！{3}祝好，{4}",
  AfterPayment: "付款后，您将能够：",
  AlreadyRegistered: "已注册吗？",
  AtLeastThanEightSymbols: "至少为8个字符",
  AuthorizeWithDS: "用数字签名授权",
  Balance: "平衡",
  BasisMailDescription: "分析和评价俄罗斯和国外公司的服务",
  BasisRegistrationTitle:
    "您可以观察自己的合作伙伴，找到新的客户，了解他们的优点和弱点。",
  BasisTariffDescription1:
    "2400万俄罗斯公司和企业家将在您身边。他们的能力和需求、长处和短处。按名称、细节信息和联系方便的搜索。",
  BasisTariffDescription2:
    "研究组织的档案。看看您的未来合作伙伴如何履行其财务、合同和法律义务。",
  BasisTariffDescription3:
    "接收有关公司生活中的事件的通知。Seldon.Basis会警告并显示有什么不对您的合作伙伴或竞争对手。使用统计和分析来对于这家组织得出认识。",
  BasisTariffDescription4:
    "研究来自独联体国家的公司的活动。检查他们公平参与投标、分析他们财务状况。拓展业务：与外国合作伙伴合作。",
  BasisTariffTitle1: "找到新客户",
  BasisTariffTitle2: "最大限度地降低风险",
  BasisTariffTitle3: "了解情况",
  BasisTariffTitle4: "找到独联体的合作伙伴",
  Bonuses: "额外优待",
  BuySubscription: "购买订阅",
  CanSendIn: "可以过{0}再发送",
  ChangeData: "编辑数据",
  ChangePassword: "修改密码",
  ChangesSaved: "更改已保存",
  ChangingEmail_SendToNewEmail:
    "尊敬的用户！{0}要确认邮箱的更改，请转到{1}链接{2}！{3} 祝好，{4}",
  ChangingEmail_SendToNewEmailTitle: "请确认新电子信箱",
  ChangingEmail_SendToOldEmail:
    "尊敬的用户！{0}您更改了电子邮箱，新的价值：{1}如果您没有更改，请更改密码并联系技术支持{2}祝好，{3}",
  ChangingEmail_SendToOldEmailTitle: "更改电子邮箱通知",
  ChangingPassword:
    "尊敬的用户！{0}您的密码已被更改。如果您没有更改，请更改密码并联系技术支持{1}祝好，{2}",
  ChangingPasswordForAdmin:
    "尊敬的用户！{0}您的密码已被管理员更改。您的新密码{1}祝好，{2}",
  ChangingPasswordTitle: "修改密码通知",
  CheckEmailRecoverPassword: "请检查您的邮箱来找回密码。",
  ChooseCertificate: "选择一个证书",
  ChooseFile: "选择一个文件",
  ChooseStrongPassword: "创建一个强大的密码，不要在其他网站上使用它",
  CommonSettings: "一般设置",
  CommonSignIn: "通常授权",
  CommonSignUp: "普通注册",
  Company: "公司",
  Confirm: "确认",
  ConfirmActivationTitle: "您已注册了。点击一下就能获得访问",
  ConfirmLicenseCreated_Mail:
    "尊敬的用户！{0}您已成功购买订阅为{1}产品祝好，{2}",
  ConfirmLicenseCreated_MailTitle: "订阅的购买",
  ConfirmLicenseUpdated_Mail:
    "尊敬的用户！{0}您已成功更新订阅为{1}产品祝好，{2}",
  ConfirmLicenseUpdated_MailTitle: "订阅续订",
  ConfirmRegister: "注册确认",
  ConfirmRegister_Mail:
    "尊敬的用户！{0}您已经成功注册了MySeldon。 要更改密码，请转到{1}链接{2}！{3}祝好，{4}",
  Consult: "咨询",
  ConsultText:
    "如果您有任何问题或没有时间{0}来使用测试访问权限，请{0}点击“咨询”按钮",
  ControlMailDescription: "预防违反、分析与监视采购系统",
  ControlRegistrationTitle:
    "控制你的采购活动。根据联邦法44号和联邦法223号，分析自己的费用与优化参与拍卖。",
  ControlTariffDescription1:
    "对组织采购活动进行快速而详细的分析。这是节省时间、资源，并让您做出明智的决策。",
  ControlTariffDescription2:
    "这系统会最大限度地降低风险、实施预防措施：防止44-联邦法律和223-联邦法律框架内违规行为和程序处罚。",
  ControlTariffTitle1: "分析和监测招标活动",
  CreatePassword: "创建密码",
  CurrentPassword: "旧密码",
  CurrentSubsriptions: "目前的订阅",
  DataProtected: "资料按国际标准PCI DSS保护",
  Day: "{0}天",
  DearUser: "尊敬的用户！",
  Demo: "测试访问",
  DemoAccess: "测试访问",
  DemoAccessDateActivation: "测试访问从 {0} 到 {1}",
  DemoAccessDay: "{0} 天可以用测试访问",
  DemoAccessFewDays: "{0} 天可以用测试访问",
  DemoAccessManyDays: "{0} 天可以用测试访问",
  Department: "部分",
  DifferenceInExchangeRates:
    "在外币支付的情况下，从您的帐户中提取的金额可能与此资费率的金额不同，由于汇率的差异，当银行执行货币兑换业务的交易时。",
  DigitalSignatureActivated: "数字签名被激活",
  DigitalSignatureAlreadyBinded: "此数字签名已经与您的帐户相关联",
  DigitalSignatureAndCertificates: "数字签名和证书",
  DigitalSignatureBinded: "数字签名绑定已经成功创建了",
  DigitalSignatureBindedToAnotherAccount:
    "此数字签名不能与您的帐户绑定，因为它已被其他用户使用",
  DigitalSignatureExpired: "证书有效期已经过期，请用另一个数字签名",
  DigitalSignatureIsNotConfidential: "电子数字签名由信托区外的认证机构签发",
  DigitalSignatureSettings: "电子数字签名的设置",
  DigitalSignatureWithdrawn:
    "电子数字签名在撤销证书的注册中，使用另一个电子数字签名密钥",
  Edit: "编辑",
  Email: "电子邮箱",
  EmailAlreadyExist: "您不能修改电子邮箱的值为指定的值因为系统中已存在该值",
  EmailOrPhone: "电子邮箱或电话号码",
  EmailSignature: "{0}团",
  EndLicense: "到",
  EnterCode: "请输入您在短信收到验证码",
  EnterCodeConfirmPhone: "输入消息中的代码以确认新的电话号码",
  EnterEmailOrPhoneNumber: "输入您的电子邮件地址或电话号码",
  EnterMailOrPhone: "输入您的电子邮件地址或电话号码",
  EnterYourEmail: "请输入您的电子邮件，我们将发送一个链接登录及其他指示。",
  ExceededRegisterPromo: "此促销代码的注册限制已超出",
  ExtendedTestAccess: "测试访问已被延长了",
  ExtendedTestAccessText: "我们已延长您mySeldon系统的测试访问时间。",
  FewDays: "{0}天",
  Finances: "财政",
  FirstName: "名字",
  ForgotPassword: "忘记密码？",
  ForgotPasswordError:
    "没有你指定的电子邮件或电话号码的账户。请重新 {0}登录{1}。",
  FreeAccessDayProduct: "{0}天免费访问从 {1}到{2}",
  FreeAccessFewDays: "{0}天免费访问",
  FreeAccessFewDaysProduct: "{0}天免费访问从 {1}到{2}",
  FreeAccessManyDays: "{0}天免费访问",
  FreeAccessManyDaysProduct: "{0}天免费访问从 {1}到{2}",
  FreeAccessOneDay: "{0}天免费访问",
  FreeAccessProduct: "访问系统{0}",
  FreeAccessProductActivate: "{0}天免费访问从 {1}到 {2}",
  FutureLicense: "未来的订阅",
  FutureSubscribeOne: "您有{0}个未来订阅",
  FutureSubscribesFew: "您有{0}个未来订阅",
  FutureSubscribesMany: "您有{0}个未来订阅",
  Gender: "性别：",
  GenderFemale: "女",
  GenderMale: "男",
  GetAccessToInfo: "得到有关公司、投标和活动的信息。注意您的环境。",
  GetDemoAccess: "获得测试访问权限",
  GetProfit: "赚取利润",
  GetProfitDescription:
    "提前知道您的机会，中标。该服务将评估您参与投标、财务机会、法庭案件和其他25个参数的经验、将分析和建议最佳购买。",
  GoToSystem: "进入系统",
  HavePromo: "有优惠码？",
  InactivePromo: "促销代码已过期",
  IncorrectEmailEntered: "您输入的电子邮箱不正确",
  IncorrectPassword: "密码无效",
  IncorrectPasswordEntered: "您输入的密码不正确",
  IncorrectPhoneEntered: "您输入的电话号码不正确",
  InvalidPromo: "无效的促销代码",
  IsSendSms: "没收到短信吗？",
  LastName: "姓",
  LicenseAgreement: "许可协议",
  LicenseCountFew: "{0}个订阅",
  LicenseCountMany: "{0}个订阅",
  LicenseCountOne: "{0}个订阅",
  LinkHasExpired: "信中的链接已经过时了。 请重新申请找回密码。",
  Login: "用户名",
  LoginPageHeader: "登录",
  LoginToAccess: "访问系统的用户名：",
  LogOff: "退出",
  ManageRisks: "管理风险",
  ManageRisksDescription:
    "检查客户和竞争对手、识别与特定供应商适当的采购。由于Seldon.Pro您将知道与谁合作安全。",
  ManyDays: "{0}天",
  MinPasswordLength: "密码长度最小值以{0}。",
  NewPassword: "新密码",
  NewsMailDescription: "俄罗斯和外国媒体新闻的互联网资源",
  NewsRegistrationTitle: "来自世界各地的突发新闻，公司、地区、媒体档案",
  NoActiveLicense: "没有有效的订阅",
  NotFound: "没有找到结果",
  NoTransactions: "您没有交易。",
  NotRobot: "请确认您不是机器人",
  OGRN: "国家基本注册号码",
  OgrnOrInn: "国家基本注册号码或纳税人识别号",
  OnDay: "{0}天",
  OnFewDays: "{0}天",
  OnlinePayment: "在线支付",
  OnManyDays: "{0}天",
  OpenAccess: "开放访问",
  OrderDigitalSignature: "订购数字签名",
  OrderDigitalSignatureSettings: "订购电子签名的配置",
  Password: "密码",
  PasswordChanged: "密码已更改",
  PasswordHasAPartOfLoginName:
    "密码不应包含帐户名称或完整用户名的一部分，其长度超过两个相邻的标志",
  PasswordMail: "{0} — 对{1}您的密码。",
  PasswordMustBeAtLeast8Symbols: "密码不能少于8个字符",
  PasswordMustContainsLettersAndDigits:
    "密码必须包含拉丁字母（从A到Z）和数字（从0到9），或拉丁文小写字母（从a到z）和数字（从0到9），或拉丁大写字母（从A到Z）和拉丁文小写字母（从a到z）",
  PasswordNotUpToDate: "密码无效，请重新注册",
  PasswordRequirementsError: "密码不符合安全要求：",
  PasswordsMismatch: "确认密码与新密码不符，请重新输入",
  PaymentButton: "转到支付",
  PaymentMethod: "付款方式",
  PaymentText_Mail: "{0}服务付款成功。",
  PersonalInfo: "个人信息",
  Phone: "电话号码",
  PhoneNumberAlreadyExist:
    "您不能修改手机号码的值为指定的值因为系统中已存在该值",
  PhoneOrEmailBusy: "与此电话号码或电子邮件的用户已存在",
  PlatformsWithContracts: "达成协议的平台",
  PleaseFillRequirementFields: "并非所有必填字段都已填写。请填写字段{0}",
  Position: "职务",
  PriceMailDescription: "为报最高价选择合同的服务",
  PriceRegistrationTitle:
    "您能很快与方便预测初始最高合同价格。评估电子投标市场，了解价格和竞争对手的积极性",
  PriceTariffDescription1:
    "在几秒钟内查找合同为最高合同价格证实。用一个按钮来下载一个完成的证实并将其包含在采购文档中。确保您的价格建议符合法律。",
  PriceTariffDescription2:
    "分析商品和服务的价格，使您的业务更高效。研究被竞争者执行的合同、查看价格动态、观察供给和需求的季节性。",
  PriceTariffDescription3:
    "使用比率使任何合同价格合理和吸引供应商。您可以指定一个倍增系数、平减指数，并考虑到其他购买条款。在Seldon.Price的帮助下您将永远有购买请求。",
  PriceTariffTitle1: "证明最初的最高总价格",
  PriceTariffTitle2: "分析市场",
  PriceTariffTitle3: "吸引新的供应商",
  PrivacyPolicy: "隐私政策",
  PrivacyPolicyAndOffer:
    "通过点击“转到支付”，您接受{0}隐私政策{1}、{2}报价{3}的条件。",
  PrivatePolicyRegister:
    "根据{0}隐私政策{1}，您同意进行处理在表单中指定您的个人数据",
  ProductCountFew: "为{0}个产品",
  ProductCountMany: "为{0}个产品",
  ProductCountOne: "为{0}个产品",
  Prolong: "延长",
  ProMailDescription: "用人工智能的服务为与招标工作。",
  PromoAnotherProduct: "本推广代码不是这个产品的",
  Promocode: "促销代码",
  PromoExpired: "推广代码有效期已经过期",
  PublicOffer:
    "根据俄罗斯联邦民法典第437条第2款，报价是一种正式的提议与其他法律、自然人、个体商（以下简称用户），在期权合同中指出的条款订一个协议。该合同只通过加入它作为一个整体承付。承付是用户许可费用。在这境况下，合同被认为以书面形式签订和相当于双方签订的合同。",
  PurchasingRegions: "您采购活动区域",
  RecoveryPassword: "找回密码",
  RecoveryPassword_Mail:
    "尊敬的用户！{0}要更改密码，请转到{1}链接{2}！{3} 祝好，{4}",
  RecoveryPassword2_Mail:
    "您使用了密码恢复表单，请转至{0}链接{1}以恢复您的密码。如果您没有这样做，请更改密码并联系技术支持。",
  Refill: "充值",
  Register: "注册",
  RegisterToStart: "注册来开始与系统工作",
  RegistrationСompleted: "注册完成",
  RememberMe: "记住我",
  RemovePromo: "删除推广代码",
  RenewSubscription: "延长订阅",
  RepeatNewPassword: "请确认新密码",
  RequiredField: "必填项",
  Resend: "重新发",
  Restore: "找回",
  Save: "保存",
  SavePasswordSuccess: "密码成功保存了",
  SavePolicy: "通过点击“保存”，您接受{0}隐私政策{1}的条件。",
  SaveUnavailable: "无法保存：",
  Search: "搜索",
  SearchInBasis: "找到在Seldon.Basis上关于自己的信息：",
  Security: "安全",
  SelectedRegions: "选择的区域",
  SelectRegion: "选择区域",
  Send: "发送",
  SendAgain: "再次发送",
  SendCode: "代码已发送了",
  SendPasswordOrCreateNew:
    "您的密码已作为代码发送到您的手机号码。点击“更改密码”按钮设置您自己的密码。",
  SendSmsWithPassword: "我们把登录系统的密码给您发短信。",
  SendToNumber: "我们把它发到您的短话号码",
  Settings: "设置",
  Show: "显示",
  ShowVideo: "观看视频",
  SignIn: "登录",
  SignInSystem: "登录",
  SignUpWithDS: "用数字签名注册",
  SincerelyTeam: "祝好，{0}Seldon团",
  SingleLoginSeldon: "所有Seldon服务的单一访问点",
  SliderBasisInfo:
    "使用本系统对您的商业伙伴进行全面评估，了解他们在财务、合同和法律方面的可靠性。",
  SliderNewsInfo:
    "了解关于行业、俄罗斯和世界的发展。自定您的新闻提要，只阅读您感兴趣的新闻。",
  SliderPriceInfo:
    "选择合同为最高初始合同价格证实。分析商品和服务的价格，使您的业务更具竞争力。",
  SliderProInfo:
    "用人工智能服务帮助中标，按专业活动和机会为每个人提供建议，分析客户、竞争对手、评估胜利的机会。",
  Snap: "绑定",
  SnapDigitalSignature: "绑定数字签名",
  StartLicense: "从",
  StartUsing: "开始用",
  Subscribe: "购买订阅",
  SuccessRegister_Mail: "您已在{0}系统中成功注册。",
  SupportMailLink:
    "如果您有任何问题，请点击{0}“了解详情”{1}，我们的专家将为您提供帮助。",
  SystemLanguage: "系统语言",
  Text403: "您无法查看此页面。如果您认为发生了错误，请与支持人员联系。",
  Text404: "您可能通过键入链接犯了错误。{0}尝试从{1}主页{2}开始",
  Text500: "抱歉，该服务尚未提供。请重试或转到{0}主页{1}。",
  TillDate: "到{0}",
  TimeLeft: "还剩{0}秒钟",
  Title403: "访问被拒绝",
  Title404: "页面未找到",
  Title500: "发生了错误。",
  ToSaveTime: "节省您的时间",
  ToSaveTimeDescription:
    "所有信息都汇集于一处，实时更新。不要搜索新的采购、检查程序的透明度、分析定购人。那些适合您的招标身边。",
  Total: "共计：{0}",
  Transactions: "交易",
  TryOtherProducts: "试一试我们别的产品",
  UploadNewPhoto: "上传一张新照片",
  UserNotFound: "用户未找到",
  WeNotFind: "我们没有找到任何结果，请尝试{0}找到在这里{1}：",
  WeSentCodeConfirm: "我们把代码发给了您的电话号码，确认收到：",
  WrongOldPassword: "密码不正确",
  WrongPassword: "您输入的密码不正确，请重新输入",
  YouCanFindInfoInBasis: "您可以随时找到{0}在Seldon.Basis上信息{1}",
  YourLogin: "您的用户名："
};
export default dictionary;
