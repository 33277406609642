// Image
// DARK SIDE VIEW
import laptopTopDark from "@images/landing/slider-info-laptop-dark-top.svg";
import laptopBottomDark from "@images/landing/slider-info-laptop-dark-bottom.svg";
import laptopImageSlide1Dark from "@images/landing/slider-info-laptop-image1.jpg";
import laptopImageSlide2Dark from "@images/landing/slider-info-laptop-image2.jpg";
import laptopImageSlide3Dark from "@images/landing/slider-info-laptop-image3.jpg";
import decorLeft1Dark from "@images/landing/slider-info-left-dark-1.png";
import decorLeft2Dark from "@images/landing/slider-info-left-dark-2.png";
import decorLeft3Dark from "@images/landing/slider-info-left-dark-3.png";
import decorLeft4Dark from "@images/landing/slider-info-left-dark-4.png";
import decorRight1Dark from "@images/landing/slider-info-right-dark-1.png";
import decorRight2Dark from "@images/landing/slider-info-right-dark-2.png";
import decorRight3Dark from "@images/landing/slider-info-right-dark-3.png";

// LIGHT SIDE VIEW
import laptopTopLight from "@images/landing/slider-info-laptop-light-top.svg";
import laptopBottomLight from "@images/landing/slider-info-laptop-light-bottom.svg";
import laptopImageSlide1Light from "@images/landing/slider-why-laptop-image1.jpg";
import laptopImageSlide2Light from "@images/landing/slider-why-laptop-image2.jpg";
import laptopImageSlide3Light from "@images/landing/slider-why-laptop-image3.jpg";
import decorLeft1Light from "@images/landing/slider-info-left-light-1.png";
import decorLeft2Light from "@images/landing/slider-info-left-light-2.png";
import decorLeft3Light from "@images/landing/slider-info-left-light-3.png";
import decorLeft4Light from "@images/landing/slider-info-left-light-4.png";
import decorRight1Light from "@images/landing/slider-info-right-light-1.png";
import decorRight2Light from "@images/landing/slider-info-right-light-2.png";
import decorRight3Light from "@images/landing/slider-info-right-light-3.png";

const initialState = {
  dark: {
    top: laptopTopDark,
    bottom: laptopBottomDark,
    left: {
      decor1: decorLeft1Dark,
      decor2: decorLeft2Dark,
      decor3: decorLeft3Dark,
      decor4: decorLeft4Dark
    },
    right: {
      decor1: decorRight1Dark,
      decor2: decorRight2Dark,
      decor3: decorRight3Dark
    },
    slides: [
      {
        position: 1,
        img: laptopImageSlide1Dark,
        title: "Держите руку на пульсе",
        desc:
          "Контролируйте ситуацию в экономике и здравоохранении 24 часа в сутки."
      },
      {
        position: 2,
        img: laptopImageSlide2Dark,
        title: "Будьте на шаг впереди",
        desc: "Прогнозируйте экономические тренды и будущие события."
      },
      {
        position: 3,
        img: laptopImageSlide3Dark,
        title: "Минимизируйте риски",
        desc: "Принимайте правильные решения, основываясь на надежных данных."
      }
    ]
  },
  light: {
    top: laptopTopLight,
    bottom: laptopBottomLight,
    left: {
      decor1: decorLeft1Light,
      decor2: decorLeft2Light,
      decor3: decorLeft3Light,
      decor4: decorLeft4Light
    },
    right: {
      decor1: decorRight1Light,
      decor2: decorRight2Light,
      decor3: decorRight3Light
    },
    slides: [
      {
        position: 1,
        img: laptopImageSlide1Light,
        title: "Повышайте эффективность",
        desc:
          "Только проверенные контрагенты. Сделки по различным направлениям."
      },
      {
        position: 2,
        img: laptopImageSlide2Light,
        title: "Увеличивайте продажи",
        desc: "Выявляйте отрасли и регионы для наращивания сбыта."
      },
      {
        position: 3,
        img: laptopImageSlide3Light,
        title: "Владейте ситуацией",
        desc:
          "Анализируйте ситуацию и оперативно реагируйте на изменения рынка."
      }
    ]
  }
};

export default initialState;
