import * as Yup from "yup";
import format from "extensions/stringExt";

//https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
//https://stackoverflow.com/questions/54405911/yup-validation-with-regex-using-matches-problem/54406124

const RegisterSchema = Yup.object().shape({
  //pass: Yup.string()
  //  .required("Обязательное поле")
  //  .min(3, format("Поле {0} должно содержать минимум {1} символа", "Имя", 3)),
  email: Yup.string()
    .required("Обязательное поле")
    .matches(
      /([A-Za-z0-9_.-]+)@([a-z0-9.-]+\.[a-z]+)/,
      "Введен некорректный email"
    )
});

export default RegisterSchema;
