const dictionary = {
  " ActiveLicenses": "有効な申し込み",
  " Apply": "適用する",
  " GetAccess": "アクセスを取得する",
  " IncorrectPassword": "確認コードが正しくありません",
  " LoginCredentials": "ログインするための資格情報：",
  " MinLengthFew": "{0}フィールドには{1}文字以上を含めなければならない",
  AccessDateActivation: "{0}から{1}までの有効なアクセス",
  AccessDay: "{0}の全ての機能への１日間のアクセス",
  AccessHalfYear: "{0}の全ての機能への半年間のアクセス",
  AccessMonth: "{0}の全ての機能への1ヶ月間のアクセス",
  AccessThreeMonth: "{0}の全ての機能への3か月間のアクセス",
  AccessWeek: "{0}の全ての機能への１週間のアクセス",
  AccessYear: "{0}の全ての機能への１年間のアクセス",
  AccountAlreadyContainsThisVIPCode:
    "個人アカウントには既にこのVIPプロモーションコードがあります",
  AccountWithTheSameDSAlreadyExist:
    "そのようなデジタル署名のある個人アカウントは既にあります",
  AccountWithTheSameEmailAlreadyExist:
    "そのようなEメールアドレスのある個人アカウントは既にあります",
  Activate: "有効にする",
  ActivationConfirmation: "有効化の確認",
  ActivationResult: "有効化の結果",
  ActivationTextBasis1:
    "企業間のコネクションと所属を確認するために、私たちのサービスを使用してください。ユーザーの利便性のために、すべての情報は「コネクションの木」と呼ばれるインタラクティブなプラットフォーム上に表示されます。",
  ActivationTextBasis2:
    "会社と管理者の登録情報を受け取ることができ、住所と電話番号が多数の会社にリンクされているかどうかを確認できます。",
  ActivationTextBasis3:
    "企業と企業家によって締結された国家契約に関する情報が提供されます。そのうえで、この企業または企業家がその契約上の義務を良心的に履行するかどうかを知るために役立ちます。",
  ActivationTextBasis4:
    "貸借対照表が発表され、会社の財務指標が計算されます。例えば、流動性、支払能力、金融安定、採算性、純資産です。",
  ActivationTextControl1:
    "44-連邦法と223-連邦法に基づく調達活動の透明性と完全性に関する最新の情報が提供されています。",
  ActivationTextControl2:
    "会社の調達活動の総合評価、予算支出の速度の評価、調達の効率の評価、リスク評価が提供されています。",
  ActivationTextControl3:
    "入札活動のあらゆる角度に関する適時の通知、例えば、予定された調達の実施、調達の遅延、 契約締結の不可能性に関する情報が提供されています。",
  ActivationTextNews1:
    "業界、国内および世界におけるイベントに関する最新情報が提供されています。重要なイベントをすぐに知ることができます。",
  ActivationTextNews2:
    "自分のニュースフィードを調整すると、あなたのプロフィールに関する重要なニュース、パートナーと競合他社に関する最新情報を入手できます。",
  ActivationTextNews3:
    "ニュースの感情分析、会社と個人の概要、彼らのイメージに対するマスメディアの影響などの隠されたものを知ることができます。最も詳細な分析は、1日24時間週7日提供されています。",
  ActivationTextPrice1:
    "価格帯を考慮し、競争価格を指定すると、あなたの調達のための入札は常にあります。",
  ActivationTextPrice2:
    "任意の契約価格のための根拠を提示するために、さまざまな調達条件を考慮する係数を使用できます。",
  ActivationTextPrice3:
    "論拠により証明されたあなたの価格は、法律の必要条件を満たすことになります。これにより、供給者を見つける可能性が高まります。",
  ActivationTextPro1:
    "商品やサービスの価格と需要を追うことができます。ウィジェットを使用すると、新しい調達に関する最近の情報を取得できます。",
  ActivationTextPro2:
    "本当の勝ち目のある入札のみにご参加してください。サービスは、顧客の入札の歴史を検査して、潜在的な参加者を評価して、その他の要因を考慮して、個人的な予測を提供します。",
  ActivationTextPro3:
    "人工知能に基づくアルゴリズムは、顧客の財務状況、調達と仲裁事件の歴史を評価しています。このようにして、ビジネス連携のために、信頼できる会社だけを選択できます。",
  ActivationTextPro4:
    "システムは、会社詳細を使用して、適切な入札を推奨しています。このようにして、貴重な時間を節約できます。",
  ActivationTitleBasis1: "コネクションと所属",
  ActivationTitleBasis2: "法人の統一国家登録簿からの抄録",
  ActivationTitleBasis3: "契約、不公平な供給者",
  ActivationTitleBasis4: "バランスと高速分析",
  ActivationTitleControl1: "違反の監視",
  ActivationTitleControl2: "調達活動の解析",
  ActivationTitleControl3: "イベントの監視",
  ActivationTitleNews1: "世界中からのニュース",
  ActivationTitleNews2: "パーソナライズされたニュースフィード",
  ActivationTitleNews3: "分析と人工知能",
  ActivationTitlePrice1: "価格帯",
  ActivationTitlePrice2: "価格の調整",
  ActivationTitlePrice3: "価格の根拠",
  ActivationTitlePro1: "競売の分析",
  ActivationTitlePro2: "勝利の予測",
  ActivationTitlePro3: "信頼性評価",
  ActivationTitlePro4: "最善の調達",
  ActiveFrom: "から有効です",
  ActiveLicense: "有効な申し込み",
  ActiveLicenseCountFew: "{0}有効な申し込み",
  ActiveLicenseCountMany: "{0}有効な申し込み",
  ActiveLicenseCountOne: "{0}有効な申し込み",
  ActualTill: "まで有効です",
  AddRemoveRegion: "地域を追加する/削除する",
  AdminPasswordReset:
    "親愛なるユーザー！{0}あなたのパスワードは管理者によってリセットされました。新しいパスワードを設定するために、{1} リンク{2}をクリックしてください。{3}敬具、{4}",
  AfterPayment: "支払い後に、次のことができるようになります：",
  AlreadyRegistered: "すでにサインアップしますか？",
  AtLeastThanEightSymbols: "最小文字数は8です。",
  AuthorizeWithDS: "電子取引プラットフォームによる認可",
  Balance: "アカウントの残高",
  BasisMailDescription: "ロシア会社と外国会社の分析と評価に向けたサービス。",
  BasisRegistrationTitle:
    "あなたのパートナーをチェックし、新しい顧客を見つけ、彼らの長所と短所を見出すことができます。",
  BasisTariffDescription1:
    "2400万のロシアの企業と起業家に関する情報は、常に利用可能です。例えば、彼らの能力とニーズ、長所と短所です。名前、連絡先情報、細目による便利な検索があります。",
  BasisTariffDescription2:
    "会社の概要を検討してください。あなたの潜在的なパートナーがその約束を良心的に履行しているかどうかを確認できます。例えば、金融債務、契約上の義務、法的義務です。",
  BasisTariffDescription3:
    "会社の活動における出来事に関する通知を受け取ることができます。Seldon.Basisは、あなたのパートナーや競合他社の問題点について警告します。統計と分析を使用すると、会社の全貌が明らかになります。",
  BasisTariffDescription4:
    "CIS諸国の会社の活動について知ることができます。会社が入札に公平に参加したかどうかを確認し、会社の財政状況を分析することができます。あなたのビジネスを拡大し、外国のパートナーと協力してください！",
  BasisTariffTitle1: "新しいクライアントを検索できます",
  BasisTariffTitle2: "リスクを最小化できます",
  BasisTariffTitle3: "最新情報を知ることができます",
  BasisTariffTitle4: "CIS諸国からパートナーを探すことができます",
  Bonuses: "ボーナスポイント",
  BuySubscription: "有料申し込みを購入する",
  CanSendIn: "{0}で送信できます",
  ChangeData: "データを変更する",
  ChangePassword: "パスワードを変更する",
  ChangesSaved: "変更が保存されました",
  ChangingEmail_SendToNewEmail:
    "親愛なるユーザー！{0}Eメールアドレスの変更を確認するために、{1} リンク{2}をクリックしてください。{3}敬具、{4}",
  ChangingEmail_SendToNewEmailTitle: "Eメールアドレスの変更の確認",
  ChangingEmail_SendToOldEmail:
    "親愛なるユーザー！{0}あなたはEメールアドレスを変更しました。 新しいEメールアドレスは{1}です。あなたはEメールアドレスの変更をリクエストしなかった場合には、パスワードを変更してテクニカルサポートに連絡してください！{2}敬具、{3}",
  ChangingEmail_SendToOldEmailTitle: "Eメールアドレスの変更に関する通知",
  ChangingPassword:
    "親愛なるユーザー！{0}あなたのパスワードが変更されました。あなたはパスワードを変更しなかった場合には、パスワードを変更してテクニカルサポートに連絡してください！{1}敬具、{2}",
  ChangingPasswordForAdmin:
    "親愛なるユーザー！{0}あなたのパスワードは管理者によって変更されました。あなたの新しいパスワードは{1}です。敬具、{2}",
  ChangingPasswordTitle: "パスワードの変更に関する通知",
  CheckEmailRecoverPassword:
    "パスワードを回復するために、あなたのEメールをチェックしてください。",
  ChooseCertificate: "証明書を選択する",
  ChooseFile: "ファイルを選択する",
  ChooseStrongPassword:
    "強力なパスワードを作成し、他の場所でそれを使用しないでください。",
  CommonSettings: "一般的な設定",
  CommonSignIn: "通常の認可",
  CommonSignUp: "通常の登録",
  Company: "会社",
  Confirm: "確認する",
  ConfirmActivationTitle:
    "あなたはすでに登録されました。ワンクリックでアクセスを取得してください。",
  ConfirmLicenseCreated_Mail:
    "親愛なるユーザー！{0}製品の申し込みが正常に購入されました{1}敬具、{2}",
  ConfirmLicenseCreated_MailTitle: "有料申し込みの購入",
  ConfirmLicenseUpdated_Mail:
    "親愛なるユーザー！{0}製品の申し込みが正常に更新されました{1}敬具、{2}",
  ConfirmLicenseUpdated_MailTitle: "有料申し込みの更新",
  ConfirmRegister: "登録の確認",
  ConfirmRegister_Mail:
    "親愛なるユーザー！{0}あなたはMySeldonサービスに正常に登録されました。パスワードを変更するために、{1} リンク{2}をクリックしてください。{3}敬具、{4}",
  Consult: "相談",
  ConsultText:
    "ご質問があれば、または試用アクセスを使用する{0}ための時間がなかった場合には、{0} 「相談」をクリックしてください。",
  ControlMailDescription: "調達活動分析・監視と違反防止に向けたシステム。",
  ControlRegistrationTitle:
    "あなたの調達活動を管理でき、経費を分析でき、44-連邦法と223-連邦法に基づく入札への参加を最適化できます。",
  ControlTariffDescription1:
    "会社の調達活動の迅速かつ詳細な分析が提供されています。これにより、時間とお金が節約され、情報に基づく意思決定が可能になります。",
  ControlTariffDescription2:
    "システムは、44-連邦法と223-連邦法に基づく手続きに関する潜在的な違反と罰金を予防し、リスクを最小化します。",
  ControlTariffTitle1: "入札活動の分析と監視",
  CreatePassword: "パスワードの作成",
  CurrentPassword: "古いパスワード",
  CurrentSubsriptions: "現在の有料申し込み",
  DataProtected: "データはPCI DSS国際標準によって保護されています",
  Day: "{0}日間",
  DearUser: "親愛なるユーザー！",
  Demo: "試用アクセス",
  DemoAccess: "試用アクセス",
  DemoAccessDateActivation: "{0}から{1}までの有効な試用アクセス",
  DemoAccessDay: "{0}日間の試用アクセス",
  DemoAccessFewDays: "{0}日間の試用アクセス",
  DemoAccessManyDays: "{0}日間の試用アクセス",
  Department: "部門",
  DifferenceInExchangeRates:
    "外貨で支払う場合に、 あなたの口座から引き出された金額は、このアクセスプランのコストとして示された金額と異なる場合があります。 支払い時に銀行が為替取引を行うとき為替レートの違いがあるためです。",
  DigitalSignatureActivated: "デジタル署名が有効になりました",
  DigitalSignatureAlreadyBinded:
    "このデジタル署名は既にあなたの個人アカウントにリンクされています",
  DigitalSignatureAndCertificates: "デジタル署名と証明書",
  DigitalSignatureBinded: "デジタル署名が正常に追加されました",
  DigitalSignatureBindedToAnotherAccount:
    "このデジタル署名は、別のユーザーによって既に使用されているため、あなたの個人アカウントにリンクされることができません",
  DigitalSignatureExpired:
    "証明書の有効期限が切れました。他のデジタル署名のキーを使用してください。",
  DigitalSignatureIsNotConfidential:
    "このデジタル署名は、信頼できない認証局によって発行されました。",
  DigitalSignatureSettings: "デジタル署名の設定",
  DigitalSignatureWithdrawn:
    "このデジタル署名は、キャンセルされた証明書の登録簿に含まれています。他のデジタル署名のキーを使用してください。",
  Edit: "変更する",
  Email: "Eメールアドレス",
  EmailAlreadyExist:
    "このEメールアドレスは既に使用されているため使用できません",
  EmailOrPhone: "Eメールアドレスまたは電話番号",
  EmailSignature: "{0}チーム",
  EndLicense: "まで",
  EnterCode: "SMSからの認証コードを入力してください",
  EnterCodeConfirmPhone:
    "新しい電話番号を確認するために、メッセージからのコードを入力してください。",
  EnterEmailOrPhoneNumber: "Eメールアドレスまたは電話番号を入力してください",
  EnterMailOrPhone: "Eメールアドレスまたは電話番号を入力してください",
  EnterYourEmail:
    "あなたのEメールアドレスを指定してください。ログインリンクと他の説明書は、そのEメールアドレスに送られます。",
  ExceededRegisterPromo: "このプロモーションコードの登録数を超えました",
  ExtendedTestAccess: "試用アクセスが更新されました",
  ExtendedTestAccessText:
    "おめでとう！mySeldonシステムへの試用アクセスが更新されました。",
  FewDays: "{0} 日間",
  Finances: "金融",
  FirstName: "名前",
  ForgotPassword: "パスワードを忘れましたか？",
  ForgotPasswordError:
    "指定されたEメールアドレスまたは電話番号のアカウントは見つかりませんでした。 {0}サインアップ{1}してください。",
  FreeAccessDayProduct: "{2}への{1}{0}日間の無料アクセス",
  FreeAccessFewDays: "{0}日間の無料アクセス",
  FreeAccessFewDaysProduct: "{2}への{1}{0}日間の無料アクセス",
  FreeAccessManyDays: "{0}日間の無料アクセス",
  FreeAccessManyDaysProduct: "{2}への{1}{0}日間の無料アクセス",
  FreeAccessOneDay: "{0}日間の無料アクセス",
  FreeAccessProduct: "{0}システムへのアクセス",
  FreeAccessProductActivate: "{0}への無料アクセスが有効になりました",
  FutureLicense: "将来の申し込み",
  FutureSubscribeOne: "あなたはさらに今後の{0}申し込みを持っています",
  FutureSubscribesFew: "あなたはさらに今後の{0}申し込みを持っています",
  FutureSubscribesMany: "あなたはさらに今後の{0}申し込みを持っています",
  Gender: "性別",
  GenderFemale: "女性",
  GenderMale: "男性",
  GetAccessToInfo:
    "会社や競売やイベントに関する情報にアクセスできます。あなたの側近者を観察できます。",
  GetDemoAccess: "試用アクセスを取得する",
  GetProfit: "利益を得ることができます",
  GetProfitDescription:
    "あなたは勝算を事前に知って、入札で勝つことができます。このサービスは、入札に参加経験、財政能力、裁判事件とその他の25のパラメータを評価し、全ての情報を分析し、最高の購入を推薦します。",
  GoToSystem: "システムへ進む",
  HavePromo: "プロモーションコードを持っていますか？",
  InactivePromo: "無効なプロモーションコード",
  IncorrectEmailEntered: "間違ったE-メールアドレスが入力されました",
  IncorrectPassword: "パスワードが正しくありません",
  IncorrectPasswordEntered: "間違ったパスワードが入力されました：",
  IncorrectPhoneEntered: "間違った電話番号が入力されました",
  InvalidPromo: "正しくないプロモーションコード",
  IsSendSms: "SMSを受信しませんでしたか？",
  LastName: "姓",
  LicenseAgreement: "ライセンス契約",
  LicenseCountFew: "{0}申し込み",
  LicenseCountMany: "{0}申し込み",
  LicenseCountOne: "{0}申し込み",
  LinkHasExpired:
    "パスワードを回復するためのリンクの有効期限が切れました。新しいリンクをリクエストしてください。",
  Login: "ログイン",
  LoginPageHeader: "ログイン",
  LoginToAccess: "システムにアクセスするためのログイン：",
  LogOff: "ログアウトする",
  ManageRisks: "リスクを管理できます",
  ManageRisksDescription:
    "顧客と競合他社をチェックすることができ、特定の供給者の興味に合わせた調達を検出できます。Seldon.Proを使用して、信頼できる協力者を見つけることができます。",
  ManyDays: "{0}日間",
  MinPasswordLength: "パスワードの長さは最小{0}文字です。",
  NewPassword: "新しいパスワード",
  NewsMailDescription: "ロシアメディアと外国メディアのニュースアグリゲーター。",
  NewsRegistrationTitle: "世界中からの最新ニュース；会社や地域や個人の概要。",
  NoActiveLicense: "有効な申し込みがありません",
  NotFound: "何も見つかりませんでした",
  NoTransactions: "あなたはまだ取引を持っていません。",
  NotRobot: "あなたはロボットではないことを確認していません。",
  OGRN: "主国家登録番号",
  OgrnOrInn: "主国家登録番号または納税者番号",
  OnDay: "{0}日間",
  OnFewDays: "{0}日間",
  OnlinePayment: "オンラインお支払い",
  OnManyDays: "{0}日間",
  OpenAccess: "アクセスが提供されました",
  OrderDigitalSignature: "デジタル署名を注文する",
  OrderDigitalSignatureSettings: "デジタル署名の設定を注文する",
  Password: "パスワード",
  PasswordChanged: "パスワードが変更されました",
  PasswordHasAPartOfLoginName:
    "パスワードは個人アカウントの名称を含めてはいけません。そのうえ、パスワードは、ユーザーのフルネームの部分の2つ以上の隣接する文字の長さを超える部分を含めてはいけません。",
  PasswordMail: "{1}のあなたのパスワード：{0}",
  PasswordMustBeAtLeast8Symbols: "パスワードの最低文字数は８の文字です。",
  PasswordMustContainsLettersAndDigits:
    "パスワードは、ラテン大文字（A～Z）と数字（0～9）、または、 ラテン小文字（a～z）と数字（0～9）、または、ラテン大文字（A～Z）とラテン小文字（a～z）を含んでいなければなりません。",
  PasswordNotUpToDate:
    "パスワードが期限切れです。もう一度サインアップしてください。",
  PasswordRequirementsError: "パスワードがセキュリティ要件を満たしていない：",
  PasswordsMismatch:
    "新しいパスワードとパスワードの確認が一致しません。もう一度お試しください。",
  PaymentButton: "お支払いへ進む",
  PaymentMethod: "お支払い方法",
  PaymentText_Mail: "{0}サービスの支払いは成功です！",
  PersonalInfo: "個人情報",
  Phone: "電話番号",
  PhoneNumberAlreadyExist: "この電話番号は既に使用されているため使用できません",
  PhoneOrEmailBusy:
    "この電話番号またはEメールアドレスを持つユーザーが既にいます",
  PlatformsWithContracts: "プラットフォームと締結された契約",
  PleaseFillRequirementFields:
    "すべての記入すべきフィールドが記入されていません。{0}フィールドを記入してください。",
  Position: "役職",
  PriceMailDescription:
    "契約の初期最大契約価格の論拠を提示するための契約の選択に向けたサービス",
  PriceRegistrationTitle:
    "初期最大契約価格の論拠を可能な限り迅速かつ容易に準備できます。そして、電子取引の市場を分析でき、競合他社の活動と値段を調べることができます。",
  PriceTariffDescription1:
    "初期最大契約価格の論拠を提示するための契約を数秒で選択することができます。作成された論拠をワンクリックでダウンロードして、それを調達文書に含めることができます。あなたの入札が法律に対応するかどうかを確認してください。",
  PriceTariffDescription2:
    "ビジネスの効率性を高めるために、商品やサービスの価格を分析できます。競合他社が達成した契約を調べたり、価格動向を見たり、需要及び供給の季節性を観察することができます。",
  PriceTariffDescription3:
    "係数を使用すると、任意の契約価格のための論拠を提示し、供給者の関心を引くことができます。たとえば、増加係数やデフレーターを使用する、または、その他の調達条件を考慮できます。Seldon.Priceを利用するとき、あなたの調達には申請が常にあります。",
  PriceTariffTitle1: "初期最大契約価格の論拠を提供できます",
  PriceTariffTitle2: "市場を分析できます",
  PriceTariffTitle3: "新しい供給者を誘致できます",
  PrivacyPolicy: "プライバシーポリシー",
  PrivacyPolicyAndOffer:
    "「お支払いへ進む」を押すことで、あなたは{0}プライバシーポリシー{1}及び {2}公募{3}の規定を読み、それに同意したものとみなします。",
  PrivatePolicyRegister:
    "個人データをフォームで指定することで、あなたは{0}プライバシーポリシー{1}に従って個人データの処理に同意したものとみなします。",
  ProductCountFew: "{0}製品に関する",
  ProductCountMany: "{0}製品に関する",
  ProductCountOne: "{0}製品に関する",
  Prolong: "延長する",
  ProMailDescription: "入札作業のための人工知能に基づくサービス",
  PromoAnotherProduct: "他の製品のためのプロモーションコードが指定されました",
  Promocode: "プロモーションコード",
  PromoExpired: "プロモーションコードの有効期限が切れました",
  PublicOffer:
    "この公募（以下：本公募）は、ロシア連邦民法典第４３７条第２項による、いかなる法人や自然人や個人企業家（以下：ユーザー）との契約の締結（本公募に定められた条件に従って）に関する正式な提案である。契約の締結の唯一の方法は、契約の全ての条件に完全に同意することであり、すなわち受諾を意味する。公募の受諾は、ユーザーによってライセンス料の支払いとなる。このようにして、契約は、書面で締結されたものとみなされ、両当事者によって署名された契約と同等であるものとする。",
  PurchasingRegions: "あなたの調達活動の地域",
  RecoveryPassword: "パスワードの回復",
  RecoveryPassword_Mail:
    "親愛なるユーザー！{0}パスワードを変更するために、{1} リンク{2}をクリックしてください。{3}敬具、{4}",
  RecoveryPassword2_Mail:
    "あなたはパスワード回復オプションを使用しました。パスワードを回復するために、{0}リンク{1}をクリックしてください。あなたはパスワードの回復をリクエストしなかった場合には、パスワードを変更してテクニカルサポートに連絡してください！",
  Refill: "追加する",
  Register: "サインアップ",
  RegisterToStart: "使用し始めるために、サインアップしてください！",
  RegistrationСompleted: "登録が完了しました",
  RememberMe: "保存する",
  RemovePromo: "プロモーションコードを削除する",
  RenewSubscription: "有料申し込みを更新する",
  RepeatNewPassword: "新しいパスワードを繰り返してください",
  RequiredField: "必須フィールド",
  Resend: "再送する",
  Restore: "回復する",
  Save: "保存する",
  SavePasswordSuccess: "パスワードが正常に保存されました",
  SavePolicy:
    "「保存する」を押すことで、あなたは{0}プライバシーポリシー{1}の規定を読み、それに同意したものとみなします。",
  SaveUnavailable: "保存することができません：",
  Search: "検索",
  SearchInBasis:
    "Seldon.Basisでは、あなた自身に関する情報を見つけることができます。",
  Security: "安全性",
  SelectedRegions: "選択された地域",
  SelectRegion: "地域を選択する",
  Send: "送る",
  SendAgain: "再送する",
  SendCode: "コードが送信されました",
  SendPasswordOrCreateNew:
    "あなたのパスワードはコードとして電話番号に送信されました。自分のパスワードを設定するために、「パスワードを変更する」ボタンをクリックしてください。",
  SendSmsWithPassword:
    "ログイン用の認証パスワードのあるSMSは、この電話番号に転送いたします。",
  SendToNumber: "これは、あなたの電話番号に送信されました。",
  Settings: "設定",
  Show: "見せる",
  ShowVideo: "ビデオを見る",
  SignIn: "ログインする",
  SignInSystem: "システムにログインしてください",
  SignUpWithDS: "電子取引プラットフォームによる登録",
  SincerelyTeam: "敬具、{0} Seldonチーム",
  SingleLoginSeldon: "Seldonの全てのサービスへの統一アクセス",
  SliderBasisInfo:
    "競合他社とパートナーを総合的に評価するために、サービスを使用してください。あなたは彼らがその約束（金融債務、契約上の義務、法的義務）を良心的に履行しているかどうかを知ることができます。",
  SliderNewsInfo:
    "ロシアや世界で、業界に関する最新情報を知ることができます。あなたのニュースフィードをカスタマイズして、必要なニュースだけを読んでください。",
  SliderPriceInfo:
    "初期最大契約価格の根拠を提示するための契約を選択することができます。ビジネスの競争力を高めるために、商品やサービスの価格を分析してください。",
  SliderProInfo:
    "これは、入札で勝つための人工知能に基づくサービスです。サービスは、勧告（能力、活動の種類によって）を個人的に提供し、競合他社と顧客を分析し、勝ち目を評価します。",
  Snap: "追加する",
  SnapDigitalSignature: "デジタル署名を追加する",
  StartLicense: "から",
  StartUsing: "使用し始める",
  Subscribe: "有料申し込みを購入する",
  SuccessRegister_Mail: "あなたは{0}システムに正常に登録されました。",
  SupportMailLink:
    "ご質問があれば、{0}「詳しくはこちら」{1} をクリックしください。できるだけ早くご連絡させていただきます。",
  SystemLanguage: "システム言語",
  Text403:
    "このページを見ることはできません。 エラーが発生したと思われる場合は、サポートに連絡してください。",
  Text404:
    "リンクを入力して間違えた可能性があります。{0}{1}メインページ{2}から始めてみてください。",
  Text500:
    "申し訳ありませんが、サービスはまだ利用できません。もう一度試すか、{0}メインページ{1}にアクセスしてください。",
  TillDate: "{0}まで",
  TimeLeft: "残り{0}秒",
  Title403: "アクセスが拒否されました",
  Title404: "ページが見つかりませんでした",
  Title500: "エラーが発生しました",
  ToSaveTime: "時間を節約できます",
  ToSaveTimeDescription:
    "すべての情報は1つの場所にあり、リアルタイムモードで更新されます。新しい調達を検索し、手続きの透明性をチェックし、顧客を分析する必要はありません 。あなたに適した入札がいつでも手元にあります。",
  Total: "合計： {0}",
  Transactions: "取引",
  TryOtherProducts: "Seldonの他のソフトウェア製品をお試しください！",
  UploadNewPhoto: "新しい写真をアップロードする",
  UserNotFound: "ユーザーが見つかりませんでした",
  WeNotFind: "何も見つかりませんでした。 {1}で検索してみてください{0}。",
  WeSentCodeConfirm:
    "パスワードコードが電話番号に送信されました。コードをここに入力してください：",
  WrongOldPassword: "間違ったパスワード",
  WrongPassword:
    "入力された現在のパスワードが正しくないです。もう一度お試しください。",
  YouCanFindInfoInBasis:
    "あなたは、{0}Seldon Basisで情報{1}をいつでも見つけることができます",
  YourLogin: "あなたのログイン："
};
export default dictionary;
