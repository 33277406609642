// Library
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

// Local
import localization from "localization";

// Component
// import RegisterLayout from "../layouts/register";
// import RegisterContainer from "../processes/register/containers/register-container";
// import RegisterConfirmContainer from "../processes/register-confirm/containers/register-confirm-container";
import LoginContainer from "../processes/login/containers/login-container";
import ErrorLoginContainer from "../processes/login/containers/error-login";
// import LoginActivationTitleContainer from "../processes/login/containers/activation-title-container";
import Landing from "../processes/landing/container/landing-container";
import Payment from "../processes/payment/container/payment-container";
import ModalState from "../processes/modal-state";
import Yookassa from "../processes/yookassa/containers/test-kassa-container";
import AuthorizationWrapper from "../layouts/AuthorizationWrapper";

const langs = "(" + localization.possibleLangList.join("|") + ")";
const partRoute = process.env.PART_ROUTE;

class AppRoutes extends Component {
  //Создаем разметку
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect
              to={`${partRoute}/${localization.selectedLang()}/account/login`}
            />
          </Route>
          <Route
            exact
            path={partRoute + "/:lang" + langs + "/landing"}
            render={props => (
              <>
                <Landing />
                <ModalState />
              </>
            )}
          />
          {/* <Route
            exact
            path={partRoute + "/:lang" + langs + "/payment-marketplace"}
            render={props => <PaymentMarketPlace />}
          /> */}
          <Route
            path={partRoute + "/:lang" + langs + "/payment/:productId/:id"}
            render={props => (
              <>
                <Payment {...props} />
                <ModalState />
              </>
            )}
          />
          <Route
            exact
            path={partRoute + "/kassa"}
            render={props => <Yookassa />}
          />
          {/* <Route
            exact
            path={partRoute + "/:lang" + langs + "/enter"}
            render={e => {
              return <RegisterLayout {...e} component={LoginContainer} />;
            }}
          />
          <Route
            exact
            path={partRoute + "/:lang" + langs + "/options"}
            render={e => {
              let lang = e.match.params.lang;
              window.location.href = decodeURI(
                `${config.sulApi}/${lang}/User/Options`
              );
              return null;
            }}
          />
          <Route
            path={partRoute + "/products/:name"}
            render={e => {
              window.location.href = decodeURI(
                e.location.pathname.replace(partRoute + "/products/", "")
              );
              return null;
            }}
          />
          <Route
            path={partRoute + "/:lang" + langs + "/activation"}
            render={e => {
              let params = new URLSearchParams(e.location.search);
              let lang = e.match.params.lang;
              let qs = {};
              if (params.get("product")) qs.product = params.get("product");
              if (params.get("returnUrl"))
                qs.ReturnUrl = decodeURI(params.get("returnUrl"));

              let search = stringify(qs);
              if (search)
                window.location.href = decodeURI(
                  `${config.sulApi}/${lang}/account/ActivateDemo?${search}`
                );
              else
                window.location.href = decodeURI(
                  `${config.sulApi}/${lang}/account/ActivateDemo`
                );
              return null;
            }}
          />
          <Route exact path={partRoute + "/account/registerconfirm"}>
            <Redirect
              to={`${partRoute}/${localization.selectedLang()}/account/registerconfirm`}
            />
          </Route>
          <Route
            exact
            path={partRoute + "/:lang" + langs + "/account/registerconfirm"}
            render={props => (
              <RegisterLayout {...props} component={RegisterConfirmContainer} />
            )}
          />
          {/* <Route exact path={partRoute + "/account/register"}>
            <Redirect
              to={`${partRoute}/${localization.selectedLang()}/account/register`}
            />
          </Route>
          <Route
            exact
            path={partRoute + "/:lang" + langs + "/account/register"}
            render={props => (
              <RegisterLayout {...props} component={RegisterContainer} />
            )}
          /> */}
          <Route
            exact
            path={partRoute + "/:lang" + langs + "/account/login"}
            render={props => (
              <AuthorizationWrapper {...props}>
                <LoginContainer />
              </AuthorizationWrapper>
            )}
          />
          <Route
            exact
            path={partRoute + "/:lang" + langs + "/account/error"}
            render={props => (
              <AuthorizationWrapper {...props}>
                <ErrorLoginContainer />
              </AuthorizationWrapper>
            )}
          />
          <Route>
            <Redirect
              to={`${partRoute}/${localization.selectedLang()}/account/login`}
            />
          </Route>
        </Switch>
      </Router>
    );
  }
}

/*
                            <Switch key={route.path}>
                                <Route exact path={partRoute + '/:lang' + langs + route.path} render={props =>
                                    (<route.layout {...props} component={route.component} />)
                                } />
                            </Switch>
*/

export default AppRoutes;
