// Library
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Component
import LangListWrapper from "../views/LangListWrapper";

// Action
import * as globalAction from "../../../processes-domain/global/global-actions";

const partRoute = process.env.PART_ROUTE;

const mapStateToProps = store => {
  return {
    lang: store.globalState.lang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLang: bindActionCreators(globalAction, dispatch).updateLang
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LangListWrapper)
);
