// Library
import React, { useEffect, useState } from "react";

// Component
import SubscribeBlock from "@ui/SubscribeBlock";

// Image
import iconDay from "@images/landing/subscribe-day.png";
import iconWeek from "@images/landing/subscribe-week.png";
import iconMonth from "@images/landing/subscribe-month.png";
import mainBg from "@images/landing/pattern.jpg";

// Local
import localization from "localization";
const langs = localization.selectedLang();
const partRoute = process.env.PART_ROUTE;

const LandingSubscribeAnalytics = ({ inputRef, tariffs, anchor }) => {
  let productId = tariffs ? tariffs.productId : 0;

  return (
    <div
      id={anchor}
      className="bg-light py-5 position-relative"
      ref={inputRef}
      style={{ background: `url(${mainBg})`, backgroundSize: "cover" }}
    >
      <div className="container">
        <h2 className="text-center text-black">
          <b>Подписка на аналитику</b>
        </h2>
        <p className="h5 mt-2 text-center">
          Дает полный доступ ко всем возможностям Cитуационного центра РСПП
          <br className="d-none d-md-block" />
          на день, неделю или месяц.
        </p>
        <div className="row justify-content-md-center mt-4">
          <div className="col-lg-3 d-lg-flex mb-2 mb-lg-0">
            <SubscribeBlock
              title="На день"
              image={iconDay}
              disable={!tariffs || !tariffs.dayTariff}
              price={
                tariffs && tariffs.dayTariff ? tariffs.dayTariff.rubPrice : 0
              }
              description={
                tariffs && tariffs.dayTariff ? tariffs.dayTariff.desc : ""
              }
              duration={
                tariffs && tariffs.dayTariff ? tariffs.dayTariff.duration : 0
              }
              link={`/${langs}/payment/${productId}/${
                tariffs && tariffs.dayTariff ? tariffs.dayTariff.id : 0
              }`}
            />
          </div>
          <div className="col-lg-3 d-lg-flex mb-2 mb-lg-0">
            <SubscribeBlock
              title="На неделю"
              image={iconWeek}
              disable={!tariffs || !tariffs.weekTariff}
              tariffId={
                tariffs && tariffs.weekTariff ? tariffs.weekTariff.id : null
              }
              price={
                tariffs && tariffs.weekTariff ? tariffs.weekTariff.rubPrice : 0
              }
              description={
                tariffs && tariffs.weekTariff ? tariffs.weekTariff.desc : ""
              }
              duration={
                tariffs && tariffs.weekTariff ? tariffs.weekTariff.duration : ""
              }
              link={`/${langs}/payment/${productId}/${
                tariffs && tariffs.weekTariff ? tariffs.weekTariff.id : 0
              }`}
            />
          </div>
          <div className="col-lg-3 d-lg-flex mb-2 mb-lg-0">
            <SubscribeBlock
              title="На месяц"
              image={iconMonth}
              disable={!tariffs || !tariffs.monthTariff}
              tariffId={
                tariffs && tariffs.monthTariff ? tariffs.monthTariff.id : null
              }
              price={
                tariffs && tariffs.monthTariff
                  ? tariffs.monthTariff.rubPrice
                  : 0
              }
              description={
                tariffs && tariffs.monthTariff ? tariffs.monthTariff.desc : ""
              }
              duration={
                tariffs && tariffs.monthTariff
                  ? tariffs.monthTariff.duration
                  : ""
              }
              link={`/${langs}/payment/${productId}/${
                tariffs && tariffs.monthTariff ? tariffs.monthTariff.id : 0
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSubscribeAnalytics;
